import { RegexpEmail } from '@core/utility/regex';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { checkIfEmailIdentical } from '@shared/services/email-validator';
import {
  newSalutation,
  phoneConstraints,
  phoneValidationMessages,
} from './ticket-created-shared.field-configs';
import { ContactSearchFormlyFieldService } from '../contact-search-formly-field.service';

export function searchContact( 
  translateService: TranslateService,
  contactFormlyFieldService: ContactSearchFormlyFieldService,
  onSelectContact: (id: string) => void,
  weNo?: string | null): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.headline__search_contact'),
            tooltip: translateService.instant('ticket.create.tooltip__search_contact'),
          },
        },
        {
          key: 'searchContact',
          fieldGroup: [
            ...contactFormlyFieldService.autocompleteSearch(
              { archObjId: weNo },
              onSelectContact,
              'ticket.create.headline__search_contact'
            ),
          ],
        },
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.headline__contact'),
            chapterText: translateService.instant('ticket.create.p__info_contact'),
          },
        },
        {
          key: 'requestingContact',
          fieldGroup: [
            ...newSalutation(translateService),
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'firstname',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__firstName'),
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 50,
                  },
                },
                {
                  type: 'input',
                  key: 'lastname',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__lastName'),
                    visibleInConfirm: true,
                    maxLength: 50,
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'phonenumber',
                  defaultValue: '0049',
                  wrappers: ['tooltip', 'form-field'],
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__phone'),
                    visibleInConfirm: true,
                    required: true,
                    tooltip: translateService.instant('ticket.create.tooltip__phone'),
                    ...phoneConstraints,
                  },
                  validation: phoneValidationMessages(translateService),
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              validators: {
                fieldMatch: {
                  expression: checkIfEmailIdentical,
                  message: translateService.instant('formErrors.email.not_identical'),
                  errorPath: 'confirmEmail',
                },
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'email',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__email'),
                    visibleInConfirm: true,
                    required: true,
                    pattern: RegexpEmail,
                    maxLength: 100,
                  },
                  validation: {
                    messages: {
                      pattern: (_err, _field: FormlyFieldConfig) =>
                        translateService.instant('formErrors.email.pattern'),
                    },
                  },
                },
                {
                  type: 'input',
                  key: 'confirmEmail',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__confirm_email'),
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
