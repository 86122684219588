import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateIncidentAuthenticatedFromPortalDTO, IncidentClient } from '@api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prospective-tenants-telekom',
  templateUrl: './prospective-tenants-telekom.component.html',
  styleUrls: ['./prospective-tenants-telekom.component.scss'],
})
export class ProspectiveTenantsTelekomComponent {
  form = new FormGroup({});

  model = {};

  public isLoading = false;

  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant('taskmodule.task.prospective_tenants.name'),
        placeholder: this.translateService.instant('taskmodule.task.prospective_tenants.name'),
        required: true,
      },
    },
    {
      key: 'requestedArea',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant('taskmodule.task.prospective_tenants.requested_area'),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_area'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'requestedPitches',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_pitches'
        ),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.requested_pitches'
        ),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'rentprice',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant('taskmodule.task.prospective_tenants.rentprice'),
        placeholder: this.translateService.instant('taskmodule.task.prospective_tenants.rentprice'),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'duration',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant('taskmodule.task.prospective_tenants.duration'),
        placeholder: this.translateService.instant('taskmodule.task.prospective_tenants.duration'),
        required: true,
        type: 'number',
      },
    },
    {
      key: 'isPreparationNecessary',
      type: 'radio',
      templateOptions: {
        label: this.translateService.instant(
          'taskmodule.task.prospective_tenants.preparation_necessary'
        ),
        required: true,
        options: [
          {
            label: this.translateService.instant('taskmodule.shared.label__choice_yes'),
            value: 'yes',
          },
          {
            label: this.translateService.instant('taskmodule.shared.label__choice_no'),
            value: 'no',
          },
        ],
      },
    },
    {
      key: 'necessary',
      type: 'input',
      hide: true,
      templateOptions: {
        label: 'Bitte eingeben...',
        placeholder: 'Bitte eingeben...',
        required: true,
      },
      expressionProperties: {
        hide: model => {
          return model.isPreparationNecessary !== 'yes';
        },
      },
    },
    {
      key: 'incentives',
      type: 'input',
      templateOptions: {
        label: this.translateService.instant('taskmodule.task.prospective_tenants.incentives'),
        placeholder: this.translateService.instant(
          'taskmodule.task.prospective_tenants.incentives'
        ),
      },
    },
  ];

  public preparation = false;

  public isPreparationNecessary;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProspectiveTenantsTelekomComponent>,
    public incidentClient: IncidentClient,
    public translateService: TranslateService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any
  ) {}

  public onOk() {
    this.isLoading = true;
    const data: CreateIncidentAuthenticatedFromPortalDTO = {
      incidentDTO: {
        incidentTitle: `Mietinteressent melden WE${this.dataDialog.siteCode} ${this.dataDialog.city} - ${this.dataDialog.street}`,
        incidentDescription: 'Beschreibung Mietinteresse',
        incidentClassId: 'Mietinteressent melden',
        incidentType: 1,
        createdById: 'systemuser-1464',
        alternateAddress: { city: this.dataDialog.city, street: this.dataDialog.street },
        parentIncidentId: this.dataDialog.incidentId,
      },
    };
    if (this.form.valid) {
      this.incidentClient.createIncidentAuthenticated(data).subscribe(
        () => {
          this.isLoading = false;
          this.dialogRef.close(this.model);
          this.toastr.success(this.translateService.instant('form.success_message'), '');
        },
        error => {
          console.log(error);
          this.isLoading = false;
          this.toastr.error(
            this.translateService.instant('ticket.create.error.message'),
            this.translateService.instant('ticket.create.error.title')
          );
        }
      );
    }
  }

  public onDismiss() {
    console.log('dismiss');
    this.dialogRef.close();
  }
}
