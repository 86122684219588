import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArchObjNewsViewModel, LocationClient } from '@api';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-location-news',
  templateUrl: './location-news.component.html',
  styleUrls: ['./location-news.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocationNewsComponent {
  public isLoading = false;

  public locationNews: ArchObjNewsViewModel;

  public title = '';

  public minDate: Date | null;

  public maxDate: Date | null;

  public form = new FormGroup({
    category: new FormControl(),
    validFrom: new FormControl(),
    validTo: new FormControl(),
    publish: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<LocationNewsComponent>,
    public locationClient: LocationClient,
    public toastr: ToastrService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { news: ArchObjNewsViewModel; title: string }
  ) {
    this.title = data.title;
    this.locationNews = data.news;
  }

  onSubmit(news: ArchObjNewsViewModel) {
    if (this.form.valid) {
      news.validFrom = this.form.value.validFrom?.format('YYYY-MM-DDTHH:mm:ssZ');
      news.validUntil = this.form.value.validTo?.format('YYYY-MM-DDTHH:mm:ssZ');
      news.isPublished = this.form.value.publish;
      console.log(news);

      this.isLoading = true;
      this.locationClient.postUpdateLocationHints(news).subscribe(
        (response: any) =>
          this.handleSuccessWithMessage(
            response,
            this.translateService.instant('crud.update.success.sucess_update_msg')
          ),
        (error: any) => this.handleError(error)
      );
    }
  }

  onValidFromDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.minDate = event.value;
  }

  onValidToDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.maxDate = event.value;
  }

  dateFilter = (date: Date | null): boolean => {
    return !this.minDate || !date || date >= this.minDate;
  };

  private handleSuccessWithMessage(
    response: any,
    message: string,
    callback?: (response: any) => void
  ) {
    this.handleSuccess(response, callback);
    this.toastr.success(message);
  }

  private handleSuccess(response: any, callback?: (response: any) => void) {
    if (callback) {
      callback(response);
    }
    this.isLoading = false;
    console.log(response);
  }

  private handleError(error: any) {
    this.isLoading = false;
    console.log(error);
    const message = this.translateService.instant('crud.update.error.failed_to_update_msg');
    this.toastr.error(message);
  }
}
