import { Component, Input } from '@angular/core';

@Component({
  selector: 'smart-card',
  templateUrl: './smart-card.component.html',
  styleUrls: ['./smart-card.component.scss'],
})
export class SmartCardComponent {
  @Input() backButton = false;

  @Input() buttonColor = 'black';

  @Input() offset = 20;

  @Input() padding = 16;

  @Input() margin = 16;
}
