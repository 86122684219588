import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export interface BreadcrumbConfig {
  initiallyVisible?: boolean;
}

export const BREADCRUMB_CONFIG = new InjectionToken<BreadcrumbConfig>('BREADCRUMB_CONFIG');

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private static readonly DEFAULT_CONFIG: BreadcrumbConfig = {
    initiallyVisible: true,
  };

  constructor(@Inject(BREADCRUMB_CONFIG) @Optional() private config: BreadcrumbConfig) {
    this.config = config || { ...BreadcrumbService.DEFAULT_CONFIG };

    this.visibleState$.next(this.config?.initiallyVisible ?? true);
  }

  public visibleState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get visible$(): Observable<boolean> {
    return this.visibleState$.pipe(share());
  }

  public get visible(): boolean {
    return this.visibleState$.getValue();
  }

  public show() {
    this.visibleState$.next(true);
  }

  public hide() {
    this.visibleState$.next(false);
  }
}
