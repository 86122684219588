import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { FeatureNames, FeatureToggle, UseLocationModuleFeature } from '@shared/features';

@Injectable()
export class FeatureToggleService {
  constructor(private msalService: MsalService) {}

  private registration: FeatureToggle<any>[] = [new UseLocationModuleFeature(this.msalService)];

  public isEnabled(featureName: FeatureNames): boolean {
    const feature = this.registration.find(x => x.name === featureName);
    if (!feature) {
      throw new Error(`Feature toggle ${featureName} is not registered`);
    }
    return feature.isEnabled();
  }

  public isChildFeatureEnabled(
    parentFeatureName: FeatureNames,
    childFeatureName: FeatureNames
  ): boolean {
    const feature = this.registration.find(x => x.name === parentFeatureName);
    if (feature) {
      if (feature.isEnabled()) {
        const childFeature = feature.childFeatures.find(x => x.name === childFeatureName);
        if (childFeature) {
          return childFeature.isEnabled();
        } else {
          throw new Error(`Child feature toggle ${childFeatureName} is not registered`);
        }
      } else {
        console.warn('Parent feature is not enabled.');
        return false;
      }
    }
    throw new Error(`Parent feature toggle ${childFeatureName} is not registered`);
  }
}
