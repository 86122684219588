<div *ngIf="data.type == 'okDismissButton' || 'okButton' || 'noButtons'">
  <h1 *ngIf="data.title" mat-dialog-title [innerHtml]="data.title | translate"></h1>
  <div mat-dialog-content *ngIf="data.message">
    <p [innerHtml]="data.message | translate"></p>
  </div>
  <div mat-dialog-actions align="center">
    <button
      mat-raised-button
      color="{{ data.cancelColor }}"
      (click)="onDismiss()"
      *ngIf="data.type == 'okDismissButton'"
    >
      <span [innerHtml]="(data.dismissButtonText! | translate) || 'Cancel'">cancel</span>
    </button>
    <button
      mat-raised-button
      color="{{ data.confirmColor }}"
      (click)="onOk()"
      *ngIf="data.type == 'okDismissButton' || 'okButton'"
    >
      <span [innerHtml]="(data.okButtonText! | translate) || 'Ok'">ok</span>
    </button>
  </div>
</div>
