<div class="container">
  <input
    type="file"
    multiple
    [accept]="contentTypes"
    (change)="onFileSelected($event)"
    title=""
    #fileUpload
  />
  <h3>{{ 'crud.fileUpload.dragAndDrop.title' | translate }}</h3>
  <h3>{{ 'crud.fileUpload.dragAndDrop.titleOr' | translate }}</h3>
  <button mat-raised-button color="primary" class="browseButton">
    {{ 'crud.fileUpload.dragAndDrop.browse' | translate }}
  </button>

  <mat-form-field
    *ngIf="hasDocumentVisibilitySelection && documentVisibilities"
    appearance="outline"
    class="drag-and-drop-form-field"
  >
    <mat-label>{{ 'documents.label__documentVisibility' | translate }}</mat-label>
    <mat-select style="z-index: 9999" [(ngModel)]="selectedVisibilityDragAndDrop">
      <mat-option
        *ngFor="let option of documentVisibilities"
        [value]="$any(option).label"
        [matTooltip]="$any(option).tooltip"
      >
        {{ $any(option).label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br />
  <br />
</div>
<div class="files-list">
  <div class="single-file" *ngFor="let typedFileForm of typedFiles.controls; let i = index">
    <div
      flexLayout="row"
      fxFlex="100"
      fxLayoutAlign="center center"
      [formGroup]="castToFormGroup(typedFileForm)"
    >
      <mat-icon>attach_file</mat-icon>
      <div class="break-all info">
        <h4 class="name">
          {{ typedFileForm.value.file.name }}
        </h4>
        <p class="size">
          {{ formatBytes(typedFileForm.value.file.size) }}
        </p>
        <app-progress [progress]="typedFileForm?.progress"></app-progress>
        <mat-error *ngIf="checkForFileSizeError(typedFileForm) as errorMessage">
          <span [innerHtml]="errorMessage"></span>
        </mat-error>
      </div>
      <div>
        <mat-form-field
          *ngIf="hasDocumentVisibilitySelection && documentVisibilities"
          appearance="outline"
          class="drag-and-drop-form-field"
        >
          <mat-label>{{ 'documents.label__documentVisibility' | translate }}</mat-label>
          <mat-select style="z-index: 9999" [(value)]="typedFileForm.value.file.visibilityExtern">
            <mat-option
              *ngFor="let option of documentVisibilities"
              [value]="$any(option).label"
              [matTooltip]="$any(option).tooltip"
            >
              {{ $any(option).label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-error *ngIf="typedFileForm.get('documentTypeId').hasError('required')">
        <span [innerHtml]="'crud.fileUpload.error.required' | translate"></span>
      </mat-error>

      <button mat-button color="accent" (click)="detachFile(i)" [disabled]="busy">
        <mat-icon class="delete">close</mat-icon>
      </button>
    </div>
  </div>
</div>
<div flexLayout="row" fxLayoutGap="1em" fxLayoutAlign="center start">
  <div fxLayout="column">
    <button
      type="button"
      mat-raised-button
      fxFlex="100"
      color="accent"
      (click)="uploadFiles()"
      [disabled]="busy || !typedFiles.value.length"
      [style.margin-bottom.em]="1"
    >
      <mat-icon>upload</mat-icon> <span [innerText]="'crud.file.upload' | translate"></span>
    </button>
    <mat-error *ngIf="!form.valid && form.touched">
      <span [innerHtml]="'crud.fileUpload.error.missingRequiredFields' | translate"></span>
    </mat-error>
  </div>
</div>

<spinner-overlay [show]="busy"></spinner-overlay>
