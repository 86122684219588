import { Component } from '@angular/core';
import { HelpService } from '@shared/services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'help-dialog',
  templateUrl: './help-dialog.component.html',
})
export class HelpDialogComponent {
  constructor(public helpService: HelpService) {}
}
