import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldBaseService } from '@strategies/_base/_form/services/formly-field.base';
import { Observable } from 'rxjs';
import { EmploymentClient } from '@api';
import { ToastrService } from 'ngx-toastr';
import { FormlyPreviewAutocompleteTemplateOptions } from '@shared/components/formly-preview-autocomplete-type/formly-preview-autocomplete-type.types';

@Injectable({
  providedIn: 'root',
})
export class ContactSearchFormlyFieldService extends FormlyFieldBaseService {
  translatePath = 'contact.autocomplete';

  constructor(
    protected translateService: TranslateService,
    private employmentClient: EmploymentClient,
    private toastr: ToastrService
  ) {
    super(translateService);
  }

  autocompleteSearch(
    defaultValue: any,
    onSelect: (id: any) => void,
    placeholder: string,
  ): FormlyFieldConfig[] {
    const translatePath = 'contact.autocomplete';

    const columnDefinitions = [
      { fieldName: 'fullName', labelTranslationKey: translatePath + '.name', classes: 'col-3' },
      { fieldName: 'phone', labelTranslationKey: translatePath + '.phone', classes: 'col-2' },
      { fieldName: 'mobilePhone', labelTranslationKey: translatePath + '.mobilePhone', classes: 'col-2' },
      { fieldName: 'company', labelTranslationKey: translatePath + '.company', classes: 'col-3' },
      { fieldName: 'orgUnit', labelTranslationKey: translatePath + '.orgUnit', classes: 'col-2' },
    ];
    const templateOptions: FormlyPreviewAutocompleteTemplateOptions = {
      required: false,
      visibleInConfirm: true,
      translatePath,
      columnDefinitions,
      defaultValue: defaultValue
        ? {
            ...defaultValue,
            id: defaultValue.archObjId,
          }
        : null,
      onSearch: (searchText: string): Observable<any> => {
        return this.employmentClient.searchEmployee(searchText);
      },
      onSelect: (searchResult: any): void => {
        onSelect(searchResult);
      },
      onError: (errorMessage: string): void => {
        this.toastr.error(this.translateService.instant(errorMessage));
      },
      onSuccess: (message: string): void => {
        this.toastr.info(this.translateService.instant(message));
      },
      label: this.translateService.instant(placeholder),
    };

    return [
      {
        type: 'preview-autocomplete',
        key: 'searchResult',
        templateOptions: { ...templateOptions },
      },
    ];
  }
}
