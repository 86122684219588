import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDataService } from '@routes/offering/shared/service/translate-data.service';

interface IImpressumItem {
  label: string;
  link?: string;
  value?: string;
  icon?: string;
  color?: string;
}

@Component({
  selector: 'app-footer-molewo',
  templateUrl: './footer-molewo.component.html',
  styleUrls: ['./footer-molewo.component.scss'],
})
export class FooterMolewoComponent implements OnInit {
  public information: Array<IImpressumItem>;

  public impressum: Array<IImpressumItem>;

  public ctaButtons: Array<IImpressumItem>;

  constructor(
    private translateService: TranslateService,
    private translateData: TranslateDataService
  ) {}

  ngOnInit() {
    this.information = [
      {
        label: this.translateService.instant('footer.home'),
        link: this.translateData.translate(
          'https://www.molewo.de/',
          'https://www.molewo.de/' //EN
        ),
      },
      {
        label: this.translateService.instant('footer.projects'),
        link: this.translateData.translate(
          'https://www.molewo.de/projekte.html',
          'https://www.molewo.de/projekte.html' //EN
        ),
      },
      {
        label: this.translateService.instant('footer.partnerships'),
        link: this.translateData.translate(
          'https://www.molewo.de/partner.html',
          'https://www.molewo.de/partner.html' //EN
        ),
      },
    ];

    this.impressum = [
      {
        label: this.translateService.instant('footer.imprint'),
        link: this.translateData.translate(
          'https://www.molewo.de/impressum.html',
          'https://www.molewo.de/impressum.html' //EN
        ),
      },
      {
        label: this.translateService.instant('footer.dataPrivacy'),
        link: this.translateData.translate(
          'https://www.molewo.de/datenschutz.html',
          'https://www.molewo.de/datenschutz.html' //EN
        ),
      },
    ];

    this.ctaButtons = [];
  }
}
