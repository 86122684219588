import { ComponentFactoryResolver, ViewContainerRef } from "@angular/core";
import { Mandant } from "@strategies";
import { FooterBrstComponent } from "@theme/footer/variations/footer-brst/footer-brst.component";
import { FooterDefaultComponent } from "@theme/footer/variations/footer-default/footer-default.component";
import { FooterMolewoComponent } from "@theme/footer/variations/footer-molewo/footer-molewo.component";

export const getFooterComponent = (cfr: ComponentFactoryResolver, dynamicContainer: ViewContainerRef, mandant: string) => {
    switch (mandant) {
        case Mandant.brst: 
            const componentFactoryBrst = cfr.resolveComponentFactory(FooterBrstComponent);
            return dynamicContainer.createComponent(componentFactoryBrst);
        case Mandant.molewo:
            const componentFactoryMolewo = cfr.resolveComponentFactory(FooterMolewoComponent);
            return dynamicContainer.createComponent(componentFactoryMolewo);
        default:
            const componentFactoryDefault = cfr.resolveComponentFactory(FooterDefaultComponent);
            return dynamicContainer.createComponent(componentFactoryDefault);
    }
};