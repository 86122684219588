import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function fileAttachments(translateService: TranslateService): Array<FormlyFieldConfig> {
  return [
    {
      type: 'chapter',
      templateOptions: {
        chapterTitle: translateService.instant('ticket.create.headline__attachment'),
      },
    },
    {
      key: 'attachments',
      type: 'repeat',
      templateOptions: {
        maxItems: 10,
        addText: translateService.instant('ticket.create.button__attach_new_file'),
        tooltip: translateService.instant('ticket.create.p__info_attachment'),
      },

      fieldArray: {
        type: 'flex-layout',
        templateOptions: {
          fxLayout: 'row',
        },
        fieldGroup: [
          {
            type: 'select',
            key: 'attachmentType',
            templateOptions: {
              label: translateService.instant('ticket.create.select__type_of_file'),
              required: true,
              visibleInConfirm: true,
              fxFlex: 'fxFlex',
              options: [
                { label: 'Flurstückskarte', value: 'AUG' },
                { label: 'Grundbuchauszug', value: 'AUG' },
                { label: 'Pläne', value: 'PL7' },
                { label: 'Schriftverkehr', value: 'SVK' },
                { label: 'Sonstiges', value: 'SO7' },
              ].sort((a, b) => {
                if (a.label === b.label) {
                  return 0;
                }
                return a.label < b.label ? -1 : 1;
              }),
            },
          },
          {
            type: 'file',
            key: 'attachment',

            templateOptions: {
              fxFlex: 'fxFlex',
              label: translateService.instant('ticket.create.label__attachment'),
              visibleInConfirm: true,
              accept: '.jpg, jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf',
              maxFileSizeInMB: '10',
            },
          },
        ],
      },
    },
  ];
}
