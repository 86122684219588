<div
  fxFlex="100"
  fxFlex.gt-sm="60"
  fxFlexOffset.gt-sm="20"
  fxFlexFill
  fxLayoutAlign="start start"
  fxLayout="column"
>
  <mat-form-field fxFlexFill>
    <mat-label>Addresseingabe</mat-label>

    <input matInput [(ngModel)]="searchTerm" (input)="getAmenity()" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAddressSelected($event)">
      <mat-option *ngFor="let option of searchResult" [value]="option">
        {{ option.formatted }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div>
    <p>
      name: {{ location?.street }} {{ location?.housenumber }}; postcode: {{ location?.postcode }};
      city: {{ location?.city }};
    </p>
  </div>
</div>
