import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner-overlay-telekom',
  templateUrl: './spinner-overlay-telekom.component.html',
  styleUrls: ['./spinner-overlay-telekom.component.scss'],
})
export class SpinnerOverlayTelekomComponent {
  @Input() show: boolean | null = false;
}
