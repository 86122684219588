import { Component, Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationNotificationComponent } from '../location-notification/location-notification.component';

@Component({
  selector: 'app-telekom-email-template',
  templateUrl: './telekom-email-template.component.html',
  styleUrls: ['./telekom-email-template.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class TelekomEmailTemplateComponent {
  @Input() address;

  date = new Date();

  emailContent = '';

  placeholder = '';

  constructor(
    private locationNotification: LocationNotificationComponent,
    private translateService: TranslateService
  ) {
    this.locationNotification.emailContent.subscribe(content => {
      this.emailContent = content;
    });
    this.placeholder = this.translateService.instant(
      'location.myLocation.location_notification.enter_text_here'
    );
  }
}
