<a
    class="matero-toolbar-button matero-avatar-button"
    mat-button
    [href]="url"
>
    <mat-icon>{{icon}}</mat-icon>
    <span
        class="matero-username"
        fxHide.lt-sm
    >
        {{ translationKey | translate }}
    </span>
</a>
