import { CommonModule } from '@angular/common';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { SmartPortalApiModule } from '@api';
import { DefaultPageComponent } from './default-page/default-page.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ErrorHandlerModule } from './error-handler';
import { throwIfAlreadyLoaded } from './module-import-guard';

const PROVIDERS = [];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost',
  },
  position: 'top-right',
  theme: 'block',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    header: ',Cookies used on the website!',
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    // href: '#',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [DefaultPageComponent, CookieConsentComponent],
  imports: [
    CommonModule,
    ErrorHandlerModule,
    SmartPortalApiModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  exports: [CookieConsentComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...PROVIDERS],
    };
  }
}
