<smart-card [backButton]="false">
  <ng-container navigation *ngIf="navigation">
    <button *ngIf="!filledForm" fxFlex="nogrow" mat-raised-button color="primary" navigateBack
            [style.margin-bottom.px]="10">
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span class="customSendIconLeft"></span>
      <span [innerHtml]="'back' | translate | uppercase"></span>
    </button>
    <button *ngIf="filledForm" fxFlex="nogrow" mat-raised-button color="primary" (click)="discardSumUpForm()"
            [style.margin-bottom.px]="10">
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span class="customSendIconLeft"></span>
      <span [innerHtml]="'back' | translate | uppercase"></span>
    </button>
  </ng-container>

  <h2 [hidden]="filledForm" [innerHtml]="title"></h2>
  <form (ngSubmit)="validateForm()" [formGroup]="form">
    <div [hidden]="filledForm">
      <p [innerHtml]="description"></p>

      <formly-form [form]="form" [fields]="formFields" [model]="formModel" [options]="options"></formly-form>

      <div>
        <button
          fxFlex="50"
          fxFlexOffset="25"
          mat-raised-button
          color="accent"
          type="submit"
        >
          <span [innerText]="'forward'|translate "> </span>
          <span class="customSendIconRight"></span>
          <mat-icon class="materialSendIcon">arrow_forward</mat-icon>
        </button>
      </div>
    </div>

  </form>
  <div *ngIf="filledForm">
    <app-form-confirm
      [filledFields]="filledForm"
      [extraConfirmFormFields]="showTicketCreateData ? !extraConfirmFormFields : extraConfirmFormFields"
      [backButton]="!navigation"
      [showTicketCreateData]="showTicketCreateData"
      (confirmed)="submit()"
      (backToEdit)="discardSumUpForm()"
    ></app-form-confirm>
  </div>
</smart-card>

<spinner-overlay [show]="loading"></spinner-overlay>
