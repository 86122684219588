import { RegexpMultipleEmail } from '@core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function emailcc(
  translateService: TranslateService,
  onEmailChange: (emails: any) => void
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.label__notification'),
            chapterText: translateService.instant('ticket.create.p__info_notification'),
          },
        },
        {
          key: 'ccemail',
          type: 'input',
          templateOptions: {
            label: translateService.instant('ticket.create.label__emailcc'),
            placeholder: 'E-Mail',
            pattern: RegexpMultipleEmail,
          },
          validation: {
            messages: {
              pattern: (_err, _field: FormlyFieldConfig) =>
                translateService.instant('formErrors.email.pattern'),
            },
          },
        },
        {
          key: 'emailccButton',
          type: 'button',
          templateOptions: {
            label: translateService.instant('ticket.create.label__emailcc_search_help'),
            change: emails => onEmailChange(emails),
          },
        },
      ],
    },
  ];
}
