import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ContentTypeViewModel,
  FmDocument,
  FmTask,
  OmContact,
  TargetSystem,
  UploadFileTempStorage,
  UploadFilesTempStorageRequest,
} from '@api';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { FileService } from '@shared/services/file.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

interface DocumentTypeFile extends File {
  documentTypeId?: string | null;
  visibilityExtern: string;
}

@Component({
  selector: 'app-detail-file-upload-default',
  templateUrl: './detail-file-upload.component.html',
  styleUrls: ['./detail-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFileUploadDefaultComponent implements OnInit {
  contentTypes = '*';

  @Input() hasTaskSelection = false;

  @Input() hasTargetSystemSelection = false;

  @Input() hasDocumentTypeSelection = false;

  @Input() hasDocumentVisibilitySelection = false;

  @Input() metadata: [{ attributeName: string; attributeValue: string }];

  @Input() typeIsRequired = false;

  @Input() tasks = Array<FmTask>();

  @Output() uploadEvent = new EventEmitter<any>();

  profile: OmContact | undefined;

  defaultTaskValue = '';

  defaultTaskLabel = '';

  defaultTaskControl = new FormControl('');

  filteredTasks: Observable<string[]>;

  documentTypes: Array<{
    value: string;
    label: string;
    maxFileSizeInBytes: number;
    contentTypes: ContentTypeViewModel[];
  }>;

  defaultDocumentTypeValue = '';

  defaultDocumentTypeLabel = '';

  defaultDocumentTypeControl = new FormControl('');

  filteredDocumentTypes: Observable<string[]>;

  targetSystems = [
    {
      value: TargetSystem.Remdoc,
      label: this.translateService.instant('documents.targetSystems.0'),
    },
  ];

  defaultTargetSystemValue = this.targetSystems[0].value;

  defaultTargetSystemLabel = this.targetSystems[0].label;

  defaultTargetSystemControl = new FormControl(this.defaultTargetSystemLabel);

  filteredTargetSystems: Observable<string[]>;

  busy = false;

  @ViewChild('fileUpload') fileUpload: ElementRef;

  form = this.fb.group({
    typedFiles: this.fb.array([]),
  });

  get typedFiles(): FormArray {
    return this.form.controls.typedFiles as FormArray;
  }

  filteredDocumentTypeOptions: Observable<any[]>[] = [];

  documentVisibilities = [
    {
      label: this.translateService.instant('documents.visibility.internal.label'),
      tooltip: this.translateService.instant('documents.visibility.internal.tooltip'),
    },
    {
      label: this.translateService.instant('documents.visibility.public.label'),
      tooltip: this.translateService.instant('documents.visibility.public.tooltip'),
    },
  ];

  selectedVisibilityDragAndDrop = this.translateService.instant(
    'documents.visibility.internal.label'
  );

  selectedVisibilitySingleFile = '';

  constructor(
    private fileService: FileService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private msalService: MsalService
  ) {}

  ngOnInit() {
    this.initTargetSystemOptions();

    if (this.hasDocumentTypeSelection) {
      this.fileService.getDocumentTypes().subscribe((documentTypes: any) => {
        this.documentTypes = documentTypes;

        this.defaultDocumentTypeValue =
          this.metadata.find(metadata => metadata.attributeName === 'defaultDocumentTypeId')
            ?.attributeValue ?? '';

        this.defaultDocumentTypeLabel =
          this.documentTypes.find(
            documentType => documentType.value === this.defaultDocumentTypeValue
          )?.label ?? '';

        this.defaultDocumentTypeControl.setValue(this.defaultDocumentTypeLabel);

        this.cd.detectChanges();
      });
    }

    this.initFilteredTasks();
    this.initFilteredDocumentTypes();
    this.initFilteredTargetSystems();
  }

  onTaskSelected(event: any) {
    const selectedTask = this.tasks.find((task: any) => task.subject === event.option?.value);
    if (selectedTask) {
      this.defaultTaskValue = selectedTask.taskId ?? '';
      this.defaultTaskLabel = selectedTask.subject ?? '';
    }
  }

  onDocumentTypeSelected(event: any) {
    const selectedDocumentType = this.documentTypes.find(
      (documentType: any) => documentType.label === event.option?.value
    );
    if (selectedDocumentType) {
      this.defaultDocumentTypeValue = selectedDocumentType.value;
      this.defaultDocumentTypeLabel = selectedDocumentType.label;
    }
  }

  onTargetSystemSelected(event: any) {
    const selectedTargetSystem = this.targetSystems.find(
      (targetSystem: any) => targetSystem.label === event.option?.value
    );
    if (selectedTargetSystem) {
      this.defaultTargetSystemValue = selectedTargetSystem.value;
      this.defaultTargetSystemLabel = selectedTargetSystem.label;
    }
  }

  onDocumentTypeOptionSelected(event: any, index: number) {
    const selectedDocumentType = this.documentTypes.find(
      (documentType: any) => documentType.label === event.option?.value
    );
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectField = arrayControlObject.get('documentTypeId');
      arrayControlObjectField?.setValue(selectedDocumentType?.value);
    }
  }

  onDocumentTypeOptionOnBlur(index: number) {
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectFieldLabel = arrayControlObject.get('documentTypeLabel');
      const arrayControlObjectFieldId = arrayControlObject.get('documentTypeId');

      setTimeout(() => {
        if (arrayControlObjectFieldLabel && arrayControlObjectFieldId) {
          this.checkControlLabel(this.documentTypes, 'label', arrayControlObjectFieldLabel, () => {
            arrayControlObjectFieldLabel.setValue('');
            arrayControlObjectFieldId.setValue('');
            this.cd.detectChanges();
          });
        }
        this.initDocumentTypesOptions(index);
      }, 1000);
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  checkForFileSizeError(typedFileForm: FormGroup): any {
    const documentTypeId = typedFileForm.get('documentTypeId')?.value;
    const maxMB = this.getSelectedDocumentTypeMaxMB(documentTypeId);
    const [isValid, message] = this.fileService.validateFile(typedFileForm.value.file, maxMB);
    if (!isValid) {
      return message;
    }

    return false;
  }

  defaultTaskOnBlur(): void {
    setTimeout(() => {
      this.checkControlLabel(this.tasks, 'subject', this.defaultTaskControl, () => {
        this.defaultTaskValue = '';
        this.defaultTaskLabel = '';
        this.defaultTaskControl.setValue(this.defaultTaskLabel);
        this.cd.detectChanges();
      });
      this.initFilteredTasks();
    }, 1000);
  }

  defaultDocumentTypeOnBlur(): void {
    setTimeout(() => {
      this.checkControlLabel(this.documentTypes, 'label', this.defaultDocumentTypeControl, () => {
        this.defaultDocumentTypeValue = '';
        this.defaultDocumentTypeLabel = '';
        this.defaultDocumentTypeControl.setValue(this.defaultDocumentTypeLabel);
        this.cd.detectChanges();
      });
      this.initFilteredDocumentTypes();
    }, 1000);
  }

  defaultTargetSystemOnBlur(): void {
    setTimeout(() => {
      this.checkControlLabel(this.targetSystems, 'label', this.defaultTargetSystemControl, () => {
        this.defaultTargetSystemValue = this.targetSystems[0].value;
        this.defaultTargetSystemLabel = this.targetSystems[0].label;
        this.defaultTargetSystemControl.setValue(this.defaultTargetSystemLabel);
        this.cd.detectChanges();
      });
      this.initFilteredTargetSystems();
    }, 1000);
  }

  private initFilteredTasks() {
    if (!this.hasTaskSelection) {
      return;
    }
    this.filteredTasks = this.defaultTaskControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTasks(value || ''))
    );
    this.cd.detectChanges();
  }

  private initFilteredDocumentTypes() {
    if (!this.hasDocumentTypeSelection) {
      return;
    }
    this.filteredDocumentTypes = this.defaultDocumentTypeControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterDocumentTypes(value || ''))
    );
    this.cd.detectChanges();
  }

  private filterTasks(taskSubject: string): any[] {
    const filterValue = taskSubject.toLowerCase();
    return this.tasks?.filter(option => option.subject?.toLowerCase().includes(filterValue));
  }

  private checkControlLabel(
    list: any[],
    listItemAttribute: string,
    control: AbstractControl,
    callback: Function
  ) {
    if (!list.find(item => item[listItemAttribute] === control.value)) {
      callback();
    }
  }

  private filterDocumentTypes(documentTypeLabel: string): any[] {
    const filterValue = documentTypeLabel.toLowerCase();
    return this.documentTypes?.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  private initFilteredTargetSystems() {
    if (!this.hasTargetSystemSelection) {
      return;
    }
    this.filteredTargetSystems = this.defaultTargetSystemControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTargetSystems(value || ''))
    );
    this.cd.detectChanges();
  }

  private filterTargetSystems(targetSystemLabel: string): any[] {
    const filterValue = targetSystemLabel.toLowerCase();
    return this.targetSystems?.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  async uploadFiles() {
    let isInternalFile: boolean | null = null;
    const MAX_FILE_SIZE = 200;
    this.form.markAllAsTouched();
    if (this.typedFiles.value === null || !this.typedFiles.valid) {
      return;
    }
    const [isValid, message, title] = this.fileService.validateTotalFileSize(
      this.form.value.typedFiles,
      (accumulator, current) => accumulator + current.file.size,
      MAX_FILE_SIZE
    );
    if (!isValid) {
      this.toastr.error(message, title);
      return;
    }
    this.busy = true;
    const errorFiles: FormGroup[] = [];
    const packedFiles: Array<UploadFileTempStorage> = [];
    for await (const formGroup of this.typedFiles.value) {
      if (
        formGroup.file.visibilityExtern ===
        this.translateService.instant('documents.visibility.internal.label')
      ) {
        isInternalFile = true;
      } else if (
        formGroup.file.visibilityExtern ===
        this.translateService.instant('documents.visibility.public.label')
      ) {
        isInternalFile = false;
      }
      const { file, documentTypeId, visibilityId, targetSystem, taskId } = formGroup;
      const contentBase64Encoded = (await this.fileService.fileRead(file)).split(',')[1];

      const maxMB = this.getSelectedDocumentTypeMaxMB(documentTypeId);
      const [isFileValid] = this.fileService.validateFile(file, maxMB);

      if (isFileValid) {
        const uploadFile = <UploadFileTempStorage>{
          contentBase64Encoded,
          metadata: {
            ...(<FmDocument>{
              isInternal: isInternalFile,
              documentId: '',
              documentTypeId,
              name: file.name,
              statusCode: 'Aktiv',
              filename: file.name,
              visibilityId,
            }),
            ...this.metadata,
          },
          targetSystem,
        };

        if (taskId) {
          uploadFile.metadata.taskId = taskId;
        }

        // populate metadata attributes
        this.metadata.forEach(m => {
          uploadFile.metadata[m.attributeName] = m.attributeValue;
        });

        packedFiles.push(uploadFile);
      } else {
        errorFiles.push(formGroup);
      }
    }

    if (!packedFiles.length) {
      // nothing to upload
      this.busy = false;
      this.cd.detectChanges();
      return;
    }

    let targetEntityId = 'entity-not-configured';
    const taskMetadata = this.metadata.find(m => m.attributeName === 'taskId');
    const incidentMetadata = this.metadata.find(m => m.attributeName === 'incidentId');
    const contactMetadata = this.metadata.find(m => m.attributeName === 'contactId');
    const rentalContractMetadata = this.metadata.find(m => m.attributeName === 'rentalContractId');
    const relationId = this.metadata.find(m => m.attributeName.includes('Id'));

    if (taskMetadata?.attributeValue) {
      targetEntityId = taskMetadata?.attributeValue;
    } else if (incidentMetadata?.attributeValue) {
      targetEntityId = incidentMetadata.attributeValue;
    } else if (contactMetadata?.attributeValue) {
      targetEntityId = contactMetadata?.attributeValue;
    } else if (rentalContractMetadata?.attributeValue) {
      targetEntityId = rentalContractMetadata.attributeValue;
    } else if (relationId?.attributeValue) {
      targetEntityId = relationId.attributeValue;
    }

    const uploadFilesTempStorageRequest: UploadFilesTempStorageRequest = {
      targetEntityId,
      files: packedFiles,
    };

    // Checks whether the user is a brst account an changes document visibility
    if (this.hasDocumentVisibilitySelection) {
      uploadFilesTempStorageRequest.files.forEach(file => {
        if (isInternalFile) {
          file.metadata.isInternal = isInternalFile;
        }
      });
    } else {
      uploadFilesTempStorageRequest.files.forEach(file => {
        file.metadata.isInternal = false;
      });
    }

    this.fileService
      .uploadFilesTempStorage(uploadFilesTempStorageRequest)
      .pipe(first())
      .subscribe(
        result => {
          this.typedFiles.clear();

          errorFiles.forEach(item => {
            this.typedFiles.push(item);
          });

          this.toastr.success(this.translateService.instant('crud.fileUpload.started'));
          this.busy = false;
          this.uploadEvent.emit(result);
          this.fileUpload.nativeElement.value = '';
          this.cd.detectChanges();
        },
        error => {
          this.busy = false;
          console.log(error);
          this.toastr.error(this.translateService.instant('crud.fileUpload.error.upload'));
          this.fileUpload.nativeElement.value = '';
          this.cd.detectChanges();
        }
      );
  }

  castToFormGroup(formGroup: any) {
    return formGroup as FormGroup;
  }

  onFileSelected(event: any) {
    if (event) {
      const files: DocumentTypeFile[] = [...event.target.files];
      files.forEach(file => {
        this.addTypedFiles(file);
      });
    }
  }

  private initDocumentTypesOptions(index: number) {
    const arrayControl = this.form.get('typedFiles') as FormArray;
    const arrayControlObject = arrayControl.at(index);
    if (arrayControlObject) {
      const arrayControlObjectField = arrayControlObject.get('documentTypeLabel');
      if (arrayControl && arrayControlObject && arrayControlObjectField) {
        this.filteredDocumentTypeOptions[index] = arrayControlObjectField.valueChanges.pipe(
          startWith(''),
          map(value => this.filterDocumentTypes(value || ''))
        );
      }
    }
  }

  private addTypedFiles(file: DocumentTypeFile) {
    file.visibilityExtern = this.selectedVisibilityDragAndDrop;

    const maxMB = this.getSelectedDocumentTypeMaxMB(this.defaultDocumentTypeValue);
    const [isValid, message, title] = this.fileService.validateFile(file, maxMB);
    if (!isValid) {
      this.toastr.error(message, title);
      this.fileUpload.nativeElement.value = '';
      return;
    }

    const fileForm = this.fb.group({
      taskId: [this.defaultTaskValue],
      targetSystem: [
        this.defaultTargetSystemValue,
        this.hasTargetSystemSelection ? Validators.required : '',
      ],
      documentTypeId: [
        this.defaultDocumentTypeValue,
        this.hasDocumentTypeSelection ? Validators.required : '',
      ],
      documentTypeLabel: [this.defaultDocumentTypeLabel],
      file: [file],
    });

    this.typedFiles.push(fileForm);
    this.initDocumentTypesOptions(this.typedFiles.length - 1);
    this.cd.detectChanges();
  }

  detachFile(index: number) {
    this.typedFiles.removeAt(index);
    this.fileUpload.nativeElement.value = '';
    this.filteredDocumentTypeOptions.splice(index, 1);
    this.cd.detectChanges();
  }

  private getSelectedDocumentType(documentTypeId) {
    return this.documentTypes?.find((documentType: any) => documentType.value === documentTypeId);
  }

  private getSelectedDocumentTypeMaxMB(documentTypeId) {
    const fallbackMaxMB = 10;
    const selectedDocumentType = this.getSelectedDocumentType(documentTypeId);
    return selectedDocumentType?.maxFileSizeInBytes
      ? selectedDocumentType.maxFileSizeInBytes / 1024 / 1024
      : fallbackMaxMB;
  }

  private initTargetSystemOptions() {
    const userRoles = this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    const hasImaUpload = !!userRoles?.includes(UserRoles.SMARTPORTAL_DOCUMENT_IMAUPLOAD);
    if (hasImaUpload) {
      this.targetSystems.push({
        value: TargetSystem.ImageMaster,
        label: this.translateService.instant('documents.targetSystems.1'),
      });
    }
  }
}
