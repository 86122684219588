import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: 'a[navigateBack], button[navigateBack]',
})
export class NavigateBackDirective {
  constructor(private locationService: NavigationService) {}

  @HostListener('click') onClick() {
    this.locationService.back();
  }
}
