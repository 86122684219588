<div *ngIf="showUser" style="padding-left: 6px">
  <button *ngIf="!loggedinB2C && !loggedinCiam" (click)="OnLoginClick($event)" mat-button>
    LOGIN
  </button>

  <button
    *ngIf="loggedinB2C || loggedinCiam"
    class="matero-toolbar-button matero-avatar-button"
    mat-button
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>account_circle</mat-icon>
    <span class="matero-username" fxHide.lt-sm>{{ accountDisplayName }}</span>
  </button>

  <mat-menu #menu="matMenu">
    <button (click)="onProfileClick()" mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span>{{ 'menu.profile.module_name' | translate }}</span>
    </button>
    <button (click)="OnResetPasswordClick()" mat-menu-item>
      <mat-icon>lock_reset</mat-icon>
      <span>{{ 'profile.showPasswordResetButton' | translate }}</span>
    </button>
    <button (click)="OnLogoutClick($event)" mat-menu-item>
      <mat-icon>logout</mat-icon>
      <span>{{ 'menu.profile.logout_action' | translate }}</span>
    </button>
  </mat-menu>
</div>
