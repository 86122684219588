/* eslint-disable prettier/prettier */
export const countryList = [
  { code: 'AF', code3: 'AFG', number: '004', name_de: "Afghanistan", name: 'Afghanistan' },
  { code: 'AL', code3: 'ALB', number: '008', name_de: "Albanien", name: 'Albania' },
  { code: 'DZ', code3: 'DZA', number: '012', name_de: "Algerien", name: 'Algeria' },
  { code: 'AS', code3: 'ASM', number: '016', name_de: "Amerikanisch-Samoa", name: 'American Samoa' },
  { code: 'AD', code3: 'AND', number: '020', name_de: "Andorra", name: 'Andorra' },
  { code: 'AO', code3: 'AGO', number: '024', name_de: "Angola", name: 'Angola' },
  { code: 'AI', code3: 'AIA', number: '660', name_de: "Anguilla", name: 'Anguilla' },
  { code: 'AQ', code3: 'ATA', number: '010', name_de: "Antarktis", name: 'Antarctica' },
  { code: 'AG', code3: 'ATG', number: '028', name_de: "Antigua und Barbuda", name: 'Antigua and Barbuda' },
  { code: 'AR', code3: 'ARG', number: '032', name_de: "Argentinien", name: 'Argentina' },
  { code: 'AM', code3: 'ARM', number: '051', name_de: "Armenien", name: 'Armenia' },
  { code: 'AW', code3: 'ABW', number: '533', name_de: "Aruba", name: 'Aruba' },
  { code: 'AU', code3: 'AUS', number: '036', name_de: "Australien", name: 'Australia' },
  { code: 'AT', code3: 'AUT', number: '040', name_de: "Österreich", name: 'Austria' },
  { code: 'AZ', code3: 'AZE', number: '031', name_de: "Aserbaidschan", name: 'Azerbaijan' },
  { code: 'BS', code3: 'BHS', number: '044', name_de: "Bahamas (die)", name: 'Bahamas (the)' },
  { code: 'BH', code3: 'BHR', number: '048', name_de: "Bahrain", name: 'Bahrain' },
  { code: 'BD', code3: 'BGD', number: '050', name_de: "Bangladesch", name: 'Bangladesh' },
  { code: 'BB', code3: 'BRB', number: '052', name_de: "Barbados", name: 'Barbados' },
  { code: 'BY', code3: 'BLR', number: '112', name_de: "Weißrussland", name: 'Belarus' },
  { code: 'BE', code3: 'BEL', number: '056', name_de: "Belgien", name: 'Belgium' },
  { code: 'BZ', code3: 'BLZ', number: '084', name_de: "Belize", name: 'Belize' },
  { code: 'BJ', code3: 'BEN', number: '204', name_de: "Benin", name: 'Benin' },
  { code: 'BM', code3: 'BMU', number: '060', name_de: "Bermuda", name: 'Bermuda' },
  { code: 'BT', code3: 'BTN', number: '064', name_de: "Bhutan", name: 'Bhutan' },
  { code: 'BO', code3: 'BOL', number: '068', name_de: "Bolivien (Plurinationaler Staat)", name: 'Bolivia (Plurinational State of)' },
  { code: 'BQ', code3: 'BES', number: '535', name_de: "Bonaire, Sint Eustatius und Saba", name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', code3: 'BIH', number: '070', name_de: "Bosnien und Herzegowina", name: 'Bosnia and Herzegovina' },
  { code: 'BW', code3: 'BWA', number: '072', name_de: "Botswana", name: 'Botswana' },
  { code: 'BV', code3: 'BVT', number: '074', name_de: "Bouvetinsel", name: 'Bouvet Island' },
  { code: 'BR', code3: 'BRA', number: '076', name_de: "Brasilien", name: 'Brazil' },
  { code: 'IO', code3: 'IOT', number: '086', name_de: "Britisches Territorium im Indischen Ozean (das)", name: 'British Indian Ocean Territory (the)' },
  { code: 'BN', code3: 'BRN', number: '096', name_de: "Brunei Darussalam", name: 'Brunei Darussalam' },
  { code: 'BG', code3: 'BGR', number: '100', name_de: "Bulgarien", name: 'Bulgaria' },
  { code: 'BF', code3: 'BFA', number: '854', name_de: "Burkina Faso", name: 'Burkina Faso' },
  { code: 'BI', code3: 'BDI', number: '108', name_de: "Burundi", name: 'Burundi' },
  { code: 'CV', code3: 'CPV', number: '132', name_de: "Cabo Verde", name: 'Cabo Verde' },
  { code: 'KH', code3: 'KHM', number: '116', name_de: "Kambodscha", name: 'Cambodia' },
  { code: 'CM', code3: 'CMR', number: '120', name_de: "Kamerun", name: 'Cameroon' },
  { code: 'CA', code3: 'CAN', number: '124', name_de: "Kanada", name: 'Canada' },
  { code: 'KY', code3: 'CYM', number: '136', name_de: "Cayman-Inseln (die)", name: 'Cayman Islands (the)' },
  { code: 'CF', code3: 'CAF', number: '140', name_de: "Zentralafrikanische Republik (die)", name: 'Central African Republic (the)' },
  { code: 'TD', code3: 'TCD', number: '148', name_de: "Tschad", name: 'Chad' },
  { code: 'CL', code3: 'CHL', number: '152', name_de: "Chile", name: 'Chile' },
  { code: 'CN', code3: 'CHN', number: '156', name_de: "China", name: 'China' },
  { code: 'CX', code3: 'CXR', number: '162', name_de: "Weihnachtsinsel", name: 'Christmas Island' },
  { code: 'CC', code3: 'CCK', number: '166', name_de: "Kokosinseln (Keeling) (die)", name: 'Cocos (Keeling) Islands (the)' },
  { code: 'CO', code3: 'COL', number: '170', name_de: "Kolumbien", name: 'Colombia' },
  { code: 'KM', code3: 'COM', number: '174', name_de: "Komoren (die)", name: 'Comoros (the)' },
  { code: 'CD', code3: 'COD', number: '180', name_de: "Kongo (Demokratische Republik)", name: 'Congo (the Democratic Republic of the)' },
  { code: 'CG', code3: 'COG', number: '178', name_de: "Kongo (die)", name: 'Congo (the)' },
  { code: 'CK', code3: 'COK', number: '184', name_de: "Cookinseln (die)", name: 'Cook Islands (the)' },
  { code: 'CR', code3: 'CRI', number: '188', name_de: "Costa Rica", name: 'Costa Rica' },
  { code: 'HR', code3: 'HRV', number: '191', name_de: "Kroatien", name: 'Croatia' },
  { code: 'CU', code3: 'CUB', number: '192', name_de: "Kuba", name: 'Cuba' },
  { code: 'CW', code3: 'CUW', number: '531', name_de: "Curaçao", name: 'Curaçao' },
  { code: 'CY', code3: 'CYP', number: '196', name_de: "Zypern", name: 'Cyprus' },
  { code: 'CZ', code3: 'CZE', number: '203', name_de: "Tschechische Republik", name: 'Czechia' },
  { code: 'CI', code3: 'CIV', number: '384', name_de: "Côte d'Ivoire", name: "Côte d'Ivoire" },
  { code: 'DK', code3: 'DNK', number: '208', name_de: "Dänemark", name: 'Denmark' },
  { code: 'DJ', code3: 'DJI', number: '262', name_de: "Dschibuti", name: 'Djibouti' },
  { code: 'DM', code3: 'DMA', number: '212', name_de: "Dominica", name: 'Dominica' },
  { code: 'DO', code3: 'DOM', number: '214', name_de: "Dominikanische Republik (die)", name: 'Dominican Republic (the)' },
  { code: 'EC', code3: 'ECU', number: '218', name_de: "Ecuador", name: 'Ecuador' },
  { code: 'EG', code3: 'EGY', number: '818', name_de: "Ägypten", name: 'Egypt' },
  { code: 'SV', code3: 'SLV', number: '222', name_de: "El Salvador", name: 'El Salvador' },
  { code: 'GQ', code3: 'GNQ', number: '226', name_de: "Äquatorialguinea", name: 'Equatorial Guinea' },
  { code: 'ER', code3: 'ERI', number: '232', name_de: "Eritrea", name: 'Eritrea' },
  { code: 'EE', code3: 'EST', number: '233', name_de: "Estland", name: 'Estonia' },
  { code: 'SZ', code3: 'SWZ', number: '748', name_de: "Eswatini", name: 'Eswatini' },
  { code: 'ET', code3: 'ETH', number: '231', name_de: "Äthiopien", name: 'Ethiopia' },
  { code: 'FK', code3: 'FLK', number: '238', name_de: "Falklandinseln (die) [Malwinen]", name: 'Falkland Islands (the) [Malvinas]' },
  { code: 'FO', code3: 'FRO', number: '234', name_de: "Färöer Inseln (die)", name: 'Faroe Islands (the)' },
  { code: 'FJ', code3: 'FJI', number: '242', name_de: "Fidschi", name: 'Fiji' },
  { code: 'FI', code3: 'FIN', number: '246', name_de: "Finnland", name: 'Finland' },
  { code: 'FR', code3: 'FRA', number: '250', name_de: "Frankreich", name: 'France' },
  { code: 'GF', code3: 'GUF', number: '254', name_de: "Französisch-Guayana", name: 'French Guiana' },
  { code: 'PF', code3: 'PYF', number: '258', name_de: "Französisch-Polynesien", name: 'French Polynesia' },
  { code: 'TF', code3: 'ATF', number: '260', name_de: "Französische Südterritorien (die)", name: 'French Southern Territories (the)' },
  { code: 'GA', code3: 'GAB', number: '266', name_de: "Gabun", name: 'Gabon' },
  { code: 'GM', code3: 'GMB', number: '270', name_de: "Gambia (die)", name: 'Gambia (the)' },
  { code: 'GE', code3: 'GEO', number: '268', name_de: "Georgien", name: 'Georgia' },
  { code: 'DE', code3: 'DEU', number: '276', name_de: "Deutschland", name: 'Germany' },
  { code: 'GH', code3: 'GHA', number: '288', name_de: "Ghana", name: 'Ghana' },
  { code: 'GI', code3: 'GIB', number: '292', name_de: "Gibraltar", name: 'Gibraltar' },
  { code: 'GR', code3: 'GRC', number: '300', name_de: "Griechenland", name: 'Greece' },
  { code: 'GL', code3: 'GRL', number: '304', name_de: "Grönland", name: 'Greenland' },
  { code: 'GD', code3: 'GRD', number: '308', name_de: "Grenada", name: 'Grenada' },
  { code: 'GP', code3: 'GLP', number: '312', name_de: "Guadeloupe", name: 'Guadeloupe' },
  { code: 'GU', code3: 'GUM', number: '316', name_de: "Guam", name: 'Guam' },
  { code: 'GT', code3: 'GTM', number: '320', name_de: "Guatemala", name: 'Guatemala' },
  { code: 'GG', code3: 'GGY', number: '831', name_de: "Guernsey", name: 'Guernsey' },
  { code: 'GN', code3: 'GIN', number: '324', name_de: "Guinea", name: 'Guinea' },
  { code: 'GW', code3: 'GNB', number: '624', name_de: "Guinea-Bissau", name: 'Guinea-Bissau' },
  { code: 'GY', code3: 'GUY', number: '328', name_de: "Guyana", name: 'Guyana' },
  { code: 'HT', code3: 'HTI', number: '332', name_de: "Haiti", name: 'Haiti' },
  { code: 'HM', code3: 'HMD', number: '334', name_de: "Heard Island und McDonald Islands", name: 'Heard Island and McDonald Islands' },
  { code: 'VA', code3: 'VAT', number: '336', name_de: "Heiliger Stuhl (der)", name: 'Holy See (the)' },
  { code: 'HN', code3: 'HND', number: '340', name_de: "Honduras", name: 'Honduras' },
  { code: 'HK', code3: 'HKG', number: '344', name_de: "Hongkong", name: 'Hong Kong' },
  { code: 'HU', code3: 'HUN', number: '348', name_de: "Ungarn", name: 'Hungary' },
  { code: 'IS', code3: 'ISL', number: '352', name_de: "Island", name: 'Iceland' },
  { code: 'IN', code3: 'IND', number: '356', name_de: "Indien", name: 'India' },
  { code: 'ID', code3: 'IDN', number: '360', name_de: "Indonesien", name: 'Indonesia' },
  { code: 'IR', code3: 'IRN', number: '364', name_de: "Iran (Islamische Republik)", name: 'Iran (Islamic Republic of)' },
  { code: 'IQ', code3: 'IRQ', number: '368', name_de: "Irak", name: 'Iraq' },
  { code: 'IE', code3: 'IRL', number: '372', name_de: "Irland", name: 'Ireland' },
  { code: 'IM', code3: 'IMN', number: '833', name_de: "Isle of Man", name: 'Isle of Man' },
  { code: 'IL', code3: 'ISR', number: '376', name_de: "Israel", name: 'Israel' },
  { code: 'IT', code3: 'ITA', number: '380', name_de: "Italien", name: 'Italy' },
  { code: 'JM', code3: 'JAM', number: '388', name_de: "Jamaika", name: 'Jamaica' },
  { code: 'JP', code3: 'JPN', number: '392', name_de: "Japan", name: 'Japan' },
  { code: 'JE', code3: 'JEY', number: '832', name_de: "Jersey", name: 'Jersey' },
  { code: 'JO', code3: 'JOR', number: '400', name_de: "Jordanien", name: 'Jordan' },
  { code: 'KZ', code3: 'KAZ', number: '398', name_de: "Kasachstan", name: 'Kazakhstan' },
  { code: 'KE', code3: 'KEN', number: '404', name_de: "Kenia", name: 'Kenya' },
  { code: 'KI', code3: 'KIR', number: '296', name_de: "Kiribati", name: 'Kiribati' },
  { code: 'KP', code3: 'PRK', number: '408', name_de: "Korea (Demokratische Volksrepublik)", name: "Korea (the Democratic People's Republic of)" },
  { code: 'KR', code3: 'KOR', number: '410', name_de: "Korea (die Republik)", name: 'Korea (the Republic of)' },
  { code: 'KW', code3: 'KWT', number: '414', name_de: "Kuwait", name: 'Kuwait' },
  { code: 'KG', code3: 'KGZ', number: '417', name_de: "Kirgisistan", name: 'Kyrgyzstan' },
  { code: 'LA', code3: 'LAO', number: '418', name_de: "Demokratische Volksrepublik Laos (die)", name: "Lao People's Democratic Republic (the)" },
  { code: 'LV', code3: 'LVA', number: '428', name_de: "Lettland", name: 'Latvia' },
  { code: 'LB', code3: 'LBN', number: '422', name_de: "Libanon", name: 'Lebanon' },
  { code: 'LS', code3: 'LSO', number: '426', name_de: "Lesotho", name: 'Lesotho' },
  { code: 'LR', code3: 'LBR', number: '430', name_de: "Liberia", name: 'Liberia' },
  { code: 'LY', code3: 'LBY', number: '434', name_de: "Libyen", name: 'Libya' },
  { code: 'LI', code3: 'LIE', number: '438', name_de: "Liechtenstein", name: 'Liechtenstein' },
  { code: 'LT', code3: 'LTU', number: '440', name_de: "Litauen", name: 'Lithuania' },
  { code: 'LU', code3: 'LUX', number: '442', name_de: "Luxemburg", name: 'Luxembourg' },
  { code: 'MO', code3: 'MAC', number: '446', name_de: "Macau", name: 'Macao' },
  { code: 'MG', code3: 'MDG', number: '450', name_de: "Madagaskar", name: 'Madagascar' },
  { code: 'MW', code3: 'MWI', number: '454', name_de: "Malawi", name: 'Malawi' },
  { code: 'MY', code3: 'MYS', number: '458', name_de: "Malaysia", name: 'Malaysia' },
  { code: 'MV', code3: 'MDV', number: '462', name_de: "Malediven", name: 'Maldives' },
  { code: 'ML', code3: 'MLI', number: '466', name_de: "Mali", name: 'Mali' },
  { code: 'MT', code3: 'MLT', number: '470', name_de: "Malta", name: 'Malta' },
  { code: 'MH', code3: 'MHL', number: '584', name_de: "Marshallinseln (die)", name: 'Marshall Islands (the)' },
  { code: 'MQ', code3: 'MTQ', number: '474', name_de: "Martinique", name: 'Martinique' },
  { code: 'MR', code3: 'MRT', number: '478', name_de: "Mauretanien", name: 'Mauritania' },
  { code: 'MU', code3: 'MUS', number: '480', name_de: "Mauritius", name: 'Mauritius' },
  { code: 'YT', code3: 'MYT', number: '175', name_de: "Mayotte", name: 'Mayotte' },
  { code: 'MX', code3: 'MEX', number: '484', name_de: "Mexiko", name: 'Mexico' },
  { code: 'FM', code3: 'FSM', number: '583', name_de: "Mikronesien (Föderierte Staaten von)", name: 'Micronesia (Federated States of)' },
  { code: 'MD', code3: 'MDA', number: '498', name_de: "Moldawien (die Republik)", name: 'Moldova (the Republic of)' },
  { code: 'MC', code3: 'MCO', number: '492', name_de: "Monaco", name: 'Monaco' },
  { code: 'MN', code3: 'MNG', number: '496', name_de: "Mongolei", name: 'Mongolia' },
  { code: 'ME', code3: 'MNE', number: '499', name_de: "Montenegro", name: 'Montenegro' },
  { code: 'MS', code3: 'MSR', number: '500', name_de: "Montserrat", name: 'Montserrat' },
  { code: 'MA', code3: 'MAR', number: '504', name_de: "Marokko", name: 'Morocco' },
  { code: 'MZ', code3: 'MOZ', number: '508', name_de: "Mosambik", name: 'Mozambique' },
  { code: 'MM', code3: 'MMR', number: '104', name_de: "Myanmar", name: 'Myanmar' },
  { code: 'NA', code3: 'NAM', number: '516', name_de: "Namibia", name: 'Namibia' },
  { code: 'NR', code3: 'NRU', number: '520', name_de: "Nauru", name: 'Nauru' },
  { code: 'NP', code3: 'NPL', number: '524', name_de: "Nepal", name: 'Nepal' },
  { code: 'NL', code3: 'NLD', number: '528', name_de: "Niederlande (die)", name: 'Netherlands (the)' },
  { code: 'NC', code3: 'NCL', number: '540', name_de: "Neukaledonien", name: 'New Caledonia' },
  { code: 'NZ', code3: 'NZL', number: '554', name_de: "Neuseeland", name: 'New Zealand' },
  { code: 'NI', code3: 'NIC', number: '558', name_de: "Nicaragua", name: 'Nicaragua' },
  { code: 'NE', code3: 'NER', number: '562', name_de: "Niger (der)", name: 'Niger (the)' },
  { code: 'NG', code3: 'NGA', number: '566', name_de: "Nigeria", name: 'Nigeria' },
  { code: 'NU', code3: 'NIU', number: '570', name_de: "Niue", name: 'Niue' },
  { code: 'NF', code3: 'NFK', number: '574', name_de: "Norfolkinsel", name: 'Norfolk Island' },
  { code: 'MP', code3: 'MNP', number: '580', name_de: "Nördliche Marianen (die)", name: 'Northern Mariana Islands (the)' },
  { code: 'NO', code3: 'NOR', number: '578', name_de: "Norwegen", name: 'Norway' },
  { code: 'OM', code3: 'OMN', number: '512', name_de: "Oman", name: 'Oman' },
  { code: 'PK', code3: 'PAK', number: '586', name_de: "Pakistan", name: 'Pakistan' },
  { code: 'PW', code3: 'PLW', number: '585', name_de: "Palau", name: 'Palau' },
  { code: 'PS', code3: 'PSE', number: '275', name_de: "Palästina, Staat der", name: 'Palestine, State of' },
  { code: 'PA', code3: 'PAN', number: '591', name_de: "Panama", name: 'Panama' },
  { code: 'PG', code3: 'PNG', number: '598', name_de: "Papua-Neuguinea", name: 'Papua New Guinea' },
  { code: 'PY', code3: 'PRY', number: '600', name_de: "Paraguay", name: 'Paraguay' },
  { code: 'PE', code3: 'PER', number: '604', name_de: "Peru", name: 'Peru' },
  { code: 'PH', code3: 'PHL', number: '608', name_de: "Philippinen (die)", name: 'Philippines (the)' },
  { code: 'PN', code3: 'PCN', number: '612', name_de: "Pitcairn", name: 'Pitcairn' },
  { code: 'PL', code3: 'POL', number: '616', name_de: "Polen", name: 'Poland' },
  { code: 'PT', code3: 'PRT', number: '620', name_de: "Portugal", name: 'Portugal' },
  { code: 'PR', code3: 'PRI', number: '630', name_de: "Puerto Rico", name: 'Puerto Rico' },
  { code: 'QA', code3: 'QAT', number: '634', name_de: "Katar", name: 'Qatar' },
  { code: 'MK', code3: 'MKD', number: '807', name_de: "Republik Nordmazedonien", name: 'Republic of North Macedonia' },
  { code: 'RO', code3: 'ROU', number: '642', name_de: "Rumänien", name: 'Romania' },
  { code: 'RU', code3: 'RUS', number: '643', name_de: "Russische Föderation (die)", name: 'Russian Federation (the)' },
  { code: 'RW', code3: 'RWA', number: '646', name_de: "Ruanda", name: 'Rwanda' },
  { code: 'RE', code3: 'REU', number: '638', name_de: "Réunion", name: 'Réunion' },
  { code: 'BL', code3: 'BLM', number: '652', name_de: "St. Barthélemy", name: 'Saint Barthélemy' },
  { code: 'SH', code3: 'SHN', number: '654', name_de: "St. Helena, Ascension und Tristan da Cunha", name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KN', code3: 'KNA', number: '659', name_de: "St. Kitts und Nevis", name: 'Saint Kitts and Nevis' },
  { code: 'LC', code3: 'LCA', number: '662', name_de: "St. Lucia", name: 'Saint Lucia' },
  { code: 'MF', code3: 'MAF', number: '663', name_de: "St. Martin (französischer Teil)", name: 'Saint Martin (French part)' },
  { code: 'PM', code3: 'SPM', number: '666', name_de: "St. Pierre und Miquelon", name: 'Saint Pierre and Miquelon' },
  { code: 'VC', code3: 'VCT', number: '670', name_de: "St. Vincent und die Grenadinen", name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', code3: 'WSM', number: '882', name_de: "Samoa", name: 'Samoa' },
  { code: 'SM', code3: 'SMR', number: '674', name_de: "San Marino", name: 'San Marino' },
  { code: 'ST', code3: 'STP', number: '678', name_de: "Sao Tome und Principe", name: 'Sao Tome and Principe' },
  { code: 'SA', code3: 'SAU', number: '682', name_de: "Saudi-Arabien", name: 'Saudi Arabia' },
  { code: 'SN', code3: 'SEN', number: '686', name_de: "Senegal", name: 'Senegal' },
  { code: 'RS', code3: 'SRB', number: '688', name_de: "Serbien", name: 'Serbia' },
  { code: 'SC', code3: 'SYC', number: '690', name_de: "Seychellen", name: 'Seychelles' },
  { code: 'SL', code3: 'SLE', number: '694', name_de: "Sierra Leone", name: 'Sierra Leone' },
  { code: 'SG', code3: 'SGP', number: '702', name_de: "Singapur", name: 'Singapore' },
  { code: 'SX', code3: 'SXM', number: '534', name_de: "Sint Maarten (niederländischer Teil)", name: 'Sint Maarten (Dutch part)' },
  { code: 'SK', code3: 'SVK', number: '703', name_de: "Slowakei", name: 'Slovakia' },
  { code: 'SI', code3: 'SVN', number: '705', name_de: "Slowenien", name: 'Slovenia' },
  { code: 'SB', code3: 'SLB', number: '090', name_de: "Salomonen", name: 'Solomon Islands' },
  { code: 'SO', code3: 'SOM', number: '706', name_de: "Somalia", name: 'Somalia' },
  { code: 'ZA', code3: 'ZAF', number: '710', name_de: "Südafrika", name: 'South Africa' },
  { code: 'GS', code3: 'SGS', number: '239', name_de: "Südgeorgien und die Südlichen Sandwichinseln", name: 'South Georgia and the South Sandwich Islands' },
  { code: 'SS', code3: 'SSD', number: '728', name_de: "Südsudan", name: 'South Sudan' },
  { code: 'ES', code3: 'ESP', number: '724', name_de: "Spanien", name: 'Spain' },
  { code: 'LK', code3: 'LKA', number: '144', name_de: "Sri Lanka", name: 'Sri Lanka' },
  { code: 'SD', code3: 'SDN', number: '729', name_de: "Sudan (der)", name: 'Sudan (the)' },
  { code: 'SR', code3: 'SUR', number: '740', name_de: "Surinam", name: 'Suriname' },
  { code: 'SJ', code3: 'SJM', number: '744', name_de: "Svalbard und Jan Mayen", name: 'Svalbard and Jan Mayen' },
  { code: 'SE', code3: 'SWE', number: '752', name_de: "Schweden", name: 'Sweden' },
  { code: 'CH', code3: 'CHE', number: '756', name_de: "Schweiz", name: 'Switzerland' },
  { code: 'SY', code3: 'SYR', number: '760', name_de: "Syrische Arabische Republik", name: 'Syrian Arab Republic' },
  { code: 'TW', code3: 'TWN', number: '158', name_de: "Taiwan", name: 'Taiwan' },
  { code: 'TJ', code3: 'TJK', number: '762', name_de: "Tadschikistan", name: 'Tajikistan' },
  { code: 'TZ', code3: 'TZA', number: '834', name_de: "Tansania, Vereinigte Republik", name: 'Tanzania, United Republic of' },
  { code: 'TH', code3: 'THA', number: '764', name_de: "Thailand", name: 'Thailand' },
  { code: 'TL', code3: 'TLS', number: '626', name_de: "Timor-Leste", name: 'Timor-Leste' },
  { code: 'TG', code3: 'TGO', number: '768', name_de: "Togo", name: 'Togo' },
  { code: 'TK', code3: 'TKL', number: '772', name_de: "Tokelau", name: 'Tokelau' },
  { code: 'TO', code3: 'TON', number: '776', name_de: "Tonga", name: 'Tonga' },
  { code: 'TT', code3: 'TTO', number: '780', name_de: "Trinidad und Tobago", name: 'Trinidad and Tobago' },
  { code: 'TN', code3: 'TUN', number: '788', name_de: "Tunesien", name: 'Tunisia' },
  { code: 'TR', code3: 'TUR', number: '792', name_de: "Türkei", name: 'Turkey' },
  { code: 'TM', code3: 'TKM', number: '795', name_de: "Turkmenistan", name: 'Turkmenistan' },
  { code: 'TC', code3: 'TCA', number: '796', name_de: "Turks- und Caicosinseln (die)", name: 'Turks and Caicos Islands (the)' },
  { code: 'TV', code3: 'TUV', number: '798', name_de: "Tuvalu", name: 'Tuvalu' },
  { code: 'UG', code3: 'UGA', number: '800', name_de: "Uganda", name: 'Uganda' },
  { code: 'UA', code3: 'UKR', number: '804', name_de: "Ukraine", name: 'Ukraine' },
  { code: 'AE', code3: 'ARE', number: '784', name_de: "Vereinigte Arabische Emirate (die)", name: 'United Arab Emirates (the)' },
  { code: 'GB', code3: 'GBR', number: '826', name_de: "Vereinigtes Königreich von Großbritannien und Nordirland (das)", name: 'United Kingdom of Great Britain and Northern Ireland (the)' },
  { code: 'UM', code3: 'UMI', number: '581', name_de: "Vereinigte Staaten Minor Outlying Islands (die)", name: 'United States Minor Outlying Islands (the)' },
  { code: 'US', code3: 'USA', number: '840', name_de: "Vereinigte Staaten von Amerika (die)", name: 'United States of America (the)' },
  { code: 'UY', code3: 'URY', number: '858', name_de: "Uruguay", name: 'Uruguay' },
  { code: 'UZ', code3: 'UZB', number: '860', name_de: "Usbekistan", name: 'Uzbekistan' },
  { code: 'VU', code3: 'VUT', number: '548', name_de: "Vanuatu", name: 'Vanuatu' },
  { code: 'VE', code3: 'VEN', number: '862', name_de: "Venezuela (Bolivarische Republik)", name: 'Venezuela (Bolivarian Republic of)' },
  { code: 'VN', code3: 'VNM', number: '704', name_de: "Vietnam", name: 'Viet Nam' },
  { code: 'VG', code3: 'VGB', number: '092', name_de: "Jungferninseln (Britisch)", name: 'Virgin Islands (British)' },
  { code: 'VI', code3: 'VIR', number: '850', name_de: "Jungferninseln (U.S.)", name: 'Virgin Islands (U.S.)' },
  { code: 'WF', code3: 'WLF', number: '876', name_de: "Wallis und Futuna", name: 'Wallis and Futuna' },
  { code: 'EH', code3: 'ESH', number: '732', name_de: "Westsahara", name: 'Western Sahara' },
  { code: 'YE', code3: 'YEM', number: '887', name_de: "Jemen", name: 'Yemen' },
  { code: 'ZM', code3: 'ZMB', number: '894', name_de: "Sambia", name: 'Zambia' },
  { code: 'ZW', code3: 'ZWE', number: '716', name_de: "Simbabwe", name: 'Zimbabwe' },
  { code: 'AX', code3: 'ALA', number: '248', name_de: "Åland-Inseln", name: 'Åland Islands' },
];
