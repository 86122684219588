/*
https://github.com/ngx-formly/ngx-formly/issues/2021
*/

import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-count-chars-wrapper',
  template: `
    <div fxLayout="column">
      <ng-container #fieldComponent></ng-container>
      <mat-hint *ngIf="to.maxLength" fxLayoutAlign="end end" style="user-select: none;">
        {{ formControl.value?.length || 0 }}/{{ to.maxLength }}
      </mat-hint>
    </div>
  `,
})
export class FormlyCountCharsWrapperComponent extends FieldWrapper {}
