import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { SearchHelpDialogComponent } from '../search-help-dialog';

@Component({
  selector: 'formly-button',
  template: `
    <button
      mat-button
      color="accent"
      type="button"
      [innerText]="field.templateOptions?.label"
      (click)="showDialog(this.form.get('ccemail')?.value)"
    ></button>
  `,
})
export class FormlyButtonComponent extends FieldType implements OnInit {
  field: FormlyFieldConfig;

  templateOptions: any;

  resultString: string;

  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.templateOptions = this.field.templateOptions;
  }

  showDialog(currentValue: string) {
    const dialogRef = this.dialog.open(SearchHelpDialogComponent, {
      width: '600px',
      data: { currValue: currentValue },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.templateOptions?.change(result);
    });
  }
}
