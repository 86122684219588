import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalErrorHandler } from './global-error-handler';

const PROVIDERS = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  },
];

@NgModule({
  providers: [...PROVIDERS],
  imports: [CommonModule],
})
export class ErrorHandlerModule {}
