/* eslint-disable no-case-declarations */
import { Component, ComponentFactoryResolver } from '@angular/core';
import { Mandant, MandantConfigService } from '@strategies/mandant-config.service';
import { BaseDynamicContainerComponent } from '@strategies/_containers/base-dynamic.container';
import { UserActionsDefaultComponent } from '@strategies/_default/user-actions';
import { UserActionsTelekomComponent } from '@strategies/telekom/_components/user-actions';

@Component({
  selector: 'user-actions',
  template: '<ng-template #dynamic></ng-template>',
  styleUrls: [],
})
export class UserActionsContainerComponent extends BaseDynamicContainerComponent {
  constructor(
    public mandantConfigService: MandantConfigService,
    public cfr: ComponentFactoryResolver
  ) {
    super(mandantConfigService, cfr);
  }

  protected override getComponent() {
    switch (this.mandantConfigService.mandant.name) {
      case Mandant.telekom:
        const componentFactoryV1 = this.cfr.resolveComponentFactory(UserActionsTelekomComponent);
        return this.viewContainerRef.createComponent(componentFactoryV1);
      default:
        const factory = this.cfr.resolveComponentFactory(UserActionsDefaultComponent);
        return this.viewContainerRef.createComponent(factory);
    }
  }
}
