<div id="emailTemplate" class="border">
  <img src="assets/images/telekom-banner.jpg" alt="" width="100%" />

  <div class="emailHeader">
    <b class="leftAligned">{{ address }}</b>
    <b class="rightAligned">{{ date | date }}</b>
  </div>
  <br />
  <br />
  <p *ngIf="!emailContent" class="marginLeft-5 placeholder">{{ placeholder }}</p>
  <div *ngIf="emailContent" [innerHTML]="emailContent" class="marginLeft-5"></div>
  <br />
  <!-- START FOOTER -->
  <pre>
    {{ 'location.myLocation.location_notification.signature_part1' | translate}}
    {{ 'location.myLocation.location_notification.signature_part2' | translate}}
    <p><img src="assets/images/telekom-iss-logo.png" alt=""></p>
  </pre>
  <div class="footer">
    <div class="contactData">
      <a class="marginLeft-5">
        {{ 'location.myLocation.location_notification.footer_headline' | translate }}
      </a>
      <pre>
        {{ 'location.myLocation.location_notification.footer_content_line1' | translate }}
        {{ 'location.myLocation.location_notification.footer_content_line2' | translate }}
        {{ 'location.myLocation.location_notification.footer_content_line3' | translate }}
      </pre>
      <p class="marginLeft-5">
        {{ 'location.myLocation.location_notification.footer_link' | translate }}
        <a href="https://yam-united.telekom.com/pages/gsus-real-estate-management-customers"
          >GSUS – Real Estate Management Customers
        </a>
      </p>
    </div>
    <p class="marginLeft-5">
      <b>{{ 'location.myLocation.location_notification.label__hint' | translate }}</b>
      {{ 'location.myLocation.location_notification.hint_part1' | translate }}
      {{ address }}
      {{ 'location.myLocation.location_notification.hint_part2' | translate }}
    </p>
  </div>
  <!-- END FOOTER -->
</div>
