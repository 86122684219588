import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PlaceholderDirective } from '@shared/directives/placeholder.directive';
import { MandantConfigService } from '@strategies';
import { getFooterComponent } from '@theme/strategies/footer.strategy';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  @ViewChild(PlaceholderDirective, { static: true, read: ViewContainerRef })
  dynamicFooter: ViewContainerRef;

  constructor(
    private cfr: ComponentFactoryResolver,
    private mandantConfigService: MandantConfigService,
  ) {}

  ngOnInit() {
    getFooterComponent(this.cfr, this.dynamicFooter, this.mandantConfigService.mandant.name);
  }
}
