/*
 * https://github.com/tinesoft/ngx-cookieconsent
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MandantConfigService, MandantConfig } from '@strategies/mandant-config.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDataService } from '@routes/offering/shared/service/translate-data.service';
import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
  NgcContentOptions,
  NgcPaletteOptions,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookie-consent',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription;

  private popupCloseSubscription: Subscription;

  private initializeSubscription: Subscription;

  private statusChangeSubscription: Subscription;

  private revokeChoiceSubscription: Subscription;

  private noCookieLawSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private ccService: NgcCookieConsentService,
    private mandantConfigService: MandantConfigService,
    private translateDataService: TranslateDataService
  ) {}

  ngOnInit(): void {
    this.initCookieConsent();
  }

  ngOnDestroy() {
    this.destroyCookieConsent();
  }

  private initCookieConsent() {
    const mandantConfig: MandantConfig = this.mandantConfigService.mandant;

    const configContent: NgcContentOptions = this.ccService.getConfig().content || {};

    this.ccService.getConfig().cookie.domain = window.location.hostname;

    // color palette from mandant config
    const configPalette: NgcPaletteOptions = this.ccService.getConfig().palette || {};
    configPalette.button = configPalette.button || { background: '' };
    configPalette.button.background = mandantConfig.primary;
    configPalette.button.text = 'white';

    // link to privacy information
    // configContent.href = mandantConfig.cookieConsent?.href || '#';
    configContent.href = this.translateDataService.translate(
      mandantConfig.cookieConsent?.hrefDe,
      mandantConfig.cookieConsent?.hrefEn || mandantConfig.cookieConsent?.hrefDe
    );

    // Override default messages with the translated ones
    configContent.header = this.translateService.instant('cookie.header');
    configContent.message = this.translateService.instant('cookie.message');
    configContent.dismiss = this.translateService.instant('cookie.dismiss');
    configContent.allow = this.translateService.instant('cookie.allow');
    configContent.deny = this.translateService.instant('cookie.deny');
    configContent.link = this.translateService.instant('cookie.link');
    configContent.policy = this.translateService.instant('cookie.policy');

    this.ccService.getConfig().content = configContent;

    this.ccService.destroy(); // remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (_event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (_event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (_event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  private destroyCookieConsent() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
