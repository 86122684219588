import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  styles: [
    `
      .file-group {
        /* padding-bottom: 0px; */
      }
    `,
  ],
  template: `
    <mat-card
      *ngFor="let field of field.fieldGroup; let i = index"
      fxLayout="row"
      fxLayoutGap="10px"
      class="mat-elevation-z8 file-group"
    >
      <formly-field [field]="field" fxFlex></formly-field>
      <div fxFlex fxLayoutAlign="end start" fxFlex="nogrow">
        <button mat-raised-button color="warn" type="button" (click)="remove(i)">
          <!-- [style.margin-top.px]="16" -->
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </mat-card>
    <div style="margin:20px 0;" fxLayout="row" fxLayoutGap="20px">
      <button
        mat-raised-button
        color="accent"
        type="button"
        (click)="add()"
        #tooltip="matTooltip"
        [matTooltip]="field.templateOptions?.tooltip"
        [disabled]="!(!to.maxItems || to.maxItems > field.fieldGroup!.length)"
      >
        <mat-icon>add </mat-icon>{{ to.addText }}
      </button>

      <button
        *ngIf="field.templateOptions?.tooltip"
        mat-button
        color="accent"
        type="button"
        (click)="tooltip.toggle()"
      >
        <mat-icon>help_outline</mat-icon>
      </button>

      <p
        color="warn"
        *ngIf="!(!to.maxItems || to.maxItems > field.fieldGroup!.length)"
        [innerText]="'ticket.create.p__error_max_attachments' | translate: { max: to.maxItems }"
      ></p>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType {}
