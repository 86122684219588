import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FmDocument, UploadFile } from '@api';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@shared/services/file.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

interface DocumentTypeFile extends File {
  documentTypeId?: string | null;
}

@Component({
  selector: 'app-detail-file-upload-default',
  templateUrl: './detail-file-upload.component.html',
  styleUrls: ['./detail-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFileUploadLegacyComponent {
  tooltip = this.translateService.instant('ticket.create.p__info_attachment');

  @Input() metadata: [{ attributeName: string; attributeValue: string }];

  @Input() documentTypes: Array<{ value: string; label: string }>;

  @Input() typeIsRequired = false;

  @Input() readonly = false;

  @Output() uploadEvent = new EventEmitter<void>();

  busy = false;

  form = this.fb.group({
    typedFiles: this.fb.array([]),
  });

  get typedFiles(): FormArray {
    return this.form.controls.typedFiles as FormArray;
  }

  constructor(
    private fileService: FileService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}

  async uploadFiles() {
    this.form.markAllAsTouched();
    if (this.typedFiles.value === null || !this.typedFiles.valid) {
      return;
    }
    const [isValid, message, title] = this.fileService.validateTotalFileSize(
      this.form.value.typedFiles,
      (accumulator, current) => accumulator + current.file.size,
      20
    );
    if (!isValid) {
      this.toastr.error(message, title);
      return;
    }
    this.busy = true;
    const packedFiles: Array<UploadFile> = [];
    for await (const formGroup of this.typedFiles.value) {
      const { file, documentTypeId, visibilityId } = formGroup;
      const contentBase64Encoded = (await this.fileService.fileRead(file)).split(',')[1];

      const uploadFile = <UploadFile>{
        contentBase64Encoded,
        metadata: {
          ...(<FmDocument>{
            isInternal: false,
            documentId: '',
            documentTypeId,
            name: file.name,
            statusCode: 'Aktiv',
            filename: file.name,
            visibilityId,
          }),
          ...this.metadata,
        },
      };

      // populate metadata attributes
      this.metadata.forEach(m => {
        uploadFile.metadata[m.attributeName] = m.attributeValue;
      });

      packedFiles.push(uploadFile);
    }
    this.fileService
      .uploadFile(packedFiles)
      .pipe(first())
      .subscribe(
        _fileResult => {
          this.typedFiles.clear();
          this.toastr.success(this.translateService.instant('fileUpload.success.delayed'));
          this.busy = false;
          this.cd.detectChanges();
          this.uploadEvent.emit();
        },
        error => {
          this.busy = false;
          console.error(error);
          this.toastr.error(this.translateService.instant('fileUpload.error.upload'));
          this.cd.detectChanges();
        }
      );
  }

  castToFormGroup(formGroup) {
    return formGroup as FormGroup;
  }

  onFileSelected(event) {
    if (event) {
      const files: DocumentTypeFile[] = [...event.target.files];
      files.forEach(file => {
        this.addTypedFiles(file);
      });
    }
  }

  private addTypedFiles(file: File) {
    const [isValid, message, title] = this.fileService.validateFile(file);
    if (!isValid) {
      this.toastr.error(message, title);
      return;
    }
    const fileForm = this.fb.group({
      documentTypeId: [''],
      file: [file],
    });
    fileForm
      .get('documentTypeId')
      ?.setValidators(this.typeIsRequired ? [Validators.required] : null);
    this.typedFiles.push(fileForm);
  }

  detachFile(index: number) {
    this.typedFiles.removeAt(index);
  }
}
