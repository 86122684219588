import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner-overlay-default',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayDefaultComponent {
  @Input() show: boolean | null = false;
}
