<smart-card [backButton]="false" class="crud-create-wrapper" [ngClass]="{'loading': loading }">
  <ng-container navigation>
    <button *ngIf="!filledForm" fxFlex="nogrow" mat-raised-button color="primary" navigateBack>
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span class="customSendIconLeft"></span>
      <span [innerHtml]="'back' | translate | uppercase"></span>
    </button>
    <button
      *ngIf="filledForm"
      fxFlex="nogrow"
      mat-raised-button
      color="primary"
      (click)="discardSumUpForm()"
    >
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span class="customSendIconLeft"></span>
      <span [innerHtml]="'back' | translate | uppercase"></span>
    </button>
    <p id="scrollFlag"></p>
  </ng-container>

  <h2 *ngIf="!filledForm" [innerHtml]="config.title | translate"></h2>
  <form (ngSubmit)="validateForm()" [formGroup]="form">
    <div [hidden]="filledForm">
      <p [innerHtml]="config.description | translate"></p>

      <formly-form
        [form]="form"
        [fields]="formFields"
        [model]="formModel"
        [options]="options"
      ></formly-form>

      <div>
        <button
          fxFlex="50"
          fxFlexOffset="25"
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!form.valid"
          (click)="scrollTop()"
        >
          <span [innerText]="'forward'|translate "> </span>
          <span class="customSendIconRight"></span>
          <mat-icon class="materialSendIcon">arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="filledForm">
    <app-form-confirm
      [filledFields]="filledForm"
      [extraConfirmFormFields]="extraConfirmFormFields"
      [translateKeyTitle]="config.confirm.title"
      [translateKeyDescription]="config.confirm.description"
      [showTicketCreateData]="false"
      (confirmed)="submit()"
      (backToEdit)="discardSumUpForm()"
    ></app-form-confirm>
  </div>

  <pre *ngIf="isDevMode">
    {{form.value | json}}
  </pre>
</smart-card>

<spinner-overlay [show]="loading"></spinner-overlay>
