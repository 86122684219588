import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDataService } from '@routes/offering/shared/service/translate-data.service';

interface IImpressumItem {
  label: string;
  link?: string;
  value?: string;
  icon?: string;
  color?: string;
}

@Component({
  selector: 'app-footer-brst',
  templateUrl: './footer-brst.component.html',
  styleUrls: ['./footer-brst.component.scss'],
})
export class FooterBrstComponent implements OnInit {
  public information: Array<IImpressumItem>;

  public impressum: Array<IImpressumItem>;

  public ctaButtons: Array<IImpressumItem>;

  constructor(
    private translateService: TranslateService,
    private translateData: TranslateDataService
  ) {}

  ngOnInit() {
    this.information = [
      {
        label: this.translateService.instant('footer.aboutFoundation'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/unsere-haeuser/',
          'https://home-in-berlin.de/our-houses/?lang=en'
        ),
      },
      {
        label: this.translateService.instant('footer.contactPerson'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/unser-team/',
          'https://home-in-berlin.de/our-team/?lang=en'
        ),
      },
      {
        label: this.translateService.instant('footer.faq'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/faq/',
          'https://home-in-berlin.de/faq-2/?lang=en'
        ),
      },
      {
        label: this.translateService.instant('footer.contact'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/kontakt/',
          'https://home-in-berlin.de/kontakt/?lang=en'
        ),
      },
    ];

    this.impressum = [
      {
        label: this.translateService.instant('footer.imprint'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/impressum/',
          'https://home-in-berlin.de/impressum/?lang=en'
        ),
      },
      {
        label: this.translateService.instant('footer.dataPrivacy'),
        link: this.translateData.translate(
          'https://home-in-berlin.de/datenschutz/',
          'https://home-in-berlin.de/privacy-policy/?lang=en'
        ),
      },
    ];

    this.ctaButtons = [
      {
        label: this.translateService.instant('footer.homeOfBerlin'),
        link: this.translateData.translate(
          'https://www.house-of-nations.de',
          'https://www.house-of-nations.de?lang=en'
        ),
        color: '#00AA4B',
      },
      {
        label: this.translateService.instant('footer.realEstateService'),
        link: this.translateData.translate(
          'https://www.arwon-berlin.de',
          'https://www.arwon-berlin.de?lang=en'
        ),
        color: '#009FE3',
      },
      {
        label: this.translateService.instant('footer.socialService'),
        link: this.translateData.translate(
          'https://sozialedienste.brst.de/',
          'https://sozialedienste.brst.de?lang=en'
        ),
        color: '#FF285A',
      },
    ];
  }
}
