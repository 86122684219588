import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { MtxGridColumn } from '@ng-matero/extensions';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';
import { IbpdiHelperService } from '@shared/services/ibpdi-helper.service';
import {
  BaseSearchOptions,
  FmTask,
  IncidentClient,
  IncidentFilterOptions,
  IncidentSearchOptions,
  IncidentViewModel,
  IncidentViews,
  OmContact,
  TaskClient,
  UserClient,
  TaskSearchOptions,
} from '@api';
import { ActivatedRoute, Router } from '@angular/router';
import { TableBaseComponent } from '@strategies/_base/_table/table.base';
import { ITableConfig } from '@strategies/_base/_table/models/table.base.model';
import { addressFormatter } from '@shared/formatter/address.formatter';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserRoles } from '@shared/constants/user-roles.constants';

@Component({
  selector: 'app-ticket-list-telekom',
  templateUrl: '/ticket-list.component.html',
  styleUrls: ['../../../_base/_table/table.base.scss', 'ticket-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListTelekomComponent extends TableBaseComponent implements OnInit {
  config = this.configure(<ITableConfig>{
    title: 'taskmodule.tickets.list.title',
    translatePath: 'taskmodule.shared',
  });

  form = new FormGroup({});

  user: OmContact;

  columns: MtxGridColumn[] = [
    {
      header: this.translate('label__ticketNumber'),
      field: 'ticketNumber',
      sortable: true,
      showExpand: true,
      class: 'truncated',
    },
    {
      header: this.translate('label__incidentTitle'),
      field: 'name',
      sortable: true,
      class: 'truncated',
    },
    {
      header: this.translate('label__incidentClass'),
      field: 'incidentClass.incidentClass',
      sortable: true,
      class: 'truncated',
    },
    {
      header: this.translate('label__economicUnit'),
      field: 'archObjViewModel.name',
      sortable: true,
      class: 'truncated',
      formatter: data => data.archObjId?.substring(0, 5),
    },
    {
      header: this.translate('label__postalCode'),
      field: 'postalCode',
      sortable: true,
      class: 'truncated',
      formatter: data => addressFormatter(data.archObj, 'postalCode'),
    },
    {
      header: this.translate('label__incidentCity'),
      field: 'city',
      sortable: true,
      class: 'truncated',
      formatter: data => addressFormatter(data.archObj, 'city'),
    },
    {
      header: this.translate('label__incidentStreet'),
      field: 'streetName',
      sortable: true,
      class: 'truncated',
      formatter: data => addressFormatter(data.archObj, 'streetName'),
    },
    {
      header: this.translate('label__createdOn'),
      field: 'createdOn',
      sortable: true,
      class: 'truncated',
      formatter: (data: any) =>
        data.createdOn
          ? moment(data.createdOn)
              .locale(this.translateService.instant('locale.locale'))
              .format(this.translateService.instant('locale.moment__date_time_format'))
          : '--',
    },
    {
      header: this.translate('label__statusReason'),
      field: 'statusReasonNavigation',
      sortable: true,
      class: 'truncated',
      formatter: (field: any) =>
        this.ibpdiHelperService.getTranslatedEnumLable(field.statusReasonNavigation),
    },
    {
      header: this.translate('label__documents'),
      field: 'action',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'attachment',
          click: ticket => this.openDocuments(ticket),
        },
      ],
    },
  ];

  fieldsInExpansionTemplate = ['subject', 'state'];

  filterFields: Array<FormlyFieldConfig> = [
    {
      type: 'flex-layout',
      fieldGroup: [
        {
          key: 'ticketNumber',
          type: 'input',
          templateOptions: {
            label: this.translate('label__ticketNumber'),
          },
        },
        {
          key: 'name',
          type: 'input',
          templateOptions: {
            label: this.translate('label__title'),
          },
        },
        {
          key: 'ticketClass',
          type: 'input',
          templateOptions: {
            label: this.translate('label__incidentClass'),
          },
        },
        {
          key: 'economicUnit',
          type: 'input',
          templateOptions: {
            label: this.translate('label__economicUnit'),
          },
        },
        {
          key: 'postalcode',
          type: 'input',
          templateOptions: {
            label: this.translate('label__postalCode'),
          },
        },
        {
          key: 'city',
          type: 'input',
          templateOptions: {
            label: this.translate('label__incidentCity'),
          },
        },
        {
          key: 'streetName',
          type: 'input',
          templateOptions: {
            label: this.translate('label__incidentStreet'),
          },
        },
        {
          type: 'matdatetimepicker',
          key: 'createDateFrom',
          templateOptions: {
            label: this.translate('label__createDateFrom'),
            startAt: moment(new Date()).startOf('day'),
          },
        },
        {
          type: 'matdatetimepicker',
          key: 'createDateTo',
          templateOptions: {
            label: this.translate('label__createDateTo'),
            startAt: moment(new Date()).startOf('day'),
          },
        },
        {
          key: 'statusReason',
          type: 'input',
          templateOptions: {
            label: this.translate('label__statusReason'),
          },
        },
        {
          type: 'select',
          key: 'ticketTaskSelection',
          templateOptions: {
            label: 'Ticket-/Aufgabenstatus',
            options: [
              {
                label: 'Tickets mit aktiven Aufgaben',
                value: 'TicketsWithActiveTasks',
              },
              {
                label: 'Alle anzeigen',
                value: 'All',
              },
            ],
          },
        },
      ],
    },
  ];

  constructor(
    injector: Injector,
    private userClient: UserClient,
    private ibpdiHelperService: IbpdiHelperService,
    public incidentClient: IncidentClient,
    private taskClient: TaskClient
  ) {
    super(injector);
  }

  public ngOnInit() {
    if (!this.hasReadAllRole()) {
      this.userClient
        .getUserProfile()
        .pipe(first())
        .subscribe(profile => {
          this.user = profile;
        });
    }
  }

  getTableData$ = (query?: BaseSearchOptions, filterOptions?: IncidentFilterOptions) => {
    this.form.value.ticketTaskSelection =
      filterOptions?.ticketTaskSelection == null
        ? 'TicketsWithActiveTasks'
        : filterOptions?.ticketTaskSelection;
    let view: IncidentViews = IncidentViews.VisibleByTaskAssociation;

    if (this.form.value.ticketTaskSelection === 'All') {
      view = IncidentViews.VisibleByOneTaskAssociation;
    }
    const searchOptions: IncidentSearchOptions = {
      ...query,
      filterOptions,
      viewOptions: { selectedView: view },
    };
    return this.incidentClient.getIncidentsAuthenticated(searchOptions);
  };

  getTasks = data => {
    const searchOptions: TaskSearchOptions = {
      filterOptions: { ticketNumber: data.data.ticketNumber },
    };
    this.taskClient.getTasks(searchOptions);
    data.data.fmTask = data.data.fmTask.reverse();
  };

  clickRow = (data, router: Router, route: ActivatedRoute) => {
    router.navigate(['..', 'task'], {
      relativeTo: route,
      queryParams: {
        filterOptions: JSON.stringify({ incident: { ticketNumber: data.rowData.ticketNumber } }),
      },
    });
  };

  clickAdd = (router: Router, route: ActivatedRoute) => {
    router.navigate(['create'], { relativeTo: route });
  };

  clickExpandedRow(row: FmTask) {
    if (!row.isActive && row.organisationId === this.user.organisationId) {
      this.router.navigate(['/taskmodule/task/viewDetails', row.taskId]);
      return;
    }

    if (!row.isActive && row.organisationId !== this.user.organisationId) return;

    if (
      row.status === 'Abgeschlossen' ||
      (row.status === 'Storniert' && row.organisationId === this.user.organisationId)
    ) {
      this.router.navigate(['/taskmodule/task/viewDetails', row.taskId]);
    } else {
      this.router.navigate(['/taskmodule/task/details', row.taskId]);
    }
  }

  private openDocuments(ticket: IncidentViewModel) {
    this.router.navigate(['/documents'], { state: { incidentId: ticket.incidentId } });
  }

  hasReadAllRole() {
    return this.hasRole(UserRoles.SMARTPORTAL_INCIDENT_READALL);
  }
}
