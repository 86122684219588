<smart-card *ngIf="config.showSmartCard" offset="2" [backButton]="config.showBackButton">
  <ng-container [ngTemplateOutlet]="tableContent"></ng-container>
</smart-card>

<ng-container *ngIf="!config.showSmartCard">
  <ng-container [ngTemplateOutlet]="tableContent"></ng-container>
</ng-container>

<ng-template #tableContent>
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
      <h3 [innerText]="config.title | translate"></h3>
      <div fxFlex="auto" [style.padding-top.px]="8">
        <app-table-filter
          fxFlexAlign="end"
          *ngIf="viewOptionsFields"
          [filterFields]="viewOptionsFields"
          triggerViaChange="true"
          filterVisible="true"
          (filterEvent)="updateViewOptions($event)"
        >
        </app-table-filter>
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="end center"
        fxLayoutAlign.lt-sm="begin begin"
        fxLayoutGap="10px"
      >
        <button
          *ngIf="filterFields"
          fxFlexOrder="0"
          fxFlexOrder.lt-sm="5"
          mat-button
          color="primary"
          (click)="toggleFilter()"
        >
          <mat-icon>filter_alt</mat-icon><span [innerText]="'crud.filter' | translate"></span>
        </button>
        <button
          *ngIf="config.showRefreshButton"
          mat-raised-button
          color="primary"
          (click)="refresh()"
        >
          <mat-icon>sync</mat-icon><span [innerText]="'crud.refresh' | translate"></span>
        </button>
        <button
          *ngIf="clickAdd && config.addButton.label"
          [disabled]="config.addButton.disabled"
          mat-raised-button
          color="accent"
          (click)="clickAddWrapper()"
        >
          <mat-icon>add</mat-icon><span [innerText]="config.addButton.label | translate"></span>
        </button>
      </div>
    </div>

    <app-table-filter
      *ngIf="filterFields"
      [filterFields]="filterFields"
      [filterVisible]="config.grid.filter.filterVisible"
      (filterEvent)="updateFilter($event)"
      (searchEvent)="refreshFromFilter()"
      (resetFilterEvent)="resetFilter()"
    >
    </app-table-filter>
    <ng-container>
      <mtx-grid
        [columns]="columns"
        [data]="data.items"
        [length]="data.totalCount"
        [pageOnFront]="false"
        [sortOnFront]="config.grid.filter.sortOnFront"
        [loading]="isLoading"
        [rowStriped]="true"
        [noResultText]="'grid.no_results' | translate"
        (rowClick)="clickRowWrapper($event)"
        [rowHover]="true"
        [cellSelectable]="true"
        [pageSizeOptions]="[5, 10, 25, 50, 100]"
        (sortChange)="sortChange($event)"
        (page)="getNextPage($event)"
        [pageSize]="config.grid.paging.pageSize"
        [pageIndex]="config.grid.paging.pageIndex"
        [showPaginator]="config.grid.paging.showPaginator"
        [columnMenuButtonText]="'grid.columns_shown' | translate"
        [columnMenuHeaderText]="'grid.columnMenuHeaderText' | translate"
        [columnHideable]="true"
        [columnHideableChecked]="'show'"
        [columnMovable]="true"
        [showColumnMenuFooter]="true"
        [columnMenuFooterTemplate]="menuFooter"
        [showToolbar]="config.grid.showToolbar"
        [showColumnMenuHeader]="true"
        [toolbarTemplate]="toolbarTpl"
        [showSidebar]="true"
        [columnResizable]="config.columnResizable"
        [expandable]="fieldsInExpansionTemplate.length > 0"
        [expansionTemplate]="expansionTemplate"
        [rowSelectable]="config.grid.select.rowSelectable"
        [multiSelectable]="config.grid.select.multiSelectable"
        (rowSelectionChange)="rowSelectionChanged($event)"
        [hideRowSelectionCheckbox]="false"
      >
      </mtx-grid>
    </ng-container>
  </div>
</ng-template>

<ng-template #menuFooter> </ng-template>

<ng-template #toolbarTpl> </ng-template>

<ng-template #expansionTemplate let-row>
  <div>
    <p *ngFor="let field of fieldsInExpansionTemplate">{{row[field]}}</p>
  </div>
</ng-template>

<ng-template #documentActionsTemplate let-row let-index="index" let-col="colDef">
  <button
    *ngIf="archiveToImageMasterAllowed()"
    mat-button
    color="primary"
    class="table-button"
    (click)="archiveFileToImageMaster(row)"
    [title]="'documents.tooltip__moveToImageMaster' | translate"
    [loading]="row.archiveToImageMasterLoading"
    [disabled]="row.actionsDisabled || hideRowActions(row)"
  >
    <mat-icon>redo</mat-icon>
  </button>
  <button
    *ngIf="downloadDocumentAllowed()"
    mat-button
    color="primary"
    class="table-button"
    (click)="downloadDocument(row)"
    [title]="'documents.tooltip__download' | translate"
    [loading]="row.downloadLoading"
    [disabled]="row.actionsDisabled || hideRowActions(row)"
  >
    <mat-icon>download</mat-icon>
  </button>
</ng-template>

<ng-template #documentVisibilityTemplate let-row let-index="index" let-col="colDef">
  <mat-select [(value)]="row.documentVisibility">
    <mat-option
      *ngFor="let option of documentVisibilities"
      [value]="$any(option).label"
      [matTooltip]="$any(option).tooltip"
      (click)="saveVisibility(row)"
    >
      {{ $any(option).label }}
    </mat-option>
  </mat-select>
</ng-template>

<ng-template #bufferedActionsTemplate let-row let-index="index" let-col="colDef">
  <button
    *ngIf="row.status == 1"
    mat-button
    color="primary"
    class="table-button"
    (click)="removeBufferredDocument(row)"
    [title]="'documents.tooltip__remove' | translate"
  >
    <mat-icon>close</mat-icon>
  </button>
</ng-template>
