<div mat-dialog-title>
  <button mat-raised-button color="primary" mat-dialog-close (click)="closeDialog()">
    {{ 'shared.back' | translate }}
  </button>
  <h3>{{ 'ticket.create.label__search_aid' | translate }}</h3>
</div>

<div mat-dialog-content fxLayout="row" fxLayoutGap="16px">
  <form class="example-form" fxFlex="50">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{ 'ticket.create.label__name_email' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        (change)="emailExists = false; isEmailInOptions()"
        (ngModelChange)="isEmailInOptions()"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <p *ngIf="this.emailExists" id="warningEmailExists">
      {{ 'ticket.create.p__info_email_exists' | translate }}
    </p>
  </form>
  <mat-list fxFlex="50">
    <mat-list-item *ngFor="let selected of selectedOptions">
      <p>{{ selected }}</p>
      <button mat-raised-button class="rightAligned" (click)="deleteSelectedOption(selected)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>

<div mat-dialog-actions>
  <button
    mat-raised-button
    cdkFocusInitial
    (click)="addOption()"
    [disabled]="!this.emailInOptions || this.emailExists"
  >
    {{ 'shared.add' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    mat-dialog-close
    (click)="saveSelectedOptions(selectedOptions)"
  >
    {{ 'shared.save' | translate }}
  </button>
</div>
<div mat-dialog-actions></div>