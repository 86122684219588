import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ContactSearchFormlyFieldService } from '../contact-search-formly-field.service';

export function editor(
  translateService: TranslateService,
  contactFormlyFieldService: ContactSearchFormlyFieldService,
  onSelectContact: (id: string) => void,
  weNo?: string | null): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.label__editor'),
          },
        },
        {
          key: 'editor',
          fieldGroup: [
            ...contactFormlyFieldService.autocompleteSearch(
              { archObjId: weNo },
              onSelectContact,
              'ticket.create.headline__search_editor'
            ),
          ],
        },
      ],
    },
  ];
}
