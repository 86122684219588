import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';

import { LoggerService } from '../logger.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let success = false;

    // extend server response observable with logging
    return next.handle(req).pipe(
      tap(event => {
        success = event instanceof HttpResponse;
      }),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}" ${
          success ? 'succeeded' : 'failed'
        } in ${elapsed} ms.`;
        if (success) {
          this.loggerService.info(msg);
        } else {
          this.loggerService.error(msg);
        }
      })
    );
  }
}

export const loggingHttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
];
