import { Injectable } from '@angular/core';
import { IncidentClassClient, RequestLocationClient, UserClient } from '@api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AddButtonType } from '../models/add-button-type.model';
import { IAddButtonConfig } from '../models/table.base.model';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  config = <IAddButtonConfig>{
    label: 'crud.add',
  };

  rentalContractValue$: BehaviorSubject<any> = new BehaviorSubject(null);

  incidentClassValue$: BehaviorSubject<any> = new BehaviorSubject(null);

  requestLocationValue$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private userClient: UserClient,
    private incidentClassClient: IncidentClassClient,
    private requestLocationClient: RequestLocationClient
  ) {
    this.fetchIncidentClasses();
    this.fetchRequestLocation();
  }

  initAddButton$(type: AddButtonType): Observable<IAddButtonConfig> {
    // this.fetchIncidentClasses();
    switch (type) {
      case AddButtonType.ENABLED:
        return of(<IAddButtonConfig>{
          label: this.config.label,
          disabled: false,
        });
      case AddButtonType.RENTAL_CONTRACT:
        return this.isAddDisabledRentalContract().pipe(
          switchMap((disabled: boolean) => {
            return of(<IAddButtonConfig>{
              label: this.config.label,
              disabled,
            });
          })
        );
      default:
        throw Error('initAddButton not implemented');
    }
  }

  private isAddDisabledRentalContract(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.userClient.getRentalContractForUserIncident().subscribe(
        value => {
          if (value !== null) {
            this.rentalContractValue$.next(value);
          }
          observer.next(value == null);
        },
        () => {
          observer.next(true);
        }
      );
    });
  }

  fetchRequestLocation(): void {
    this.requestLocationClient.getRequestLocations().subscribe(
      value => {
        if (value !== null) {
          this.requestLocationValue$.next(value);
        }
      },
      error => {
        console.error('Error fetching incident classes:', error);
      }
    );
  }

  fetchIncidentClasses(): void {
    this.incidentClassClient.getIncidentClasses().subscribe(
      value => {
        if (value !== null) {
          this.incidentClassValue$.next(value);
        }
      },
      error => {
        console.error('Error fetching incident classes:', error);
      }
    );
  }
}
