export enum LoginBehaviour {
  // The user is routed to /authentication/login without param and "has to" choose a login variant
  // when clicking "login".
  Choice = 0,

  // The user is routed to /authentication/ciam, the ciam-login-flow is triggered wihtout choice
  // when clicking "login".
  Ciam = 1,

  // The user is routed to /authentication/b2c, the b2c-login-flow is triggered wihtout choice
  // when clicking "login".
  B2C = 2,
}
