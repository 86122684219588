import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentMetadata, StorageClient } from '@api';
import { ITableConfig } from '@strategies/_base/_table/models/table.base.model';
import { TableBaseComponent } from '@strategies/_base/_table/table.base';
import { Mandant, MandantConfigService } from '@strategies/mandant-config.service';
import * as moment from 'moment';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-detail-file-list-buffered',
  templateUrl: '../../_table/table.base.html',
  styleUrls: ['../../_table/table.base.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DetailFileListBufferedComponent
  extends TableBaseComponent
  implements OnInit, OnChanges
{
  @Input() documents: DocumentMetadata[];

  @Output() removeEvent = new EventEmitter<any>();

  config = this.configure(<ITableConfig>{
    title: 'documents.list.titleInProgress',
    translatePath: 'documents',
    showSmartCard: false,
    showBackButton: false,
    showRefreshButton: false,
  });

  @ViewChild('bufferedActionsTemplate', { static: true })
  bufferedActionsTemplateRef!: TemplateRef<void>;

  constructor(
    injector: Injector,
    private storageClient: StorageClient,
    private mandantService: MandantConfigService
  ) {
    super(injector);

    this.config.grid.showToolbar = false;
    this.config.grid.columnResizable = false;
    this.config.grid.paging.showPaginator = false;
    this.config.grid.filter.filterOnFront = true;
    this.config.grid.filter.sortOnFront = true;
  }

  ngOnInit() {
    switch (this.mandantService.mandant.name) {
      case Mandant.brst:
        this.columns = [
          { header: this.translate('label__title'), field: 'name', sortable: true },
          {
            header: this.translate('label__status'),
            field: 'status',
            width: '20%',
            sortable: true,
            formatter: (data: any) => this.translate(`status.${data.status}`),
          },
          {
            header: this.translate('label__created'),
            field: 'created',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.created ? moment(data.created).format('DD.MM.YYYY, HH:mm:ss') : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.bufferedActionsTemplateRef,
          },
        ];
        break;
      default:
        this.columns = [
          { header: this.translate('label__title'), field: 'name', sortable: true },
          {
            header: this.translate('label__target'),
            field: 'targetSystem',
            width: '10%',
            sortable: true,
            formatter: (data: any) => this.translate(`targetSystems.${data.targetSystem}`),
          },
          {
            header: this.translate('label__status'),
            field: 'status',
            width: '20%',
            sortable: true,
            formatter: (data: any) => this.translate(`status.${data.status}`),
          },
          {
            header: this.translate('label__created'),
            field: 'created',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.created ? moment(data.created).format('DD.MM.YYYY, HH:mm:ss') : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.bufferedActionsTemplateRef,
          },
        ];
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.documents = changes?.documents?.currentValue;
    this.getData();
  }

  removeBufferredDocument(document: DocumentMetadata) {
    if (document.rowKey) {
      this.storageClient
        .deleteDocumentMetadata(document.rowKey)
        .pipe(take(1))
        .subscribe(_response => {
          const index = this.documents.indexOf(document);
          this.documents.splice(index, 1);
          const message = this.translateService.instant('generic.successMessage');
          this.toastr.success(message);
          this.removeEvent.emit(this.documents);
          this.cd.detectChanges();
        }, this.showError);
    }
  }

  showError() {
    const message = this.translateService.instant('generic.errorMessage');
    this.toastr.error(message);
  }

  override getTableData$ = () => {
    return of({ entites: this.documents, totalCount: this.documents.length });
  };

  override clickRow = (_data: any, _router: Router, _route: ActivatedRoute) => {
    // do nothing
  };
}
