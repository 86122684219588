import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {}

  initEventHandler(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      const newRoute = this.router.url.substring(0, this.router.url.lastIndexOf('/'));
      if (newRoute.length > 0) {
        this.router.navigate([newRoute]);
      } else {
        window.location.href = '/';
      }
    }
  }
}
