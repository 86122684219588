<div style="display: flex; justify-content: space-between; align-items: center">
  <h2 [innerText]="title"></h2>
  <excel-export-telekom
    [formModel]="formModel"
    [isLoading]="isLoading"
    [formFields]="formFields"
  ></excel-export-telekom>
</div>
<form fxLayout="column">
  <formly-form [form]="form" [fields]="formFields" [model]="formModel"></formly-form>
  <app-detail-fields-base
    *ngIf="task"
    [data]="task"
    [detailFields]="detailFields"
    [readonly]="true"
  ></app-detail-fields-base>
</form>
<details *ngIf="isDevMode">
  <pre>{{ entity | json }}</pre>
</details>
<spinner-overlay [show]="isLoading"></spinner-overlay>
