import { FormlyFieldConfig } from '@ngx-formly/core';
import { RegexpEmail, RegexpStreetname, RegexpZipCodeDE } from '@core';
import { TranslateService } from '@ngx-translate/core';
import {
  federalList,
  newSalutation,
  phoneConstraints,
  phoneValidationMessages
} from './ticket-created-shared.field-configs';
import {checkIfEmailIdentical} from "@shared/services/email-validator";

export function concerningContact(translateService: TranslateService): Array<FormlyFieldConfig> {
  return [
    {
      // Besitzerkontakt
      hideExpression:
        '(model.concerningLocation.powerOfDisposition === "Andere" || !model.concerningLocation.powerOfDisposition ) || !model.auxAddress',
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.headline__contact_owner'),
            chapterText: translateService.instant('ticket.create.p__info_contact_owner'),
          },
          expressionProperties: {
            'templateOptions.visibleInConfirm':
              'model.concerningLocation.powerOfDisposition!=="Andere" && model.auxAddress',
          },
        },
        {
          key: 'concerningContact',
          fieldGroup: [
            ...newSalutation(translateService),
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'firstname',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__firstName_owner'),
                    visibleInConfirm: true,
                    maxLength: 50,
                    required: true,
                  },
                },
                {
                  type: 'input',
                  key: 'lastname',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__lastName_owner'),
                    maxLength: 50,
                    visibleInConfirm: true,
                    required: true,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'phonenumber',
                  defaultValue: '0049',
                  wrappers: ['tooltip', 'form-field'],
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__phone_owner'),
                    visibleInConfirm: true,
                    required: true,
                    ...phoneConstraints,
                    tooltip: translateService.instant('ticket.create.tooltip__phone'),
                  },
                  validation: phoneValidationMessages(translateService),
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              validators: {
                fieldMatch: {
                  expression: checkIfEmailIdentical,
                  message: translateService.instant('formErrors.email.not_identical'),
                  errorPath: 'confirmEmail',
                },
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'email',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__email_owner'),
                    pattern: RegexpEmail,
                    visibleInConfirm: true,
                    required: true,
                    maxLength: 100,
                  },
                  validation: {
                    messages: {
                      pattern: (_err, _field: FormlyFieldConfig) =>
                        translateService.instant('formErrors.email.pattern'),
                    },
                  },
                },
                {
                  type: 'input',
                  key: 'confirmEmail',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__confirm_email_owner'),
                    required: true,
                  },
                },
              ],
            },
            {
              key: 'address',
              fieldGroup: [
                {
                  type: 'flex-layout',
                  templateOptions: {
                    fxLayout: 'row',
                  },
                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'street',
                      templateOptions: {
                        label: translateService.instant('ticket.create.label__street_owner'),
                        visibleInConfirm: true,
                        required: true,
                        maxLength: 250,
                        minLength: 3,
                        pattern: RegexpStreetname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant('formErrors.street.pattern'),
                          minlength: (err, _field) =>
                            translateService.instant('formErrors.street.minLength', {
                              length: err.requiredLength,
                            }),
                        },
                      },
                      expressionProperties: {
                        'templateOptions.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'input',
                      key: 'zipcode',
                      templateOptions: {
                        label: translateService.instant('ticket.create.label__zipcode_owner'),
                        visibleInConfirm: true,
                        required: true,
                        pattern: RegexpZipCodeDE,
                        maxLength: 5,
                        minLength: 5,
                      },
                      expressionProperties: {
                        'templateOptions.visibleInConfirm': 'true',
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant('formErrors.zipcode.pattern'),
                        },
                      },
                    },
                  ],
                },
                {
                  type: 'flex-layout',
                  templateOptions: {
                    fxLayout: 'row',
                  },
                  fieldGroup: [
                    {
                      type: 'input',
                      key: 'city',
                      templateOptions: {
                        label: translateService.instant('ticket.create.label__city_owner'),
                        visibleInConfirm: true,
                        required: true,
                        minLength: 3,
                        maxLength: 80,
                        pattern: RegexpStreetname,
                      },
                      validation: {
                        messages: {
                          pattern: translateService.instant('formErrors.city.pattern'),
                          minlength: (err, _field) =>
                            translateService.instant('formErrors.city.minLength', {
                              length: err.requiredLength,
                            }),
                        },
                      },
                      expressionProperties: {
                        'templateOptions.visibleInConfirm': 'true',
                      },
                    },
                    {
                      type: 'select',
                      key: 'state',
                      templateOptions: {
                        label: translateService.instant('ticket.create.label__state_owner'),
                        visibleInConfirm: true,
                        required: true,
                        options: federalList(translateService.currentLang),
                      },
                      expressionProperties: {
                        'templateOptions.visibleInConfirm': 'true',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
