import { Injectable, OnDestroy } from '@angular/core';
import { MandantConfig } from '@strategies';
import { BehaviorSubject } from 'rxjs';
import * as tinycolor from 'tinycolor2';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicThemeService implements OnDestroy {
  primaryColor = '#bb0000';

  accentColor = '#FF0000';

  primaryColorPalette: Color[] = [];

  accentColorPalette: Color[] = [];

  public brandingLogo$ = new BehaviorSubject('');

  public brandingFavicon$ = new BehaviorSubject('');

  public brandingTitle$ = new BehaviorSubject('Smart Portal');

  public backgroundUrl$ = new BehaviorSubject('./assets/images/polygon.svg');

  public backgroundColor$ = new BehaviorSubject('#AAA');

  ngOnDestroy() {
    this.brandingLogo$.unsubscribe();
    this.brandingFavicon$.unsubscribe();
    this.brandingTitle$.unsubscribe();
    this.backgroundUrl$.unsubscribe();
  }

  public async loadTheme(mandant: MandantConfig) {
    this.setTheme(mandant.primary, mandant.accent);
    this.brandingLogo$.next(mandant.logo);
    this.brandingFavicon$.next(mandant.faviconUrl || mandant.logo);
    this.brandingTitle$.next(mandant.brandingTitle || 'Smart Portal if no brandingtitle');
    this.backgroundUrl$.next(mandant.backgroundUrl || './assets/images/polygon.svg');
    this.backgroundColor$.next(mandant.backgroundColor || mandant.accent);
    if (mandant.customCssUrl) {
      this.setCustomCss(mandant.customCssUrl);
    }
  }

  public setTheme(primaryColor: string, accentColor?: string) {
    this.setPrimaryColor(primaryColor);
    this.setAccentColor(accentColor || primaryColor);
  }

  public setPrimaryColor(colorHexString: string) {
    this.primaryColorPalette = this.computeColors(colorHexString);
    this.updateTheme(this.primaryColorPalette, 'primary');
  }

  public setAccentColor(colorHexString: string) {
    this.accentColorPalette = this.computeColors(colorHexString);
    this.updateTheme(this.accentColorPalette, 'accent');
  }

  private updateTheme(colors: Color[], theme: string) {
    colors.forEach(color => {
      document.documentElement.style.setProperty(`--theme-${theme}-${color.name}`, color.hex);
      document.documentElement.style.setProperty(
        `--theme-${theme}-contrast-${color.name}`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    });
  }

  private computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  private getColorObject(value, name: string): Color {
    const c = tinycolor(value);
    return {
      name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  private setCustomCss(cssUrl) {
    let link = document.getElementById('customCss');
    if (!link) {
      link = document.createElement('link');
      link.id = 'customCss';
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', cssUrl);
      document.head.appendChild(link);
    } else {
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', cssUrl);
    }
  }
}
