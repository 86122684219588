import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@core';
import { TranslateService } from '@ngx-translate/core';

const LANGUAGES = {
  de: { short: 'de', long: 'de-DE', label: 'Deutsch' },
  en: { short: 'en', long: 'en-US', label: 'English' },
};

@Component({
  selector: 'app-translate',
  template: `
    <button
      mat-button
      class="matero-toolbar-button"
      [matMenuTriggerFor]="menu"
      style="padding-left: 12px"
    >
      <mat-icon style="padding-right: 36px">language</mat-icon
      ><span [innerText]="language | slice: 0:2 | uppercase"></span>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let lang of languages" (click)="useLanguage(lang.long)">
        <span>{{ lang.label }}</span>
      </button>
    </mat-menu>
  `,
  styles: [],
})
export class TranslateComponent implements OnInit {
  language: string;

  languages = Object.keys(LANGUAGES).map(l => LANGUAGES[l]);

  constructor(
    private translate: TranslateService,
    private settings: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  useLanguage(language: string) {
    this.language = language;
    window.location.reload();
    this.settings.setLanguage(language);
  }

  ngOnInit() {
    this.translate.addLangs(Object.keys(LANGUAGES).map(l => LANGUAGES[l].long));

    this.language = this.settings.language;
    if (!this.language) {
      // get browser lang and set app lang. fallback lang is en-EN
      this.language =
        navigator.language === LANGUAGES.de.long ? LANGUAGES.de.long : LANGUAGES.en.long;
    }
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    this.settings.setLanguage(this.language);

    // any language parameter in url? reload page if not identical to app lang
    this.activatedRoute.queryParams.subscribe(params => {
      if (!params.lang) {
        return;
      }
      if (!LANGUAGES[params.lang]) {
        console.error(`Unknown language key ${params.lang}`);
        return;
      }
      const lang = LANGUAGES[params.lang].long;

      // reload page without lang params
      if (lang !== this.language) {
        this.language = lang;
        this.settings.setLanguage(lang);
        this.location.replaceState(this.router.url.split('?')[0]);
        window.location.reload();
      }
    });
  }
}
