import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent {
  // holds data path of the image url
  captchaImage: string;

  // holds the generated captcha string
  captcha: string;

  // form which includes the validation input and honeypot fields
  form = new FormGroup({});
  formModel: any = {};
  options: FormlyFormOptions = {};
  formFields: Array<FormlyFieldConfig> = [
    // input field for comparing with the captcha string
    {
      type: 'input',
      key: 'confirmCaptcha',
      templateOptions: {
        label: this.translateService.instant('captcha.label__input'),
        required: true,
        maxLength: 5,
      },
    },
    // honeypot field (invisible for the user), if not empty validation has to fail
    {
      type: 'input',
      key: 'honeypot',
      templateOptions: {
        label: 'Das ist ein Honeypot Feld:',
        maxLength: 20,
      },
      hide: true,
    },
  ];

  constructor(
    protected translateService: TranslateService,
    public dialogRef: MatDialogRef<CaptchaComponent>,
    private toastService: ToastrService
  ) {
    // Loads initial captcha
    this.generateCaptcha();
  }

  public onOk() {
    // validates captcha and honeypot field, else clears form and loads new captcha
    if (this.formModel.confirmCaptcha === this.captcha && this.formModel.honeypot === undefined) {
      this.dialogRef.close(true);
    } else {
      const message = this.translateService.instant('captcha.error');
      this.toastService.error(message);
      this.form.reset();
      this.generateCaptcha();
    }
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  // Generates a CAPTCHA
  generateCaptcha() {
    const chrs = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    let captcha = '';
    for (let i = 0; i < 5; i++) {
      captcha += chrs[Math.floor(Math.random() * chrs.length)];
    }
    this.captcha = captcha;
    this.generateCaptchaImage(captcha);
  }

  // Generates an image of the generated CAPTCHA
  generateCaptchaImage(captcha: string) {
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';
    canvas.width = 60;
    canvas.height = 36;
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d')!;
    context.fillStyle = '#00aa4b';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = '15px Helvetica';
    context.fillStyle = '#fff';
    context.fillText(captcha, 6, 22);
    const data = canvas.toDataURL();
    document.body.removeChild(canvas);
    this.captchaImage = data;
  }

  // used for loading a new captcha
  loadNewCaptcha() {
    this.form.reset();
    this.generateCaptcha();
  }
}
