import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { MenuGuard } from '@core';
import { DefaultPageComponent } from '@core/default-page/default-page.component';
import { AppLayoutComponent } from '../theme/app-layout/app-layout.component';
import { RoutePath } from './routes-path.config';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: DefaultPageComponent, pathMatch: 'full' },
      {
        path: RoutePath.DASHBOARD,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [MenuGuard],
      },
      {
        path: RoutePath.OFFERING,
        loadChildren: () => import('./offering/offering.module').then(m => m.OfferingsModule),
        canLoad: [MenuGuard],
      },
      {
        path: RoutePath.TICKET,
        loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
        canLoad: [MenuGuard],
      },
      {
        path: RoutePath.LOCATION,
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
        canLoad: [MenuGuard],
      },
      {
        path: RoutePath.PROPERTY_MANAGEMENT,
        loadChildren: () =>
          import('./propertymanagement/propertymanagement.module').then(
            m => m.PropertyManagementModule
          ),
        canLoad: [MenuGuard],
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.SELF_SERVICE,
        loadChildren: () =>
          import('./selfservice/selfservice.module').then(m => m.SelfServiceModule),
        canLoad: [MenuGuard],
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.PROFILE,
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        // canLoad: [MenuGuard],
        // canActivate: [MsalGuard],
      },
      {
        path: RoutePath.FEEDBACK,
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
      },
      {
        path: RoutePath.AUTHENTICATION,
        loadChildren: () =>
          import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
      {
        path: RoutePath.TASKS,
        loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule),
        canActivate: [MsalGuard],
      },
      {
        path: RoutePath.INFO,
        loadChildren: () => import('./info/info.module').then(m => m.InfoModule),
      },
      {
        path: RoutePath.ADMIN,
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: RoutePath.PERMISSION_REQUEST,
        loadChildren: () =>
          import('./permission-request/permission-request.module').then(
            m => m.PermissionRequestModule
          ),
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
        canLoad: [MenuGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: 'legacy',
      enableTracing: true,
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
