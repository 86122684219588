import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { PreloaderService } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@shared';
import { MandantConfigService } from '@strategies';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

interface SwVersion {
  version: string;
  changelog: string;
}

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-cookie-consent *ngIf="useCookieConsent"></app-cookie-consent>
  `,
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  public useCookieConsent: boolean;

  private readonly destroying$ = new Subject<void>();

  constructor(
    private preloader: PreloaderService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private swUpdate: SwUpdate,
    private mandantConfig: MandantConfigService,
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private navigationService: NavigationService
  ) {
    if (!window.location.href.includes('authentication/callback')) {
      sessionStorage.setItem('prevRoute', window.location.pathname);
    }
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }

  public ngOnInit() {
    // MONITOR EVENTS:
    this.catchB2CErrors(true);
    if (false) {
      this.logB2CInteractionStatus();
      this.logB2CAuthenticationEvents();
    }

    this.showIosA2HsPopup();
    this.checkForUpdate();
    this.useCookieConsent = !!this.mandantConfig.mandant.cookieConsent;

    this.navigationService.initEventHandler();
  }

  ngOnDestroy(): void {
    console.log('destroying app');
    this.destroying$.next(undefined);
    this.destroying$.complete();
    console.log('destroyed app');
  }

  private showIosA2HsPopup() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

    if (isIos() && !isInStandaloneMode()) {
      this.toastr.show(this.translateService.instant('pwa.ios_install'));
    }
  }

  private checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((evt: UpdateAvailableEvent) => {
        const oldAppData: SwVersion = <SwVersion>evt.current.appData;
        const newAppData: SwVersion = <SwVersion>evt.available.appData;
        let msg = '';
        this.translateService
          .get('pwa.update_available', {
            oldVersion: oldAppData.version,
            newVersion: newAppData.version,
            changelog: newAppData.changelog,
          })
          .subscribe((text: string) => {
            msg = text;
          });
        const updateApp: any = window.confirm(msg);
        if (updateApp) {
          window.location.reload();
        }
      });
    }
  }

  private logB2CInteractionStatus(): void {
    this.msalBroadcastService.inProgress$
      .pipe(takeUntil(this.destroying$))
      .subscribe((result: InteractionStatus) => {
        console.log('INTERACTION STATUS: ', result);
      });
  }

  private logB2CAuthenticationEvents(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(takeUntil(this.destroying$))
      .subscribe((result: EventMessage) => {
        console.log('EVENTMESSAGE: ', result);
      });
  }

  private catchB2CErrors(silent: boolean = true): void {
    this.msalAuthService
      .handleRedirectObservable() // don't use the .instance.handleRedirectPromise, it creates the bug described a few lines later.
      .toPromise()
      .then((_result: AuthenticationResult | null) => {
        if (!silent) {
          console.log('REDIRECT PROMISE:', _result);
        }
      })
      .catch((_reason: Error) => {
        if (
          _reason.message.includes(
            'AADB2C90091: The user has cancelled entering self-asserted information.'
          )
        ) {
          if (!silent) {
            console.log('User cancelled reset-password');
          }
          // [TODO: KB] this is a workaround for a bug that prevents the user from accessing any
          // guarded route (e.g. /selfservice) after canceling the "reset password flow".
          this.msalAuthService.logout();
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!silent) {
            console.log('REDIRECT PROMISE CATCH:', _reason.message);
          }
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.destroying$)
      )
      .subscribe((_result: EventMessage) => {
        if (!silent) {
          console.log('!! LOGIN FAILED !!', _result);
        }
        // Add your auth error handling logic here
      });
  }
}
