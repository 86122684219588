import { Directive, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MtxSelectComponent } from '@ng-matero/extensions';

// @typescript-eslint/no-use-before-define
// necessary since ng-matero ng-select does not properly export the mat-select as a NG_VALUE_ACCESSOR
@Directive({
  selector: 'mtx-select[ngrxFormControlState]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => NgrxMatSelectViewAdapterDirective),
      multi: true,
    },
  ],
})
export class NgrxMatSelectViewAdapterDirective implements ControlValueAccessor {
  constructor(private mtxSelect: MtxSelectComponent) {}

  writeValue(obj: any): void {
    this.mtxSelect?.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.mtxSelect.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.mtxSelect.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.mtxSelect.setDisabledState(isDisabled);
  }
}
