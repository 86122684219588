<h3 mat-dialog-title>
  {{ title }}
</h3>
<form [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ 'location.myLocation.location_news.category.title' | translate }}</mat-label>
    <mat-select formControlName="category" required>
      <mat-option value="information">{{
        'location.myLocation.location_news.category.options.information' | translate
      }}</mat-option>
      <mat-option value="warning">{{
        'location.myLocation.location_news.category.options.warning' | translate
      }}</mat-option>
      <mat-option value="blocking">{{
        'location.myLocation.location_news.category.options.blocking' | translate
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'location.myLocation.location_news.validFrom' | translate }}</mat-label>
    <input
      matInput
      [max]="maxDate"
      [matDatepicker]="validFromPicker"
      formControlName="validFrom"
      (dateChange)="onValidFromDateChange($event)"
      required
    />
    <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
    <mat-datepicker #validFromPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'location.myLocation.location_news.validTo' | translate }}</mat-label>
    <input
      matInput
      [min]="minDate"
      [matDatepicker]="validToPicker"
      formControlName="validTo"
      (dateChange)="onValidToDateChange($event)"
      required
    />
    <mat-datepicker-toggle matSuffix [for]="validToPicker"></mat-datepicker-toggle>
    <mat-datepicker #validToPicker></mat-datepicker>
  </mat-form-field>

  <mat-checkbox formControlName="publish">{{
    'location.myLocation.location_news.publish' | translate
  }}</mat-checkbox>

  <button type="submit" mat-raised-button color="primary" (click)="onSubmit(locationNews)">
    {{ 'shared.save' | translate }}
  </button>
</form>
