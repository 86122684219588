import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-tooltip-wrapper',
  template: `
    <div [matTooltip]="to.tooltip" [matTooltipPosition]="to.tooltipPosition || 'above'">
        <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class FormlyTooltipWrapperComponent extends FieldWrapper {}
