import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { LoggerService, LogLevel } from '@core/logger';
import { DynamicThemeService } from '@core/bootstrap/dynamic-theme.service';
import { environment } from '@env/environment';
import { MandantConfig, MandantConfigService, Mandant } from '@strategies/mandant-config.service';
import { BlobStorageService } from './blob-storage.service';
import { MenuService } from './menu.service';
import { PreloaderService } from './preloader.service';
import { SettingsService } from './settings.service';
import { TranslateLangService } from './translate-lang.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private currentModul = '';

  private currentMandantName = '';

  private blobMenuJson: any;

  public defaultRoute: string;

  public defaultRouteAuthenticated?: string;

  constructor(
    private loggerService: LoggerService,
    private menu: MenuService,
    private router: Router,
    private dynamicTheme: DynamicThemeService,
    private mandantConfigService: MandantConfigService,
    private translateLangService: TranslateLangService,
    private blobStorageService: BlobStorageService,
    private settingsService: SettingsService,
    private titleService: Title,
    private preloader: PreloaderService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const modul = event.urlAfterRedirects.split('/')[1]?.split('?')[0];

        if (modul !== this.currentModul) {
          this.currentModul = modul;
          this.load().catch(err => console.error(err));
        }
      }
    });
  }

  get menuJson() {
    return this.blobMenuJson;
  }

  public async load(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // NOTE: Set log level to Logger service
        this.loggerService.Level = environment.logLevel || LogLevel.All;

        const mandantConfig: MandantConfig = await this.mandantConfigService.loadMandantConfig();

        if (this.currentMandantName !== mandantConfig.name) {
          this.defaultRoute = mandantConfig.defaultRoute || '';
          this.defaultRouteAuthenticated = mandantConfig.defaultRouteAuthenticated || '';
          this.currentMandantName = mandantConfig.name;
          await this.translateLangService.loadTranslation(
            mandantConfig.translationUrl,
            this.settingsService.language
          );
          this.dynamicTheme.loadTheme(mandantConfig);

          this.titleService.setTitle(mandantConfig.browserTitle ?? 'SmartPortal');

          // Loads required PWA icons into index.html based on Mandant
          switch (this.mandantConfigService.mandant.name) {
            case Mandant.telekom:
              document.querySelector('#appleTouchIconId')?.setAttribute('href', 'assets/icons/telekom-PWA-icons/apple-touch-icon.png');
              document.querySelector('#webmanifest')?.setAttribute('href', 'manifest.telekom.webmanifest');
              break;
            default:
              document.querySelector('#appleTouchIconId')?.setAttribute('href', 'assets/icons/apple-touch-icon.png');
              document.querySelector('#webmanifest')?.setAttribute('href', 'manifest.webmanifest');
              break;
          }


            
          
        }

        // NOTE: Request Identity
        // This has to be adjusted to your needs
        // await coreUtility.requestStateAsync(this.store, coreStore.selectIdentityState, () =>
        //   identityStore.fetchIdentityAction()
        // );

        // NOTE: Request Identity Roles
        // This has to be adjusted to your needs
        // this.store.dispatch(identityStore.fetchRolesAction());

        // NOTE: Load sidemenu configuration from `menu.json` within the `assets/data` folder

        const menuJson: any = await this.blobStorageService.getJsonFromUrl(
          `${mandantConfig.menuUrl}?t=${new Date().getTime()}`
        );
        this.blobMenuJson = menuJson;
        this.menu.setRawMenu(menuJson);

        // NOTE: if there is no currentModule, the first object in the menuJson will be selected
        const currentMenu =
          this.currentModul !== ''
            ? menuJson[this.currentModul]
            : menuJson[Object.keys(menuJson)[0]];
        const translatedMenu = MenuService.recursiveMenuForTranslation(currentMenu, 'menu');
        this.menu.set(translatedMenu);
        resolve();
      } catch (error) {
        console.error(error);
        reject(error);
        this.preloader.showError(error.message);
        throw new Error('Failed to load startup data.');
      }
    });
  }
}
