import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MandantConfigService } from '@strategies/mandant-config.service';

@Component({
  selector: 'app-base-container',
  template: '<ng-template #dynamic></ng-template>',
  styleUrls: [],
})
export abstract class BaseDynamicContainerComponent implements OnInit, OnChanges {
  @Input() show: boolean | null = false;

  @ViewChild('dynamic', { static: true, read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  component: ComponentRef<any>;

  protected constructor(
    public mandantConfigService: MandantConfigService,
    public cfr: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.component = this.getComponent();
    this.component.instance.show = this.show;
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes.show;
    if (this.component?.instance) {
      this.component.instance.show = change?.currentValue;
    }
  }

  protected abstract getComponent();
}
