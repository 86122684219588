import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '@shared/components';

export interface IHelp {
  title: string;
  content: string;
}

@Injectable()
export class HelpService {
  public currentHelpId: string | undefined;

  constructor(private helpDialog: MatDialog) {}

  public set(helpId: string): void {
    this.currentHelpId = `help.${helpId}`;
  }

  public reset(): void {
    this.currentHelpId = undefined;
  }

  public show() {
    this.helpDialog.open(HelpDialogComponent, { disableClose: false, minWidth: '500px' });
  }
}
