import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'label + mat-radio-group[required]',
})
/**
 * Fügt einem Label zu einer MatRadioGroup ein Sternchen ("*") hinzu, wenn die
 * Radio Group als required markiert ist. Dazu muss im DOM das mat-radio-group
 * direkt dem label folgen (direkte Geschwister im DOM).
 */
export class MatRadioGroupRequiredDirective implements AfterViewInit {
  private radioGroup: ElementRef;

  private renderer: Renderer2;

  constructor(renderer: Renderer2, radioGroup: ElementRef) {
    this.radioGroup = radioGroup;
    this.renderer = renderer;
  }

  ngAfterViewInit() {
    this.setClassToLabel();
  }

  private setClassToLabel(): void {
    const firstSibling = this.renderer.parentNode(this.radioGroup.nativeElement).firstChild;
    let currentSibling = firstSibling;
    let nextSibling = this.renderer.nextSibling(firstSibling);
    while (nextSibling) {
      if (
        currentSibling.tagName.toLowerCase() === 'label' &&
        nextSibling === this.radioGroup.nativeElement
      ) {
        this.renderer.addClass(currentSibling, 'mat-asterisk');
        break;
      }
      currentSibling = nextSibling;
      nextSibling = this.renderer.nextSibling(nextSibling);
    }
  }
}
