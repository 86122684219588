import { Component } from '@angular/core';

@Component({
  selector: 'app-address-finder',
  templateUrl: './address-finder.component.html',
  styleUrls: ['./address-finder.component.scss'],
})
export class AddressFinderComponent {
  private apiKey = '36f72e22495046989a5cc25dd9267d28';

  public searchTerm = '';

  public searchResult: any;

  public location: any;

  public getAmenity() {
    if (!this.searchTerm) {
      return;
    }

    const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${this.searchTerm}&type=amenity&format=json&apiKey=${this.apiKey}`;

    const requestOptions = {
      method: 'GET',
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        this.searchResult = result.results;
      })
      .catch(error => console.log('error', error));
  }

  public onAddressSelected(event: any) {
    this.searchTerm = event.option?.value?.formatted;
    this.location = event.option?.value;
    console.log('query', this.searchTerm, event.option?.value);
  }
}
