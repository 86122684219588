/* eslint-disable no-param-reassign */
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FmDocument, StorageClient } from '@api';
import { FileService } from '@shared';
import { ITableConfig } from '@strategies/_base/_table/models/table.base.model';
import { TableBaseComponent } from '@strategies/_base/_table/table.base';
import { Mandant, MandantConfigService } from '@strategies/mandant-config.service';
import * as moment from 'moment';
import { of } from 'rxjs';

interface DocumentRow extends FmDocument {
  downloadLoading: boolean;
  archiveToImageMasterLoading: boolean;
  actionsDisabled: boolean;
}

interface DocumentVisibilityRow extends FmDocument {
  documentVisibility?: string;
}

@Component({
  selector: 'app-detail-file-list',
  templateUrl: '../../_table/table.base.html',
  styleUrls: ['../../_table/table.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailFileListComponent extends TableBaseComponent implements OnInit, OnChanges {
  @Input() documents: FmDocument[];

  @Input() canSelectVisibility = false;

  config = this.configure(<ITableConfig>{
    title: 'documents.list.title',
    translatePath: 'documents',
    showSmartCard: false,
    showBackButton: false,
    showRefreshButton: false,
  });

  @ViewChild('documentActionsTemplate', { static: true })
  documentActionsTemplateRef!: TemplateRef<void>;

  @ViewChild('documentActionsBRSTTemplate', { static: true })
  documentActionsTemplateBRSTRef!: TemplateRef<void>;

  @ViewChild('documentVisibilityTemplate', { static: true })
  documentVisibilityTemplateRef!: TemplateRef<void>;

  documentVisibilities = [
    {
      label: this.translate('visibility.internal.label'),
      tooltip: this.translate('visibility.internal.tooltip'),
    },
    {
      label: this.translate('visibility.public.label'),
      tooltip: this.translate('visibility.public.tooltip'),
    },
  ];

  documentsList: DocumentVisibilityRow[];

  constructor(
    injector: Injector,
    private fileService: FileService,
    private storageClient: StorageClient,
    private mandantConfigService: MandantConfigService
  ) {
    super(injector);

    this.config.grid.showToolbar = false;
    this.config.grid.columnResizable = false;
    this.config.grid.paging.showPaginator = false;
    this.config.grid.filter.filterOnFront = true;
    this.config.grid.filter.sortOnFront = true;
    // TODO get setting for multiselect download from mandant config
    if (this.mandantConfigService.mandant.name === Mandant.telekom) {
      this.config.grid.select.rowSelectable = false;
      this.config.grid.select.multiSelectable = false;
    } else {
      this.config.grid.select.rowSelectable = false;
      this.config.grid.select.multiSelectable = false;
    }
  }

  ngOnInit() {
    switch (this.mandantConfigService.mandant.name) {
      case Mandant.brst:
        this.columns = [
          {
            header: this.translate('label__title'),
            field: 'filename',
            sortable: true,
          },
          {
            header: this.translate('label__ticketNumber'),
            field: 'incident.ticketNumber',
            width: '20%',
            sortable: true,
          },
          {
            header: this.translate('label__documentVisibility'),
            field: 'visibility',
            width: '240px',
            cellTemplate: this.documentVisibilityTemplateRef,
            hide: !this.canSelectVisibility,
          },
          {
            header: this.translate('label__created'),
            field: 'changeDate',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.changeDate ? moment(data.changeDate).format('DD.MM.YYYY, HH:mm:ss') : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.documentActionsTemplateRef,
          },
        ];
        break;
      default:
        this.columns = [
          {
            header: this.translate('label__title'),
            field: 'filename',
            sortable: true,
          },
          {
            header: this.translate('label__created'),
            field: 'changeDate',
            width: '20%',
            sortable: true,
            formatter: (data: any) =>
              data.changeDate ? moment(data.changeDate).format('DD.MM.YYYY, HH:mm:ss') : '--',
          },
          {
            header: this.translate('label__action'),
            width: '120px',
            field: 'action',
            cellTemplate: this.documentActionsTemplateRef,
          },
        ];
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.documents = changes?.documents?.currentValue;
    this.documentsList = this.documents.map(document => {
      return {
        ...document,
        documentVisibility: document.isInternal
          ? this.translate('visibility.internal.label')
          : this.translate('visibility.public.label'),
      };
    });

    this.getData();
  }

  override getTableData$ = () => {
    return of({ entites: this.documentsList, totalCount: this.documents.length });
  };

  override clickRow = (_data: any, _router: Router, _route: ActivatedRoute) => {
    // do nothing
  };

  async downloadDocument(row: DocumentRow) {
    if (this.selectedRows.length > 1) {
      const ids: string[] = [];
      this.selectedRows.forEach((selecedRow: DocumentRow) => {
        if (selecedRow.blobFilename) {
          ids.push(selecedRow.blobFilename);
        }
      });

      this.pushSubscription(
        this.fileService.downloadZip(ids).subscribe(
          res => {
            const fileResponse = res;
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();

            if (fileResponse !== null) {
              this.fileService.saveFile(fileResponse, fileResponse.fileName ?? 'download.zip');
            }
          },
          _err => {
            this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();
          }
        )
      );
    } else {
      if (!row.blobFilename) {
        this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
        return;
      }

      row.actionsDisabled = true;
      row.downloadLoading = true;
      this.cd.detectChanges();

      this.pushSubscription(
        this.fileService.downloadFile(row.blobFilename, row.contentType).subscribe(
          res => {
            const fileResponse = res;
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();

            if (fileResponse !== null) {
              this.fileService.saveFile(fileResponse, row.name);
            }
          },
          _err => {
            this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
            row.actionsDisabled = false;
            row.downloadLoading = false;
            this.cd.detectChanges();
          }
        )
      );
    }
  }

  async archiveFileToImageMaster(row: DocumentRow) {
    if (this.selectedRows.length > 1) {
      const ids: string[] = [];
      this.selectedRows.forEach((selecedRow: DocumentRow) => {
        if (selecedRow.blobFilename) {
          ids.push(selecedRow.blobFilename);
        }
      });

      this.pushSubscription(
        this.storageClient.archiveFilesToImageMaster(ids).subscribe(
          _res => {
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          },
          _err => {
            this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          }
        )
      );
    } else {
      if (!row.blobFilename) {
        this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
        return;
      }

      row.actionsDisabled = true;
      row.archiveToImageMasterLoading = true;
      this.cd.detectChanges();

      this.pushSubscription(
        this.storageClient.archiveFileToImageMaster(row.blobFilename).subscribe(
          _res => {
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          },
          _err => {
            this.toastr.error(this.translateService.instant('detailFields.fileDownload.warn'));
            row.actionsDisabled = false;
            row.archiveToImageMasterLoading = false;
            this.cd.detectChanges();
          }
        )
      );
    }
  }

  saveVisibility(file: DocumentVisibilityRow) {
    if (file.documentVisibility === this.translate('visibility.internal.label')) {
      file.isInternal = true;
    } else if (file.documentVisibility === this.translate('visibility.public.label')) {
      file.isInternal = false;
    }
    this.fileService.setFileVisibility(file);
  }
}
