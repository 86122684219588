import { LogLevel } from '@core/logger';
import { EnvironmentBase } from '@env/environment.base';
import { LoginBehaviour } from '@env/LoginBehaviour';

export const environment: EnvironmentBase = {
  MultiMandantConfigs: [
    {
      host: 'brst-dev.smart-us.de',
      pathToAssets: 'https://brst-dev.smart-us.de/assets/brst',
    },
    {
      host: 'bluebird-dev.smart-us.de',
      pathToAssets: 'https://bluebird-dev.smart-us.de/assets/bluebird',
    },
    {
      host: 'nemo-dev.smart-us.de',
      pathToAssets: 'https://nemo-dev.smart-us.de/assets/telekom',
    },
    {
      host: 'molewo-dev.smart-us.de',
      pathToAssets: 'https://molewo-dev.smart-us.de/assets/molewo',
    },
  ],
  baseUrl: 'https://affsusportalbackdev01.azurewebsites.net',
  production: true,
  logLevel: LogLevel.All,
  hmr: false,
  AadClientId: '5b1037ee-0f37-438a-9696-235baf3c4bb4',
  AadScope:
    'https://affinissmartus.onmicrosoft.com/aa4d719b-4bcc-495b-bafd-405ea1f1bac9/SmartPortal',
  AadAuthorityDomain: 'affinissmartus.b2clogin.com',
  AadSignupSigninUrl:
    'https://affinissmartus.b2clogin.com/affinissmartus.onmicrosoft.com/B2C_1A_SIGNIN_ONLY_ROLES_PWRESET',
  AadEditprofileUrl:
    'https://affinissmartus.b2clogin.com/affinissmartus.onmicrosoft.com/B2C_1A_PASSWORDRESET',
  LoginButtonBehaviour: LoginBehaviour.B2C,
  buildNumber: '',
};
