import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

export interface MandantConfig {
  name: string;
  id: string;
  brandingTitle: string;
  browserTitle?: string;
  primary: string;
  accent: string;
  backgroundColor?: string;
  backgroundUrl: string;
  logo: string;
  favicon: string;
  translationUrl: string;
  menuUrl: string;
  faviconUrl: string;
  ticketCreateDsgvoUrl: string;
  ticketCreateDsgvoUrlEn: string;
  customCssUrl: string;
  impressumUrl: string;
  dataPrivacyUrl?: string;
  defaultRoute: string;
  defaultRouteAuthenticated?: string;
  showLogin: boolean;
  hideExposeFilterForOccupation?: boolean;
  profile?: {
    showPasswordReset?: boolean;
  };
  cookieConsent?: {
    hrefDe: string;
    hrefEn?: string;
  };
  hideFooter?: boolean;
  topMenuLengthMax?: number;
  showPermanentSideMenu?: boolean;
  mobileSideMenuPosition?: string;
}

export enum Mandant {
  telekom = 'telekom',
  brst = 'brst',
  affinis = 'affinis',
  bluebird = 'bluebird',
  tesla = 'tesla',
  molewo = 'molewo',
  pasm = 'pasm',
}

@Injectable({
  providedIn: 'root',
})
export class MandantConfigService {
  private mandantConfig$: BehaviorSubject<any> = new BehaviorSubject({});

  get mandantConfig(): Observable<any> {
    return this.mandantConfig$.asObservable();
  }

  get mandant(): MandantConfig {
    return this.mandantConfig$.value;
  }

  constructor(private http: HttpClient) {}

  public loadMandantConfig() {
    return new Promise<MandantConfig>((resolve, reject) => {
      const { host } = window.location;

      let pathToAssets: string | undefined = `${host}/assets`;
      // override pathToAssets if environment contains MultimandantConfig
      if (environment.MultiMandantConfigs) {
        pathToAssets = environment.MultiMandantConfigs.find(c => c.host === host)?.pathToAssets;
      }
      if (!pathToAssets) {
        throw new Error(`Cant find config in MultiMandantConfig for host: ${host}`);
      }

      this.http
        .get<MandantConfig>(`${pathToAssets}/config.json`)
        .toPromise()
        .then((config: MandantConfig) => {
          const mandantConfig = this.replaceRootUrl(config, pathToAssets || '');
          this.mandantConfig$.next(mandantConfig);
          resolve(mandantConfig);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private replaceRootUrl(config: MandantConfig, absolutPath: string): MandantConfig {
    Object.keys(config).forEach(key => {
      config[key] =
        typeof config[key] === 'string'
          ? config[key].replace('%storageRoot%', absolutPath)
          : config[key];
    });
    return config;
  }
}
