import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocumentMetadata,
  DocumentTypeViewModel,
  DownloadFile,
  DownloadFileExternalRequest,
  DownloadFileResult,
  FileClient,
  FileResponse,
  FmDocument,
  StorageClient,
  UploadFile,
  UploadFileResult,
  UploadFilesTempStorageRequest,
} from '@api';
import { TranslateService } from '@ngx-translate/core';
import { MandantConfigService } from '@strategies';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  documentTypes: Array<{ value: string; label: string }>;

  constructor(
    private fileClient: FileClient,
    private storageClient: StorageClient,
    private httpCient: HttpClient,
    private translateService: TranslateService,
    private mandant: MandantConfigService,
    private toastr: ToastrService
  ) {}

  public downloadFile(id: string, contentType: string | undefined) {
    const fileReq = { id } as DownloadFile;
    return this.fileClient.downloadFile(contentType, fileReq);
  }

  public downloadFileExternal(request: DownloadFileExternalRequest) {
    return this.fileClient.downloadFileExternal(request);
  }

  public downloadZip(ids: string[]) {
    return this.fileClient.downloadZip(ids);
  }

  public getFileLink(id: string) {
    const fileReq = { id } as DownloadFile;

    return this.fileClient.getFileLink(fileReq);
  }

  public saveFile(fileResponse: FileResponse, fileName: string) {
    saveAs(fileResponse.data, fileName);
  }

  public saveFileLink(fileResult: DownloadFileResult, fileName: string | undefined) {
    if (fileResult.link !== undefined) {
      this.httpCient.get(fileResult.link, { responseType: 'blob' }).subscribe(response => {
        saveAs(response, fileName);
      });
    }
  }

  public uploadFile(files: UploadFile[]): Observable<UploadFileResult[]> {
    return this.fileClient.uploadFile(files);
  }

  public uploadFilesTempStorage(
    uploadRequest: UploadFilesTempStorageRequest
  ): Observable<DocumentMetadata[]> {
    return this.storageClient.uploadFiles(uploadRequest);
  }

  public fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  public validateFile(file?: File, maxMB: number = 10): [Boolean, string, string] {
    let isValid = true;
    let message = '';
    const title = this.translateService.instant('fileUpload.error.title_error');
    if (file) {
      if (file.name.length > 150) {
        message = this.translateService.instant(
          'fileUpload.error.message__filename_length_too_large'
        );
        isValid = false;
      }

      if (file.size <= 0) {
        message = this.translateService.instant('fileUpload.error.message__file_size_too_small');
        isValid = false;
      }

      if (file.size > 1024 * 1024 * maxMB) {
        message = this.translateService.instant('fileUpload.error.message__file_size_larger10', {
          maxMB,
        });
        isValid = false;
      }
    }
    return [isValid, message, title];
  }

  public validateTotalFileSize(
    files: File[],
    fileSizeAccessor: (accumulator, current: any) => number,
    maxMB: number
  ) {
    let isValid = true;
    let message = '';
    const title = this.translateService.instant('fileUpload.error.title_error');
    if (this.mandant.mandant.name === 'telekom') {
      const totalSize = files.reduce(fileSizeAccessor, 0);
      if (totalSize > 1024 * 1024 * maxMB) {
        message = this.translateService.instant('fileUpload.error.message__file_size_larger20', {
          maxMB,
        });
        isValid = false;
      }
      if (totalSize <= 0) {
        message = this.translateService.instant('fileUpload.error.message__file_size_too_small');
        isValid = false;
      }
    }
    return [isValid, message, title];
  }

  getDocumentTypes(): Observable<any> {
    if (this.documentTypes?.length) {
      return of(this.documentTypes);
    }

    return this.storageClient.getDocumentTypes().pipe(
      map((documentTypes: any) => {
        this.documentTypes = documentTypes.map((item: DocumentTypeViewModel) => {
          return {
            label: item.name,
            value: item.documentTypeId,
            maxFileSizeInBytes: item.maxFileSizeInBytes,
            contentTypes: item.fmDocumentTypeContentType,
          };
        });
        return this.documentTypes;
      })
    );
  }

  public setFileVisibility(file: FmDocument) {
    this.fileClient.setFileVisibility(file).subscribe(
      () => {
        this.toastr.success(
          'Die Dokumentensichtbarkeit (intern/extern) wurde erfolgreich geändert.'
        );
      },
      error => {
        this.toastr.error(
          'Bei der Änderung der Dokumentensichtbarkeit (intern/extern) trat ein Fehler auf.'
        );
        console.log(error);
      }
    );
  }
}
