import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function extraConfirm(translateService: TranslateService): Array<FormlyFieldConfig> {
  return[
    {
      key: 'subscribeNewsletter',
      type: 'checkbox',
      defaultValue: false,
      templateOptions: {
        label: translateService.instant('ticket.create.confirm.label__newsletter_checkbox'),
        appearance: 'standard',
      },
    },
    {
      key: 'confirmContent',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      templateOptions: {
        label: translateService.instant('ticket.create.confirm.label__confirm_checkbox'),
        appearance: 'standard',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
    {
      key: 'acceptDsgvo',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      templateOptions: {
        label: translateService.instant('ticket.create.confirm.label__dsgvo'),
        appearance: 'standard',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
  ];
}
