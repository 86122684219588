<div class="footer-container">
  <div
    fxLayout="column"
    fxLayoutGap="32px"
    fxFlex.md="90"
    fxFlexOffset.md="5"
    fxFlex.lt-md="90"
    fxFlexOffset.lt-md="5"
    fxFlexOffset.gt-md="20"
    fxFlex.gt-md="60"
  >
    <div fxLayout="row" fxLayout.lt-md="column" class="debug" fxLayoutGap="64px">
      <div fxLayout="column" class="debug" fxFlex>
        <h2 [innerText]="'footer.contact' | translate"></h2>
        <div class="contact-group">
          <p><strong>MOLEWO Service GmbH</strong></p>
          <p>Dohlenweg 1a</p>
          <p>28844 Weyhe</p>
        </div>
        <div class="contact-group">
          <p><strong>Dr. Norbert Warnken</strong></p>
          <p [innerText]="'footer.firstContact' | translate"></p>
        </div>
        <div class="contact-group">
          <p><strong>Tanja Warnken</strong></p>
          <p [innerText]="'footer.secondContact' | translate"></p>
        </div>
        <p [innerText]="'footer.email' | translate"></p>
      </div>
      <div fxLayout="column" class="debug" fxFlex>
        <h2 [innerText]="'footer.links' | translate"></h2>
        <ng-container *ngFor="let item of information">
          <a [href]="item.link" target="_blank"><p [innerText]="item.label"></p></a>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxFlex class="debug" fxLayoutGap="16px">
      <p [innerText]="'footer.allRightsReserved' | translate"></p>
      <div *ngFor="let item of impressum" fxLayoutAlign="end none">
        <a [href]="item.link" target="_blank"><p [innerText]="item.label"></p></a>
      </div>
    </div>
  </div>
</div>
