<div class="row">
    <div class="col-12">
        <p [innerHTML]="(helpService.currentHelpId ?? '') + '.title' | translate | html"></p>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <p [innerHTML]="(helpService.currentHelpId ?? '') + '.content' | translate | html"></p>
    </div>
</div>
<hr>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="" cdkFocusInitial>{{ 'shared.close' | translate }}</button>
</div>