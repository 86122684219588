import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'formly-chapter',
  template: `
    <hr *ngIf="field.templateOptions?.chapterHr" />
    <h3
      style="display:inline"
      [innerText]="field.templateOptions?.chapterTitle || ''"
      #tooltip="matTooltip"
      [matTooltip]="field.templateOptions?.tooltip"
    ></h3>
    <button
      *ngIf="field.templateOptions?.tooltip"
      mat-button
      color="accent"
      type="button"
      (click)="tooltip.toggle()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <p [innerText]="field.templateOptions?.chapterText || ''"></p>
  `,
})
export class FormlyChapterComponent extends FieldType {}
