import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { StartupService } from '@core/bootstrap/startup.service';

@Component({
  selector: 'app-default-page',
  template: '',
  styleUrls: [],
})
export class DefaultPageComponent implements OnInit {
  constructor(
    private startupService: StartupService,
    private router: Router,
    private msalAuthService: MsalService
  ) {}

  ngOnInit() {
    const isUserAuthenticated: boolean =
      this.msalAuthService.instance.getActiveAccount() != null || false;

    if (isUserAuthenticated && this.startupService.defaultRouteAuthenticated !== '') {
      this.router.navigate([this.startupService.defaultRouteAuthenticated || 'dashboard']);
    } else {
      this.router.navigate([this.startupService.defaultRoute || 'dashboard']);
    }
  }
}
