/* eslint-disable no-case-declarations */
import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FmTask } from '@api';
import { BaseDynamicContainerComponent } from '@strategies/_containers/base-dynamic.container';
import { DetailFileUploadLegacyComponent } from '@strategies/_default/_details/detail-file-upload-legacy/detail-file-upload.component';
import { DetailFileUploadDefaultComponent } from '@strategies/_default/_details/detail-file-upload-service/detail-file-upload.component';
import { Mandant, MandantConfigService } from '@strategies/mandant-config.service';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'app-detail-file-upload',
  template: '<ng-template #dynamic></ng-template>',
  styleUrls: [],
})
export class DetailFileUploadContainerComponent
  extends BaseDynamicContainerComponent
  implements OnInit, OnChanges
{
  @Input() hasTaskSelection = false;

  @Input() hasTargetSystemSelection = false;

  @Input() hasDocumentTypeSelection = false;

  @Input() canSelectVisibility = false;

  @Input() metadata: [{ attributeName: string; attributeValue: string }];

  @Input() documentTypes: Array<{ value: string; label: string }>;

  @Input() typeIsRequired = false;

  @Input() tasks: Array<FmTask>;

  @Input() useFileUploadDefault: boolean;

  @Output() uploadEvent = new EventEmitter<any>();

  private canSelectVisibilitySubject = new BehaviorSubject<boolean>(false);

  canSelectVisibility$ = this.canSelectVisibilitySubject.asObservable();

  constructor(
    public mandantConfigService: MandantConfigService,
    public cfr: ComponentFactoryResolver
  ) {
    super(mandantConfigService, cfr);
  }

  ngOnInit() {
    this.canSelectVisibility$ = of(this.canSelectVisibility);
    this.component = this.getComponent();
    this.component.instance.metadata = this.metadata;

    if (this.mandantConfigService.mandant.name === Mandant.telekom) {
      this.component.instance.hasTaskSelection = this.hasTaskSelection;
      this.component.instance.hasTargetSystemSelection = this.hasTargetSystemSelection;
      this.component.instance.hasDocumentTypeSelection = this.hasDocumentTypeSelection;

      this.component.instance.tasks = this.tasks;
      this.component.instance.documentTypes = this.documentTypes;
      this.component.instance.typeIsRequired = this.typeIsRequired;
    }
    this.canSelectVisibility$.subscribe(res => {
      this.component.instance.hasDocumentVisibilitySelection = res;
    });

    this.component.instance.uploadEvent.subscribe((data: any) => {
      this.uploadEvent.emit(data);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.component?.instance) {
      this.updateMemberVariable(changes, 'metadata');

      if (this.mandantConfigService.mandant.name === Mandant.telekom) {
        this.updateMemberVariable(changes, 'hasTaskSelection');
        this.updateMemberVariable(changes, 'hasTargetSystemSelection');
        this.updateMemberVariable(changes, 'hasDocumentTypeSelection');

        this.updateMemberVariable(changes, 'tasks');
        this.updateMemberVariable(changes, 'documentTypes');
        this.updateMemberVariable(changes, 'typeIsRequired');
      }
    }
  }

  private updateMemberVariable(changes: SimpleChanges, key: string) {
    if (changes[key]) {
      this.component.instance[key] = changes[key].currentValue;
    }
  }

  protected override getComponent() {
    switch (this.mandantConfigService.mandant.name) {
      case Mandant.brst:
      case Mandant.telekom:
        if (this.useFileUploadDefault) {
          const factory = this.cfr.resolveComponentFactory(DetailFileUploadLegacyComponent);
          return this.viewContainerRef.createComponent(factory);
        }

        const factoryV1 = this.cfr.resolveComponentFactory(DetailFileUploadDefaultComponent);
        return this.viewContainerRef.createComponent(factoryV1);
      default:
        const factory = this.cfr.resolveComponentFactory(DetailFileUploadLegacyComponent);
        return this.viewContainerRef.createComponent(factory);
    }
  }
}
