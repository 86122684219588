<button
  mat-raised-button
  color="primary"
  mat-dialog-close
  (click)="closeDialog()"
  style="margin-bottom: 10px"
>
  {{ 'shared.back' | translate }}
</button>
<form (ngSubmit)="validateForm()" [formGroup]="form">
  <formly-form [form]="form" [fields]="formFields" [model]="formModel"></formly-form>
  <div>
    <div>
      <app-telekom-email-template [address]="data.street"></app-telekom-email-template>
    </div>
    <br />
    <div>
      <angular-editor
        [(ngModel)]="this.htmlContent"
        [config]="angularEditorConfig"
        [ngModelOptions]="{ standalone: true }"
        (blur)="onBlur($event.target.innerHTML)"
      ></angular-editor>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        fxFlexAlign="end"
        style="margin-top: 10px"
      >
        <span [innerText]="'Senden' | translate"></span>
      </button>
    </div>
  </div>
</form>
