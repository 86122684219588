<form [formGroup]="form" (ngSubmit)="onOk()">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  <div mat-dialog-actions align="center">
    <button mat-raised-button (click)="onDismiss()">
      <span>{{ 'form.cancel' | translate }}</span>
    </button>
    <button mat-raised-button color="primary" type="submit">
      <span>{{ 'form.save' | translate }}</span>
    </button>
    <spinner-overlay [show]="isLoading"></spinner-overlay>
  </div>
</form>
