import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UploadFile } from '@api';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@shared/components/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ticket-create-base',
  templateUrl: './ticket-create.base.html',
  styleUrls: ['./ticket-create.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCreateBaseComponent {
  @Input() navigation = true;

  @Input() id: any;

  loading = false;

  form = new FormGroup({});

  formModel: any = {};

  formFields: FormlyFieldConfig[];

  filledForm: FormlyFieldConfig[] | null;

  extraConfirmFormFields?: FormlyFieldConfig[];

  showExtraConfirmFormFields: boolean;

  title: string;

  description: string;

  showTicketCreateData: boolean;

  patchValue: Observable<any>;

  options: FormlyFormOptions = {
    formState: {
      mainModel: this.formModel,
    },
  };

  protected prepareSubmit: (data: any) => any;

  protected clientEndpoint: (data: any) => Observable<any>;

  protected router: Router;

  private cd: ChangeDetectorRef;

  private el: ElementRef;

  private dialog: MatDialog;

  protected translateService: TranslateService;

  constructor(@Inject(Injector) injector: Injector) {
    this.router = injector.get(Router);
    this.cd = injector.get(ChangeDetectorRef);
    this.el = injector.get(ElementRef);
    this.dialog = injector.get(MatDialog);

    this.translateService = injector.get(TranslateService);
  }

  validateForm() {
    if (this.form.valid) {
      this.showFormSummary();
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  private async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-error',
      '.mat-input-element.ng-invalid, .mat-select.ng-invalid, mat-checkbox.ng-invalid, mat-form-field.ng-invalid'
    );

    try {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
      firstInvalidControl.focus();
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }

  async showFormSummary() {
    this.filledForm = this.formFields;
  }

  fileRead(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result as string);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  }

  discardSumUpForm() {
    this.filledForm = null;
  }

  async submit() {
    this.loading = true;

    const files = this.form.get('attachments') as FormArray;

    const packedFiles: Array<UploadFile> = [];
    if (files !== null) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      for await (const [_index, f] of files.controls.entries()) {
        const cont = (await this.fileRead(f.value.attachment)).split(',')[1];
        packedFiles.push({
          contentBase64Encoded: cont,
          metadata: {
            isInternal: false,
            documentId: '',
            name: f.value.attachment.name,
            guidExternalSystem: undefined,
            crmGuidOriginRecord: undefined,
            filename: f.value.attachment.name,
            dynamicsUri: undefined,
            publishDocument: undefined,
            statusCode: 'Aktiv',
            contactId: undefined,
            rentalContractId: undefined,
            functionalLocationId: undefined,
            accountId: undefined,
            documentTypeId: f.value.attachmentType,
            changeDate: undefined,
            account: undefined,
            contact: undefined,
            functionalLocation: undefined,
            rentalContract: undefined,
          },
        });
      }
    }

    const preparedData = this.prepareSubmit(this.form.value);

    this.clientEndpoint({
      ...preparedData,
      files: packedFiles,
    }).subscribe(
      () => {
        this.form.reset();
        this.loading = false;
        const successDialog = this.dialog.open(DialogComponent, {
          width: '500px',
          data: {
            type: 'okButton',
            title: 'ticket.create.success.title',
            message: 'ticket.create.success.message',
            okButtonText: 'ticket.create.success.button__text',
          },
        });

        successDialog.afterClosed().subscribe(_res => {
          const targetUrl = this.getStatusPath();
          this.router.navigate([targetUrl]);
        });
        this.cd.detectChanges();
      },
      error => {
        this.loading = false;
        this.dialog.open(DialogComponent, {
          width: '300px',
          data: {
            type: 'noButtons',
            title: 'ticket.create.error.title',
            message: 'ticket.create.error.message',
          },
        });
        console.error(error);
        this.loading = false;
        this.cd.detectChanges();
      }
    );
  }

  getStatusPath() {
    const parts = this.router.url.split('/'); //  ['', 'ticket', 'any']

    // Correct routing for helpdesk ticket creation
    if (parts[1] === 'helpdesk') {
      parts[1] = 'ticket';
    }

    const modulePath = parts[1];
    const newRoute = `/${modulePath}/status`;
    return newRoute;
  }
}
