import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ExportTaskExcelDto, TaskClient } from '@api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'excel-export-telekom',
  templateUrl: './excel-export-telekom.component.html',
})
export class ExcelExportTelekomComponent {
  @Input() formModel: any;
  @Input() isLoading: boolean;
  @Input() formFields: FormlyFieldConfig[];

  constructor(
    private translateService: TranslateService,
    private taskClient: TaskClient,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) {}

  public excelExport() {
    this.isLoading = true;
    const exportFormFields: { [key: string]: string } = {};
    const fieldGroup = this.formFields[8]?.fieldGroup;

    if (fieldGroup) {
      fieldGroup.forEach(field => {
        if (field.templateOptions && field.templateOptions.label) {
          if (typeof(field.defaultValue) === 'number') {
            field.defaultValue = field.defaultValue.toString().replace(',', '.') ?? ''
          }
          exportFormFields[field.templateOptions?.label] =
            field.defaultValue?.defaultName ?? field.defaultValue ?? ''
        }
      });
    }

    const excelExportDto: ExportTaskExcelDto = {
      fileName: `${this.formModel.incident.ticketNumber}_${this.formModel.subject}`,
      ticketNumber: this.formModel.incident.ticketNumber,
      subject: this.formModel.subject,
      result: this.formModel.result,
      newStatus: this.formModel.newStatus,
      formFields: exportFormFields,
    };

    this.taskClient.createExportExcelFromTask(excelExportDto).subscribe(
      res => {
        const fileResponse: any = res;

        if (fileResponse !== null) {
          saveAs(fileResponse.data, `${excelExportDto.fileName}.xlsx`);
        }

        this.isLoading = false;
        this.toastr.success(
          this.translateService.instant('taskmodule.task.details.excel_export.success_message'),
          this.translateService.instant('taskmodule.task.details.excel_export.success_title')
        );
        this.cd.detectChanges();
      },
      err => {
        console.error(err);
        this.isLoading = false;
        this.toastr.error(
          this.translateService.instant('taskmodule.task.details.excel_export.error_message'),
          this.translateService.instant('taskmodule.task.details.excel_export.error_title')
        );
        this.cd.detectChanges();
      }
    );
  }
}
