import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideIn = trigger('slideIn', [
  state(
    '*',
    style({
      transform: 'translateX(100%)',
    })
  ),
  state(
    'in',
    style({
      transform: 'translateX(0)',
    })
  ),
  state(
    'out',
    style({
      transform: 'translateX(-100%)',
    })
  ),
  transition('* => in', animate('600ms ease-in')),
  transition('in => out', animate('600ms ease-in')),
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0, height: '0', margin: '0', padding: '0' }),
    animate('300ms 0ms ease-in-out', style({ opacity: 1, height: '*', margin: '*', padding: '*' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, height: '*', margin: '*', padding: '*' }),
    animate('300ms 0ms ease-in-out', style({ opacity: 0, height: '0', margin: '0', padding: '0' })),
  ]),
]);
