import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FormlyFieldBaseService {
  translatePath = 'form';

  constructor(protected translateService: TranslateService) {}

  createInput(
    key: string,
    labelKey: string,
    required: boolean = true,
    visibleInConfirm: boolean = true,
    maxLength: number = 200
  ): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            type: 'input',
            key,
            wrappers: ['readOnly', 'form-field'],
            templateOptions: {
              label: this.translate(labelKey),
              required,
              visibleInConfirm,
              maxLength,
            },
          },
        ],
      },
    ];
  }

  createTextarea(
    key: string,
    labelKey: string,
    required: boolean = true,
    visibleInConfirm: boolean = true,
    maxLength: number = 500,
    rows: number = 5,
    defaultValue: any = undefined
  ): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key,
            type: 'textarea',
            defaultValue,
            templateOptions: {
              label: this.translate(labelKey),
              required,
              visibleInConfirm,
              maxLength,
              rows,
            },
          },
        ],
      },
    ];
  }

  createHidden(key: string, value: any): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key,
            defaultValue: value,
          },
        ],
      },
    ];
  }

  createDateTimePicker(
    key: string,
    labelKey: string,
    required: boolean = true,
    visibleInConfirm: boolean = true,
    dateFilter = (_d: moment.Moment): boolean => true,
    startAt: moment.Moment = moment().startOf('day')
  ): FormlyFieldConfig[] {
    return [
      {
        type: 'matdatetimepicker',
        key,
        templateOptions: {
          label: this.translate(labelKey),
          required,
          visibleInConfirm,
          startAt,
          startView: 'month',
          dateTimeFormat: this.translate('locale_moment__date_format'),
          dateTimeFormatConfirm: this.translate('locale_moment__date_format'),
          timezone: 'Europe/Berlin',
          dateFilter,
        },
        expressionProperties: {
          'templateOptions.minDate': () => {
            return moment(new Date()).startOf('day');
          },
        },
      },
    ];
  }

  createFileUpload(accept = '*', maxFileSizeInMB = '10'): FormlyFieldConfig[] {
    return [
      {
        key: 'attachments',
        type: 'repeat',
        templateOptions: {
          addText: this.translateService.instant('ticket.create.button__attach_new_file'),
          tooltip: this.translateService.instant('ticket.create.p__info_attachment'),
        },

        fieldArray: {
          type: 'flex-layout',
          templateOptions: {
            fxLayout: 'row',
          },
          fieldGroup: [
            {
              type: 'file',
              key: 'attachment',
              templateOptions: {
                fxFlex: 'fxFlex',
                label: this.translateService.instant('ticket.create.label__attachment'),
                visibleInConfirm: true,
                accept,
                maxFileSizeInMB,
              },
            },
          ],
        },
      },
    ];
  }

  createDragAndDropFileUpload(accept = '*', maxFileSizeInMB = '10'): FormlyFieldConfig[] {
    return [
      {
        type: 'dragdrop',
        key: 'attachments',
        templateOptions: {
          label: this.translateService.instant('ticket.create.label__attachment'),
          visibleInConfirm: true,
          accept,
          maxFileSizeInMB,
        },
      },
    ];
  }

  private translate(key: string): string {
    return this.translateService.instant(`${this.translatePath}.${key}`);
  }
}
