import { Component, ViewEncapsulation } from '@angular/core';
import { ErrorCodeComponent } from './error-code.component';

@Component({
  selector: 'error-code-401',
  templateUrl: './error-code.component.html',
  styleUrls: ['./error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorCode401Component extends ErrorCodeComponent {
  /**
   * This class exists to not have to pass params to the base ErrorCodeComponent from route.
   */
  constructor() {
    super();
    this.code = '401';
    this.title = 'Unauthorisierter Zugriff';
    this.message =
      'Sie sind nicht angemeldet oder nicht ausreichend berechtigt um diese Seite anzuzeigen 😔';
  }
}
