import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationFormlyFieldService } from '@routes/location/location-formly-field.service';

export function searchLocation(
  translateService: TranslateService,
  locationFormlyFieldService: LocationFormlyFieldService,
  onSelectLocation: (id: string) => void,
  weNo?: string | null
): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.confirm.headline__objectAdress'),
            visibleInConfirm: true,
            tooltip: translateService.instant('ticket.create.tooltip__search_location'),
          },
        },
        {
          key: 'searchLocation',
          fieldGroup: [
            ...locationFormlyFieldService.autocompleteSearch(
              true,
              { archObjId: weNo },
              onSelectLocation
            ),

            {
              type: 'input',
              key: 'roomNumber',
              templateOptions: {
                label: translateService.instant('ticket.create.label__roomNumber'),
                required: true,
                visibleInConfirm: true,
              },
            },
            {
              type: 'flex-layout',
              fieldGroup: [
                {
                  className: 'col-0 m-b-16',
                  template:  translateService.instant('helpdesk.ticket.create.description__roomNumber'),
                },
              ],
            },
            {
              type: 'select',
              key: 'floor',
              templateOptions: {
                label: translateService.instant('helpdesk.ticket.create.label__floor'),
                visibleInConfirm: true,
              },
            },
            {
              type: 'input',
              key: 'section',
              templateOptions: {
                label: translateService.instant('helpdesk.ticket.create.label__section'),
                visibleInConfirm: true,
                maxLength: 20,
              },
              validation: {
                messages: {
                  pattern: translateService.instant('formErrors.section.pattern'),
                },
              },
            },
            {
              type: 'textarea',
              key: 'auxaddress',
              templateOptions: {
                label: translateService.instant('helpdesk.ticket.create.label__differentAddress'),
                visibleInConfirm: true,
                autosize: true,
                autosizeMinRows: 4,
                maxLength: 2000,
              },
            },
          ],
        },
      ],
    },
  ];
}
