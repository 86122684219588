import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '@core/bootstrap/menu.service';
import { HelpService } from '@shared';
import { MandantConfigService } from '@strategies/mandant-config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  title = '';

  description = '';

  mandant: string;

  items: any;

  constructor(
    private helpService: HelpService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected menuService: MenuService,
    private mandantConfigService: MandantConfigService
  ) {}

  ngOnDestroy(): void {
    this.helpService.reset();
  }

  ngOnInit() {
    this.mandant = this.mandantConfigService.mandant.name;
    this.loadItems();
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  protected loadItems() {
    this.items = this.menuService.getFilteredMenu$();

    this.activatedRoute.data.subscribe(data => {
      this.title = data.title;
      this.description = data.description;
      if (data.helpId) {
        this.helpService.set(data.helpId);
      }
    });
  }
}
