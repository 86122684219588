import { Component } from '@angular/core';

@Component({
  selector: 'app-feedback',
  template: `
    <button
      mat-button
      class="matero-toolbar-button"
      [routerLink]="['/feedback']"
      style="padding-left: 12px"
    >
      <mat-icon style="padding-right: 36px">thumb_up</mat-icon>
      <span>{{ 'menu.feedback.feedback_action' | translate }}</span>
    </button>
  `,
})
export class FeedbackComponent {}
