import { TranslateService } from '@ngx-translate/core';

export function enumToOptionSet(
  theEnum,
  translateService: TranslateService,
  translatePrefix?: string
) {
  const dot = translatePrefix ? '.' : '';
  return Object.values(theEnum)
    .filter(key => typeof key === 'string')
    .map(key => ({
      label: translateService.instant(`${translatePrefix || ''}${dot}${key as string}`),
      value: theEnum[key as string] as number,
      disabled: false,
    }));
}
