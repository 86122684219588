import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseSearchOptions, IncidentViewOptions, OrderDirection } from '@api';
import { MsalService } from '@azure/msal-angular';
import { MtxGridColumn } from '@ng-matero/extensions/data-grid';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@shared/constants/user-roles.constants';
import { rxSubscriptionContainerMixin } from '@shared/mixins';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AddButtonType } from './models/add-button-type.model';
import {
  IAddButtonConfig,
  ITableConfig,
  ITableData,
  ITableFilterConfig,
  ITableGridConfig,
  ITablePagingConfig,
  ITableSelectConfig,
} from './models/table.base.model';
import { TableService } from './services/table.service';
import { TableFilterComponent } from './table-filter/table-filter.component';

@Component({
  selector: 'app-list-base',
  templateUrl: './table.base.html',
  styleUrls: ['./table.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBaseComponent
  extends rxSubscriptionContainerMixin()
  implements OnDestroy, AfterViewInit
{
  config: ITableConfig = <ITableConfig>{
    title: '',
    translatePath: '',
    showSmartCard: true,
    showBackButton: true,
    showRefreshButton: true,
    addButton: <IAddButtonConfig>{
      disabled: true,
    },
    grid: <ITableGridConfig>{
      showToolbar: true,
      columnResizable: true,
      paging: <ITablePagingConfig>{
        pageIndex: 0,
        pageSize: 50,
        showPaginator: true,
      },
      filter: <ITableFilterConfig>{
        filterVisible: false,
        sortOnFront: false,
        filterOptions: {},
      },
      select: <ITableSelectConfig>{
        rowSelectable: false,
        multiSelectable: false,
      },
    },
  };

  data = <ITableData>{
    items: undefined,
    itemsOriginal: undefined,
    totalCount: 0,
  };

  isLoading = false;

  columns: Array<MtxGridColumn> = [];

  fieldsInExpansionTemplate: string[] = [];

  viewOptionsFields?: FormlyFieldConfig[];

  filterFields?: FormlyFieldConfig[];

  query: BaseSearchOptions = {
    pagingOptions: {
      skip: this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
      take: this.config.grid.paging.pageSize,
    },
  };

  @ViewChild(TableFilterComponent) crudFilterComponent: TableFilterComponent;

  getTableData$: (
    query?: BaseSearchOptions | undefined,
    filterOptions?: any,
    viewOptions?: IncidentViewOptions | undefined
  ) => Observable<any>;

  clickRow: (data: any, router: Router, route: ActivatedRoute) => void;

  clickAdd: (router: Router, route: ActivatedRoute) => void;

  protected router: Router;

  protected route: ActivatedRoute;

  protected toastr: ToastrService;

  protected cd: ChangeDetectorRef;

  protected tableService: TableService;

  protected translateService: TranslateService;

  protected msalService: MsalService;

  protected selectedRows: any[] = [];

  constructor(@Inject(Injector) injector: Injector) {
    super();

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.toastr = injector.get(ToastrService);
    this.cd = injector.get(ChangeDetectorRef);

    this.tableService = injector.get(TableService);
    this.translateService = injector.get(TranslateService);

    this.msalService = injector.get(MsalService);
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (params.filterOptions) {
        this.config.grid.filter.filterOptions = {
          ...this.config.grid.filter.filterOptions,
          ...JSON.parse(params.filterOptions),
        };
        this.crudFilterComponent.patchValue(this.config.grid.filter.filterOptions);
        this.config.grid.filter.filterVisible = true;
      }
      this.getData();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getData() {
    this.isLoading = true;

    this.pushSubscription(
      this.getTableData$(
        this.query,
        this.config.grid.filter.filterOptions,
        this.config.grid.filter.viewOptions
      ).subscribe(
        data => {
          this.data.items = data.entites;
          this.data.totalCount = data.totalCount;
          this.isLoading = false;
          this.cd.detectChanges();

          if (this.config.grid.filter.filterOnFront) {
            this.refreshFromFilter();
          }
        },
        error => {
          console.log(error);
          this.isLoading = false;
          if (error.errorCode === 3 && error.message === 'USER_HAS_NO_ORGANISATIONID') {
            this.toastr.error(
              this.translateService.instant('crud.details.error.failed_to_load_no_orgId_msg'),
              this.translateService.instant('crud.details.error.failed_to_load_title'),
              { timeOut: 0 }
            );
          } else {
            this.toastr.error(
              this.translateService.instant('crud.details.error.failed_to_load_msg'),
              this.translateService.instant('crud.details.error.failed_to_load_title'),
              { timeOut: 0 }
            );
          }
          this.isLoading = false;
          this.cd.detectChanges();
        }
      )
    );
  }

  refresh() {
    this.getData();
  }

  refreshFromFilter() {
    if (!this.config.grid.filter.filterOnFront) {
      this.refresh();
    } else {
      if (!this.data.itemsOriginal) {
        this.data.itemsOriginal = Object.assign([], this.data.items);
      }
      this.data.items = Object.assign([], this.data.itemsOriginal);
      this.data.items = this.data.items.filter((row: any) => {
        let found = false;
        let hasFilter = false;
        Object.keys(this.config.grid.filter.filterOptions).forEach(filterKey => {
          const filterText = this.config.grid.filter.filterOptions[filterKey]?.toLowerCase();

          if (!found) {
            found = this.isFilterFound(row, filterKey, filterText);
          }

          if (filterText) {
            hasFilter = true;
          }
        });
        return !hasFilter || found ? row : null;
      });

      this.cd.detectChanges();
    }
  }

  updateFilter(filterOptions: any) {
    this.config.grid.filter.filterOptions = filterOptions;
  }

  updateViewOptions(viewOptions: any) {
    this.config.grid.filter.viewOptions = viewOptions.viewOption;
    this.getData();
  }

  resetFilter() {
    this.config.grid.filter.filterOptions = {};
    this.refresh();
  }

  toggleFilter() {
    this.config.grid.filter.filterVisible = !this.config.grid.filter.filterVisible;
  }

  sortChange(event) {
    this.query = {
      ...this.query,
      orderOptions: {
        attributeName: event.active,
        orderDirection: this.directionStringToEnum(event.direction),
      },
    };
    if (!this.config.grid.filter.sortOnFront) {
      this.getData();
    }
  }

  getNextPage(e: PageEvent) {
    this.config.grid.paging.pageIndex = e.pageIndex;
    this.config.grid.paging.pageSize = e.pageSize;

    this.query = {
      ...this.query,
      pagingOptions: {
        skip: this.config.grid.paging.pageIndex * this.config.grid.paging.pageSize,
        take: this.config.grid.paging.pageSize,
      },
    };

    this.getData();
  }

  clickRowWrapper(row) {
    this.clickRow(row, this.router, this.route);
  }

  clickAddWrapper() {
    this.clickAdd(this.router, this.route);
  }

  rowSelectionChanged(event: any) {
    this.selectedRows = event;
  }

  downloadDocumentAllowed(): boolean {
    return this.hasRole(UserRoles.SMARTPORTAL_DOCUMENT_READ);
  }

  archiveToImageMasterAllowed(): boolean {
    return this.hasRole(UserRoles.SMARTPORTAL_DOCUMENT_IMAUPLOAD);
  }

  hasRole(roleName: string): boolean {
    const userRoles = this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles;
    return !!userRoles?.includes(roleName);
  }

  hideRowActions(row: any): boolean {
    if (this.selectedRows?.length > 1) {
      return !this.selectedRows.find(selectedRow => {
        return selectedRow.documentId === row.documentId;
      });
    }
    return false;
  }

  protected configure(config: ITableConfig) {
    return { ...this.config, ...config };
  }

  protected translate(key: string) {
    return this.translateService.instant(`${this.config.translatePath}.${key}`);
  }

  protected initAddButton(type: AddButtonType) {
    this.pushSubscription(
      this.tableService.initAddButton$(type).subscribe((config: IAddButtonConfig) => {
        this.config.addButton.label = config.label;
        this.config.addButton.disabled = config.disabled;
      })
    );
  }

  protected isFilterFound(row: any, filterKey: string, filterText: string): boolean {
    let found = false;
    if (filterText) {
      const text = row[filterKey]?.toString().toLowerCase();
      if (text.includes(filterText)) {
        found = true;
      }
    }
    return found;
  }

  private directionStringToEnum(dir: string): OrderDirection {
    switch (dir) {
      case 'asc': {
        return OrderDirection.Asc;
      }
      case 'desc': {
        return OrderDirection.Desc;
      }
      default: {
        return OrderDirection.None;
      }
    }
  }
}
