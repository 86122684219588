import { Component, ViewEncapsulation } from '@angular/core';
import { ErrorCodeComponent } from './error-code.component';

@Component({
  selector: 'error-code-503',
  templateUrl: './error-code.component.html',
  styleUrls: ['./error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorCode503Component extends ErrorCodeComponent {
  /**
   * This class exists to not have to pass params to the base ErrorCodeComponent from route.
   */
  constructor() {
    super();
    this.code = '503.1';
    this.title = 'page under construction';
    this.message = 'Please come back later 😊';
  }
}
