<smart-card [backButton]="config.showBackButton" [offset]="2" class="crud-details-wrapper">
  <h2 [innerText]="config.title"></h2>
  <app-detail-fields-base
    *ngIf="!isLoading && data"
    [allowFileUpload]="config.showFileUpload"
    [detailFields]="detailFields"
    [data]="data"
    [canSelectVisibility]="canSelectVisibility$ | async"
  ></app-detail-fields-base>
  <ng-template appPlaceholder></ng-template>
  <details *ngIf="isDevMode">
    <pre>{{ data | json }}</pre>
  </details>
</smart-card>
<spinner-overlay [show]="isLoading"></spinner-overlay>
