import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Observable, of, Subscription } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, SettingsService } from '@core';
import { MandantConfigService } from '@strategies';

const MOBILE_MEDIAQUERY = 'screen and (max-width: 599px)';
const TABLET_MEDIAQUERY = 'screen and (min-width: 600px) and (max-width: 959px)';
const MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public sidenav: MatSidenav;

  @ViewChild('sidenav', { static: false }) set setSidenav(sidenav: MatSidenav) {
    if (sidenav) {
      this.sidenav = sidenav;
    }
  }

  @ViewChild('content', { static: true }) content: MatSidenavContent;

  public isLandingModule: Observable<boolean> = of(false);

  options = this.settings.getOptions();

  private layoutChangesSubscription: Subscription;

  get isOver(): boolean {
    return this.isMobileScreen;
  }

  private isMobileScreen = false;

  private isTabletScreen = false;

  @HostBinding('class.matero-content-width-fix') get contentWidthFix() {
    return (
      this.isContentWidthFixed &&
      this.options.navPos === 'side' &&
      this.options.sidenavOpened &&
      !this.isOver
    );
  }

  private isContentWidthFixed = true;

  @HostBinding('class.matero-sidenav-collapsed-fix') get collapsedWidthFix() {
    return (
      this.isCollapsedWidthFixed &&
      (this.options.navPos === 'top' || (this.options.sidenavOpened && this.isOver))
    );
  }

  private isCollapsedWidthFixed = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private settings: SettingsService,
    public mandantConfigService: MandantConfigService
  ) {
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
      .subscribe(state => {
        // SidenavOpened must be reset true when layout changes
        this.options.sidenavOpened = true;

        this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
        this.isTabletScreen = state.breakpoints[TABLET_MEDIAQUERY];
        this.options.sidenavCollapsed = this.isTabletScreen;
        this.isContentWidthFixed = state.breakpoints[MONITOR_MEDIAQUERY];
      });

    // listen to the route and change the styling of the app if the route points to the dedicated
    // landing module (which is customer specific and has its own styling).
    this.router.events.subscribe((_event: any) => {
      this.isLandingModule = of(
        (this.activatedRoute?.snapshot.firstChild?.routeConfig?.path ?? '') === 'landing'
      );
    });

    // Initialize project theme with options
    this.receiveOptions(this.options);
  }

  public get showPermanentSideMenu() {
    return this.mandantConfigService.mandant.showPermanentSideMenu;
  }

  public get mobileSideMenuPosition() {
    return this.showPermanentSideMenu
      ? this.mandantConfigService.mandant.mobileSideMenuPosition || 'end'
      : 'end';
  }

  ngOnInit() {
    setTimeout(() => {
      this.isContentWidthFixed = false;
      this.isCollapsedWidthFixed = false;
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  toggleCollapsed() {
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => {
      this.settings.setNavState('collapsed', this.options.sidenavCollapsed);
    }, timer);
  }

  sidenavCloseStart() {
    this.isContentWidthFixed = false;
  }

  sidenavOpenedChange(isOpened: boolean) {
    this.options.sidenavOpened = isOpened;
    this.settings.setNavState('opened', isOpened);

    this.isCollapsedWidthFixed = !this.isOver;
    this.resetCollapsedState();
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
  }
}
