// https://regexr.com/2rhq7 RFC28822 standard Email validation
export const RegexpPhoneInternational =
  /^(?:(\d{1,3}))?([-/\\(]*(\d{3})[-/\\)]*)?((\d{3})[-/\\]*(\d{2,4})(?:[-x/\\]*(\d+))?)$/;
export const RegexpCountryCode = /^(\+|00)\d{1,4}$/;
export const RegexpEmail = /[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const RegexpMultipleEmail =
  /^(([\w\-\.]+)@((([a-zA-Z0-9\-]+)\.))((([a-zA-Z]{2,4});)|([a-zA-Z]{2,4})$))*$/;
export const RegexpPhoneDE = /^(\+49|0|0049)+([\s/|-]?\d+)*$/;
export const RegexpPhoneInt = /^(\+\d{2}|0)+([\s/|-]?\d+)*$/;
export const RegexpZipCodeDE = /^\d{5}$/;
export const RegexpStreetname = /^[ A-Za-z0-9_.,/ß&\-äÄöÖüÜ'"]*$/;
export const RegexpPlotnumber = /^[A-Za-z0-9]*$/;
export const RegexpSingleDigitInteger = /^\d$/;
