import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-recaptcha',
  template: ` <re-captcha
      (resolved)="resolved($event)"
      [siteKey]="field.templateOptions?.siteKey"
      [formControl]="control"
      [formlyAttributes]="field"
    >
    </re-captcha>
    <pre></pre>`,
})
export class FormlyRecaptchaComponent extends FieldType {
  control: FormControl;

  field: FormlyFieldConfig;

  resolved(captchaResponse: string) {
    // todo: set validated true;
    this.field.formControl?.setValue(captchaResponse);
  }
}
