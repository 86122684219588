<div *ngIf="backButton">
  <button fxFlex="nogrow" mat-raised-button color="primary" (click)="pressBackToEdit()">
    <mat-icon class="materialSendIcon">arrow_back</mat-icon>
    <span class="customSendIconLeft"></span>
    <span [innerHtml]="'ticket.create.confirm.button__back_to_edit' | translate | uppercase"></span>
  </button>
</div>

<ng-container *ngIf="showTicketCreateData; else elseShowTicketCreateData">
  <div fxLayoutGap="8px">
    <h2 [innerText]="translateKeyTitle | translate"></h2>
    <p [innerText]="translateKeyDescription | translate" class="confirm-value"></p>
  </div>

  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__short_title' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[0]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__short_description' | translate"></span>:
    </div>
  </div>
  <br />
  <div>
    <span class="confirm-value">{{ filledForm[1]?.value }}</span>
  </div>
  <br />
  <br />

  <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="8px" class="confirm-row">
    <div fxLayout="row" fxFlex fxLayoutGap="10px">
      <div fxFlex [style.padding-top.px]="22" fxHide.lt-sm class="confirm-chapter-hr-div">
        <hr class="confirm-chapter-hr" />
      </div>
      <div fxFlex="nogrow">
        <h3
          [innerText]="'ticket.create.confirm.headline__objectAdress' | translate"
          class="confirm-chapter-headline"
        ></h3>
      </div>
      <div fxFlex [style.padding-top.px]="22" class="confirm-chapter-hr-div">
        <hr class="confirm-chapter-hr" />
      </div>
    </div>
  </div>

  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.confirm.label__placeholder' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value"
      >{{ filledForm[4]?.value?.siteName }}, {{ filledForm[4]?.value?.address?.city }},
      {{ filledForm[4]?.value?.address?.streetName }}</span
    >
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.confirm.label__roomNumber' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[5]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.confirm.label__floor' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[7]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.confirm.label__section' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[8]?.value }}</span>
  </div>
  <br />
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.confirm.label__differentAddress' | translate"></span>:
    </div>
  </div>
  <br />
  <div>
    <span>{{ filledForm[9]?.value }}</span>
  </div>
  <br />
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__salution' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[13]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__jobtitel' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[14]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__firstName' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[15]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__lastName' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[16]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__phoneNumber' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[17]?.value }}</span>
  </div>
  <br />
  <div>
    <div fxFlex class="confirm-label">
      <span [innerText]="'ticket.create.label__email' | translate"></span>:
    </div>
    <span fxFlex class="confirm-value">{{ filledForm[18]?.value }}</span>
  </div>
</ng-container>

<ng-template #elseShowTicketCreateData>
  <div fxLayoutGap="8px" *ngIf="!hideTitle">
    <h2 [innerText]="translateKeyTitle | translate"></h2>
    <p [innerText]="translateKeyDescription | translate" class="confirm-value"></p>
  </div>

  <div *ngFor="let field of filledForm">
    <ng-container *ngIf="field.visibleInConfirm">
      <div
        fxLayout.gt-sm="row"
        fxLayout="column"
        [ngSwitch]="field.type"
        fxLayoutGap="8px"
        class="confirm-row"
      >
        <ng-container *ngSwitchCase="'textarea'">
          <div fxFlex>
            <!-- <strong>
              <p [innerText]="field.label + ':'"></p>
            </strong>
            <div [innerText]="field.value"></div> -->
            <div class="confirm-label"><p [innerText]="field.label + ':'"></p></div>
            <div [innerText]="field.value"></div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'formly-template'">
          <div fxFlex [innerHtml]="field.data.template"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'chapter'">
          <div fxLayout="row" fxFlex fxLayoutGap="10px">
            <div fxFlex [style.padding-top.px]="22" fxHide.lt-sm class="confirm-chapter-hr-div">
              <hr class="confirm-chapter-hr" />
            </div>
            <div fxFlex="nogrow">
              <h3 [innerHtml]="field.label" class="confirm-chapter-headline"></h3>
            </div>
            <div fxFlex [style.padding-top.px]="22" class="confirm-chapter-hr-div">
              <hr class="confirm-chapter-hr" />
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'matdatetimepicker'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex class="confirm-value">
            <span *ngIf="!field?.data?.templateOptions?.dateTimeFormatConfirm">
              {{ field.value }}
            </span>
            <span *ngIf="field?.data?.templateOptions?.dateTimeFormatConfirm">
              {{ field.value | date: field?.data?.templateOptions?.dateTimeFormat }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'datetimepicker'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex class="confirm-value">
            <span *ngIf="!field?.data?.templateOptions?.dateTimeFormatConfirm">
              {{ field.value }}
            </span>
            <span *ngIf="field?.data?.templateOptions?.dateTimeFormatConfirm">
              {{ field.value | date: field?.data?.templateOptions?.dateTimeFormat }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex [innerText]="field.value" class="confirm-value"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'tree-select'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex [innerText]="field.value" class="confirm-value"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'pasm-autocomplete'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex class="confirm-value">
            <span *ngIf="field.value.siteName">{{ field.value.siteName }};</span>
            <span *ngIf="field.value.siteAdditionalNumber"
              >{{ field.value.siteAdditionalNumber }};</span
            >
            <span *ngIf="field.value.vpsz">{{ field.value.vpsz }};</span>
            <span *ngIf="field.value.address.streetName">
              {{ field.value.address.streetName }},
              {{ field.value.address.postalCode }}
              {{ field.value.address.city }}
            </span>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'dragdrop'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div fxFlex class="confirm-value">{{ field.name }}</div>
        </ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div
            *ngIf="field.value"
            class="confirm-value"
            fxFlex
            fxLayoutAlign="start end"
            [innerText]="'selfservice.ticket.create.label__residentialAccess_yes' | translate"
          ></div>
          <div
            *ngIf="!field.value"
            class="confirm-value"
            fxFlex
            fxLayoutAlign="start end"
            [innerText]="'selfservice.ticket.create.label__residentialAccess_no' | translate"
          ></div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div fxFlex class="confirm-label"><span [innerText]="field.label"></span>:</div>
          <div
            class="confirm-value"
            fxFlex
            fxLayoutAlign="start end"
            [innerText]="field.value"
          ></div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
<hr *ngIf="!hideTitle" />

<form (ngSubmit)="validateConfirmedForm()" [formGroup]="extraConfirmForm">
  <div>
    <formly-form
      [form]="extraConfirmForm"
      [fields]="extraConfirmFormFields"
      [model]="extraConfirmFormModel"
    ></formly-form>
    <div fxLayout="row" [style.margin]="'20px 0px'" *ngIf="dsgvoUrl && dsgvoUrl != ''">
      <a fxFlex fxLayout="row" [href]="dsgvoUrl" target="_blank">
        <mat-icon>description</mat-icon>
        <span [innerText]="'ticket.create.confirm.label__dsgvo_link' | translate"></span>
      </a>
    </div>
  </div>

  <div fxLayoutGap="20px" fxLayout="column" fxLayout.gt-md="row">
    <button fxFlex="50" type="button" mat-raised-button color="primary" (click)="pressBackToEdit()">
      <span class="customSendIconLeft"></span>
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span [innerText]="'ticket.create.confirm.button__back_to_edit' | translate"></span>
    </button>
    <button fxFlex="50" type="submit" mat-raised-button color="accent">
      <span class="customSendIconRight"></span>
      <span [innerText]="'ticket.create.confirm.button__send' | translate"></span>&nbsp;
      <mat-icon class="materialSendIcon">send</mat-icon>
    </button>
  </div>
</form>
