import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { MenuService } from '@core';
import { environment } from '@env/environment';
import { LoginBehaviour } from '@env/LoginBehaviour';
import { rxSubscriptionContainerMixin } from '@shared';
import { MandantConfigService } from '@strategies';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from 'apps/smart-portal/src/app/services/OAuthService/authentication.service';
import { JwtParser } from 'apps/smart-portal/src/app/services/OAuthService/jwt-parser';
import { filter } from 'rxjs/operators';
import { b2cPolicies, protectedResources } from '../../../../../../auth-config';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent extends rxSubscriptionContainerMixin() implements OnInit {
  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private mandantConfigService: MandantConfigService,
    public oauthService: OAuthService,
    public authenticationService: AuthenticationService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public menuService: MenuService
  ) {
    super();
  }

  public loggedinB2C = false;

  public loggedinCiam = false;

  public accountDisplayName = 'login';

  public showUser = false;

  public profileLink = 'profile';

  async ngOnInit(): Promise<void> {
    const config = this.mandantConfigService.mandant;
    if (config && config.showLogin === true) {
      this.showUser = true;
      this.setLoginDisplay();
    }
    if (environment.B2cOauthConfig || environment.CiamConfig) {
      await this.b2cOAuthLoginLogic();
    }

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        console.log('LOGIN SUCCESS:', result);

        const accessTokenRequest: SilentRequest = {
          scopes: [...protectedResources.getIncident.scopes],
          account: this.msalAuthService.instance.getAllAccounts()['0'],
        };

        this.msalAuthService
          .acquireTokenSilent(accessTokenRequest)
          .toPromise()
          .then((value: AuthenticationResult) => {
            console.log('got AuthenticationResult via SilentRefresh:', value);
          })
          .catch(reason => {
            console.log('[user] Acquire Token silent error: ', reason);
          });

        this.setLoginDisplay();
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe((status: InteractionStatus) => {
        console.log('loginstatus:', status);
        this.setLoginDisplay();
      });
  }

  private async b2cOAuthLoginLogic() {
    this.pushSubscription(
      this.oauthService.events.subscribe(_value => {
        this.setLoginDisplay();
        // console.log('oauthService.events', value);
      })
    );
    await this.authenticationService.ConfigureOAuth();
    await this.oauthService.tryLoginCodeFlow();
    const token = this.oauthService.getIdToken();
    // console.log('(user component) TOKEN/idToken:', token);
    if (token) {
      this.authenticationService.setToken(token);
      this.setLoginDisplay();
    }

    this.setLoginDisplay();
  }

  public OnLoginClick(_event: any) {
    switch (environment.LoginButtonBehaviour) {
      case LoginBehaviour.Choice:
        this.router.navigate(['authentication', 'login']);
        break;
      case LoginBehaviour.B2C:
        this.router.navigate(['authentication', 'b2c']);
        break;
      case LoginBehaviour.Ciam:
        this.router.navigate(['authentication', 'ciam']);
        break;
      default:
        break;
    }
  }

  public OnLogoutClick(_event: any) {
    this.authenticationService.doLogout();
    this.msalAuthService.logout();
    //   {
    //   postLogoutRedirectUri: `${window.location.origin}`,
    // }
  }

  public onProfileClick() {
    this.router.navigate([this.profileLink]);
  }

  setLoginDisplay() {
    const accounts = this.msalAuthService.instance.getAllAccounts();
    if (accounts['0'] != null) {
      this.loggedinB2C = true;
      this.accountDisplayName = `b2c | ${accounts['0'].name ?? 'login'}`;
    }

    const ciamToken = this.oauthService.getIdToken();
    // console.log('ciamToken', ciamToken);
    if (ciamToken) {
      this.loggedinCiam = true;
      const decodedToken = JwtParser.decodeToken(ciamToken);
      this.accountDisplayName = `${decodedToken?.family_name}, ${decodedToken?.given_name}`;
    }
    // const ciamToken = this.oauthService.getAccessToken();
  }

  public OnResetPasswordClick() {
    const editProfileFlowRequest: RedirectRequest = {
      scopes: ['openid'],
      authority: b2cPolicies.authorities.editProfile.authority,
    };
    this.msalAuthService.loginRedirect(editProfileFlowRequest);
  }
}
