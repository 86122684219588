<div fxFlex="100" [fxLayout]="temp1" [style.margin-bottom.em]="temp2">
  <ng-container *ngFor="let field of detailFields">
    <ng-container [ngSwitch]="field.type" *ngIf="visible(field)">
      <!-- FIELD -->
      <ng-container *ngSwitchCase="'field'">
        <div [fxFlex]="field.fxFlex || '33'" fxFlex.xs="100" fxFlex.sm="50">
          <span
            [innerText]="getLabel(field)"
            class="field-headline"
            [style]="field.cssTitle"
          ></span>
          <span *ngIf="field.break===false ?? false">: </span>
          <span *ngIf="field.break !== false"><br /></span>
          <span [innerText]="getData(field)" [style]="field.css" class="field-content"></span>
        </div>
      </ng-container>

      <!-- ALLIGNEDFIELD -->
      <ng-container *ngSwitchCase="'allignedField'">
        <div [fxFlex]="field.fxFlex || '33'" fxLayout="row" fxLayout.lt-lg="column">
          <div fxFlex="50">
            <span [innerText]="getLabel(field) + ': '" class="field-headline"></span>
          </div>
          <div fxFlex="50">
            <span [innerText]="getData(field)" [style]="field.css" class="field-content"></span>
          </div>
        </div>
      </ng-container>

      <!-- LINK -->
      <ng-container *ngSwitchCase="'link'">
        <div [fxFlex]="field.fxFlex || '33'" fxFlex.xs="100" fxFlex.sm="50">
          <span [innerText]="getLabel(field)" class="field-headline"></span>
          <span *ngIf="field.break===false ?? false">: </span>
          <span *ngIf="field.break !== false"><br /></span>
          <a [href]="getLink(field)" target="_blank">
            <span [innerText]="getData(field)" [style]="field.css" class="field-content"></span>
          </a>
        </div>
      </ng-container>

      <!-- HTML -->
      <ng-container *ngSwitchCase="'html'">
        <div [fxFlex]="field.fxFlex || '33'">
          <span [innerText]="getLabel(field)" class="field-headline"></span>
          <span *ngIf="field.break===false ?? false">: </span>
          <span *ngIf="field.break !== false"><br /></span>
          <span [innerHTML]="getData(field)" [style]="field.css" class="field-content"></span>
        </div>
      </ng-container>

      <!-- TEXTAREA -->
      <ng-container *ngSwitchCase="'textarea'">
        <div fxFlex="100">
          <span
            [innerText]="getLabel(field)"
            class="field-headline"
            [style]="field.cssTitle"
          ></span>
          <p [innerText]="getData(field)" [style]="field.css" class="textarea"></p>
        </div>
      </ng-container>

      <!-- CHAPTER -->
      <ng-container *ngSwitchCase="'chapter'">
        <div fxFlex="100" fxLayout="row" fxLayoutGap="10px">
          <div fxFlex [style.padding-top.px]="22" fxHide.lt-sm><hr /></div>
          <div fxFlex="nogrow" [style.color]="'var(--theme-accent-500)'">
            <h3 [innerHtml]="getLabel(field)"></h3>
          </div>
          <div fxFlex [style.padding-top.px]="22"><hr /></div>
        </div>
      </ng-container>

      <!-- FILES -->
      <ng-container *ngSwitchCase="'files'">
        <h4 *ngIf="field.label" [innerText]="field.label"></h4>
        <ng-container *ngIf="mandant == 'brst'">
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-upload
                *ngIf="allowFileUpload"
                [hasTaskSelection]="false"
                [hasTargetSystemSelection]="false"
                [hasDocumentTypeSelection]="false"
                [canSelectVisibility]="canSelectVisibility"
                [metadata]="getMetadata(field)"
                (uploadEvent)="documentsBufferedChanged($event)"
              ></app-detail-file-upload>
            </div>
          </div>
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-list-buffered
                *ngIf="documentsBuffered.length > 0"
                [documents]="documentsBuffered"
                (removeEvent)="documentsBufferedChanged($event)"
              >
              </app-detail-file-list-buffered>
            </div>
          </div>
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-list
                *ngIf="documents.length > 0"
                [documents]="documents"
                [canSelectVisibility]="canSelectVisibility"
              ></app-detail-file-list>
            </div>
          </div>
        </ng-container>

        <div *ngIf="mandant != 'brst'" fxFlex="100" [style.margin-right.px]="-24">
          <div fxFlex="100" fxLayout="row" fxLayoutGap="16px" class="button-new-line">
            <div fxFlex="50" fxLayout="column" fxLayoutGap="16px">
              <app-detail-file-upload
                *ngIf="isFileUploadAllowed()"
                [useFileUploadDefault]="true"
                [hasTaskSelection]="false"
                [hasTargetSystemSelection]="false"
                [hasDocumentTypeSelection]="false"
                [metadata]="getMetadata(field)"
              ></app-detail-file-upload>
            </div>
            <div fxFlex="50">
              <div
                *ngIf="documents.length > 0"
                class="file-list-container"
                fxLayout="column"
                fxLayoutGap="16px"
              >
                <ng-container *ngFor="let document of documents; let i = index">
                  <div class="file-container" fxLayoutAlign="space-between center">
                    <button
                      mat-raised-button
                      fxFlex
                      class="fileButton"
                      fxLayoutAlign="begin center"
                      (click)="downloadFile(document, i)"
                      [loading]="fileDownloadState[i] === true"
                      [disabled]="fileDownloadState[i] === true"
                    >
                      <mat-icon>download</mat-icon>
                      {{ document.name }}
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- TYPED-FILES -->
      <ng-container *ngSwitchCase="'typed-files'">
        <h4 *ngIf="field.label" [innerText]="field.label"></h4>
        <div
          *ngIf="mandant != 'telekom' && mandant != 'brst'"
          fxFlex="100"
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutGap="16px"
        >
          <div fxFlex.gt-sm="50">
            <app-detail-file-upload
              *ngIf="isFileUploadAllowed()"
              [hasTaskSelection]="false"
              [hasTargetSystemSelection]="false"
              [hasDocumentTypeSelection]="true"
              [documentTypes]="field.options!"
              [typeIsRequired]="field.typeIsRequired || false"
              [metadata]="getMetadata(field)"
              [readonly]="readonly"
            ></app-detail-file-upload>
          </div>
          <div fxFlex.gt-sm="50">
            <div
              *ngIf="documents.length > 0"
              class="file-list-container"
              fxFlex="100"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <div
                fxLayoutGap="16px"
                fxAlignItems="center"
                *ngFor="let document of documents; let i = index"
              >
                <div fxFlex="50" class="file-container" fxLayoutAlign="space-between center">
                  <button
                    mat-raised-button
                    fxFlex
                    class="fileButton"
                    [title]="document.name"
                    fxLayoutAlign="begin center"
                    (click)="downloadFile(document, i)"
                    [loading]="fileDownloadState[i] === true"
                    [disabled]="fileDownloadState[i] === true"
                  >
                    <mat-icon>download</mat-icon>
                    {{ document.name}}
                  </button>
                </div>
                <div fxFlex="50" fxFlexAlign="center">
                  {{ document.changeDate | date: 'dd.MM.YYYY, HH:mm:ss' }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="mandant == 'telekom' || mandant == 'brst'">
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-upload
                *ngIf="isFileUploadAllowed()"
                [hasTaskSelection]="true"
                [hasTargetSystemSelection]="true"
                [hasDocumentTypeSelection]="true"
                [canSelectVisibility]="canSelectVisibility"
                [typeIsRequired]="field.typeIsRequired || false"
                [metadata]="getMetadata(field)"
                [tasks]="data?.fmTask"
                (uploadEvent)="documentsBufferedChanged($event)"
              ></app-detail-file-upload>
            </div>
          </div>
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-list-buffered
                *ngIf="documentsBuffered.length > 0"
                [documents]="documentsBuffered"
                (removeEvent)="documentsBufferedChanged($event)"
              >
              </app-detail-file-list-buffered>
            </div>
          </div>
          <div fxFlex="100" fxLayout.gt-sm="row" fxLayout="column">
            <div fxFlex.gt-sm="100">
              <app-detail-file-list
                *ngIf="documents.length > 0"
                [documents]="documents"
                [canSelectVisibility]="canSelectVisibility"
              >
              </app-detail-file-list>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- DATA GRID -->
      <ng-container *ngSwitchCase="'grid'">
        <div fxFlex="100">
          <mtx-grid
            *ngIf="field.columns && getTableData(field.key)"
            [toolbarTitle]="getLabel(field) || ''"
            [showToolbar]="true"
            [columns]="field.columns!"
            [data]="getTableData(field.key)"
            [showPaginator]="false"
            [columnMenuButtonText]="'grid.columns_shown' | translate"
            [columnMenuHeaderText]="'grid.columnMenuHeaderText' | translate"
          ></mtx-grid>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
