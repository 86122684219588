import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureToggleService } from '@shared/services/feature-toggle.service';
import { Params } from '@angular/router';

export interface MenuTag {
  color: string; // Background Color
  value: string;
}

export interface MenuChildrenItem {
  route: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  children?: MenuChildrenItem[];
  icon?: string;
  visibleOnDashboard?: boolean;
}

export interface Menu {
  route: string;
  name: string;
  type: 'link' | 'sub' | 'extLink' | 'extTabLink';
  icon: string;
  label?: MenuTag;
  badge?: MenuTag;
  visibleOnDashboard?: boolean;
  visibleOnHeader?: boolean;
  authOnly?: boolean;
  anonymOnly?: boolean;
  children?: MenuChildrenItem[];
  queryParams?: Params;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private rawMenu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  private menu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  private filteredMenu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  private userRoles: string[] = [];

  constructor(
    private msalAuthService: MsalService,
    private featureToggleService: FeatureToggleService
  ) {
    this.menu$.subscribe(items => {
      const filtered = items
        .filter(this.authFilter(this.userIsLoggedIn()))
        .filter(this.roleFilter(this.userRoles));
      this.filteredMenu$.next(filtered);
    });
  }

  private static isLeafItem(item: MenuChildrenItem): boolean {
    const cond0 = item.route === undefined;
    const cond1 = item.children === undefined;
    const cond2 = !cond1 && item.children?.length === 0;
    return cond0 || cond1 || cond2;
  }

  private static deepCopyJson(object: any): any {
    if (!object || object?.length === 0) {
      return [];
    }
    return JSON.parse(JSON.stringify(object));
  }

  private static jsonDeepEqual(objectA: any, objectB: any): boolean {
    return JSON.stringify(objectA) === JSON.stringify(objectB);
  }

  private static routeEqual(routeArr: Array<string>, realRouteArr: Array<string>): boolean {
    let realRouteArrTmp = MenuService.deepCopyJson(realRouteArr);
    realRouteArrTmp = realRouteArrTmp.filter(r => r !== '');
    return MenuService.jsonDeepEqual(routeArr, realRouteArrTmp);
  }

  public static recursiveMenuForTranslation(
    menu: Menu[] | MenuChildrenItem[],
    namespace: string
  ): Menu[] {
    const clonedMenu = MenuService.deepCopyJson(menu);

    clonedMenu.forEach(menuItem => {
      // eslint-disable-next-line no-param-reassign
      menuItem.name = `${namespace}.${menuItem.name}`;
      if (menuItem.children && menuItem.children.length > 0) {
        this.recursiveMenuForTranslation(menuItem.children, menuItem.name);
      }
    });

    return clonedMenu;
  }

  public getRawMenu() {
    return this.rawMenu$;
  }

  public getFilteredMenu$() {
    this.userRoles = this.msalAuthService.instance.getActiveAccount()?.idTokenClaims?.roles ?? [];

    // TODO: Remove this workaround when the roles are set correctly in the token
    const canUseLocationModule = this.featureToggleService.isEnabled('use-location-module');
    if (canUseLocationModule) {
      this.userRoles?.push('skyblue');
    }

    return this.filteredMenu$;
  }

  private authFilter(userLoggedIn: boolean) {
    return item => {
      if (typeof item.authOnly === 'undefined' && typeof item.anonymOnly === 'undefined') {
        return true;
      }
      return userLoggedIn ? item.authOnly || false : item.anonymOnly || false;
    };
  }

  private roleFilter(userRoles: string[] | undefined) {
    return item => {
      if (item.roles) {
        let hasRole = false;
        for (const roleName of item.roles) {
          hasRole = !!userRoles?.includes(roleName);
          if (hasRole) {
            break;
          }
        }
        return hasRole ? item : false;
      }

      return item;
    };
  }

  public set(menu: Menu[]): Observable<Menu[]> {
    this.menu$.next(menu.filter(this.authFilter(this.userIsLoggedIn())));
    return this.menu$.asObservable();
  }

  public setRawMenu(menu: Menu[]) {
    this.rawMenu$.next(menu);
  }

  private userIsLoggedIn(): boolean {
    const accounts = this.msalAuthService.instance.getAllAccounts();
    return accounts['0'] != null;
  }

  public add(menu: Menu) {
    const tmpMenu = this.menu$.value;
    tmpMenu.push(menu);
    this.menu$.next(tmpMenu);
  }

  public reset() {
    this.menu$.next([]);
  }

  public getMenuLevel(routeArr: string[]): string[] {
    let tmpArr: string[] = [];
    this.menu$.value.forEach(item => {
      /// / breadth-first-traverse -modified
      interface MenuLayer {
        item: Menu | MenuChildrenItem;
        parentNamePathList: string[];
        realRouteArr: string[];
      }

      let unhandledLayer: MenuLayer[] = [{ item, parentNamePathList: [], realRouteArr: [] }];
      while (unhandledLayer.length > 0) {
        let nextUnhandledLayer: MenuLayer[] = [];
        for (const ele of unhandledLayer) {
          const eachItem = ele.item;
          const currentNamePathList: string[] = MenuService.deepCopyJson(
            ele.parentNamePathList
          ).concat(eachItem.name);
          const currentRealRouteArr: string[] = MenuService.deepCopyJson(ele.realRouteArr).concat(
            eachItem.route
          );
          /// / compare the full Array
          /// / for expandable
          const cond = MenuService.routeEqual(routeArr, currentRealRouteArr);
          if (cond) {
            tmpArr = currentNamePathList;
            break;
          }

          const isLeafCond = MenuService.isLeafItem(eachItem);
          if (!isLeafCond) {
            const { children } = eachItem;
            const wrappedChildren = (children || []).map(child => ({
              item: child,
              parentNamePathList: currentNamePathList,
              realRouteArr: currentRealRouteArr,
            }));
            nextUnhandledLayer = nextUnhandledLayer.concat(wrappedChildren);
          }
        }
        unhandledLayer = nextUnhandledLayer;
      }
    });
    return tmpArr;
  }
}
