import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { appInitializerProviders } from '@core/initializers';

import { CoreModule, consoleLoggerProviders, loggingHttpInterceptorProviders } from '@core';
import { BREADCRUMB_CONFIG, globalErrorHandlerProviders, HelpService } from '@shared';
import { ServiceWorkerModule } from '@angular/service-worker';
// Import MSAL and MSAL browser libraries
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { GetProtectedResourcesMap, msalConfig, protectedResources } from '../../auth-config';
import { environment } from '../environments/environment'; // Angular CLI environment
import { ThemeModule } from './theme/theme.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services';

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true,
      easing: 'ease-in',
      onActivateTick: true,
      autoDismiss: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ThemeModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    RoutesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).

      // TODO(js): https://stackoverflow.com/questions/59683465/why-does-my-angular-pwa-fails-on-lighthouse-pwa-audit-while-everything-work-fine
      registrationStrategy: 'registerWhenStable:30000',
    }),
    OAuthModule.forRoot(),
    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: protectedResources.getIncident.scopes },
        // important for the cancelation of "reset password"
        loginFailedRoute: '/',
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: GetProtectedResourcesMap(),
      }
    ),
  ],
  providers: [
    loggingHttpInterceptorProviders,
    appInitializerProviders,
    consoleLoggerProviders,
    globalErrorHandlerProviders,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { showDelay: 250, hideDelay: 0, touchGestures: 'off' },
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    {
      provide: BREADCRUMB_CONFIG,
      useValue: {
        initiallyVisible: false,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    AuthenticationService,
    HelpService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
