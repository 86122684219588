import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private selector = 'globalLoader';

  private getElement() {
    return document.getElementById(this.selector);
  }

  hide() {
    const el = this.getElement();
    if (el) {
      el.addEventListener('transitionend', () => {
        el.className = 'global-loader-hidden';
      });

      if (!el.className.includes('global-loader-hidden')) {
        el.className += ' global-loader-fade-in';
      }
    }
  }

  showError(errormsg: string) {
    this.hide();
    const el = document.getElementById('startup-error');
    console.error(errormsg);
    if (el) {
      el.setAttribute('style', 'display:flex');
      const msg = `
        <h2>Error</h2>
        <p>Error when loading the configuration</p>
      `;
      el.innerHTML = msg;
    }
  }
}
