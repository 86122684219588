import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  ViewChild,
} from '@angular/core';
import { PlaceholderDirective } from '@shared/directives';

@Component({
  selector: 'app-view-base',
  templateUrl: './view.base.html',
  styleUrls: ['./view.base.scss'],
})
export class ViewBaseComponent implements AfterViewInit {
  dynamicComponent: any;

  @ViewChild(PlaceholderDirective) templateHost: PlaceholderDirective;

  private cd: ChangeDetectorRef;
  private componentFactoryResolver: ComponentFactoryResolver;

  constructor(@Inject(Injector) injector: Injector) {
    this.cd = injector.get(ChangeDetectorRef);
    this.componentFactoryResolver = injector.get(ComponentFactoryResolver);

    // this.dynamicComponent = config.dynamicComponent;
  }

  ngAfterViewInit() {
    this.showTemplate();
    this.cd.detectChanges();
  }

  private showTemplate() {
    const templateFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.dynamicComponent
    );
    const hostViewContainerRef = this.templateHost.viewContainerRef;
    hostViewContainerRef.createComponent(templateFactory);
  }
}
