// panel-wrapper.component.ts
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-card-wrapper',
  template: `
    <mat-card class="mat-elevation-z5 show-box-shadow">
      {{ to.visible }}
      <ng-container #fieldComponent></ng-container>
    </mat-card>
  `,
})
export class FormlyCardWrapperComponent extends FieldWrapper {}
