<form [formGroup]="form" [hidden]="filterVisible === false" >
  <div fxLayout="column">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="end start" fxLayoutGap="16px">
      <formly-form [form]="form" [fields]="filterFields" [model]="formModel" fxFlex></formly-form>
      <div [style.padding-top.px]="5" *ngIf="!triggerViaChange">
        <button type="submit" mat-raised-button color="accent" (click)="search()">
          <span [innerText]="'crud.applyFilter' | translate"></span>
        </button>
        <button type="button" mat-button color="warn" (click)="resetFilter()">
          <span [innerText]="'crud.resetFilter' | translate"></span>
        </button>
      </div>
    </div>
  </div>
</form>
