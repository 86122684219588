import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { rxSubscriptionContainerMixin } from '../../mixins/rx-subscription-container.mixin';

@Component({
  selector: 'app-element-not-found',
  templateUrl: './element-not-found.component.html',
  styleUrls: ['./element-not-found.component.scss'],
})
export class ElementNotFoundComponent extends rxSubscriptionContainerMixin() implements OnInit {
  public title: string;

  public message: string;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.pushSubscription(
      this.route.data.subscribe(params => {
        this.title = params.title;
        this.message = params.message;
      })
    );
  }
}
