import { Directive, Host } from '@angular/core';
import { NgForOf } from '@angular/common';

@Directive({
  selector: '[ngForTrackByIndex]',
})
export class NgForTrackByIndexDirective<T> {
  constructor(@Host() private ngFor: NgForOf<T>) {
    this.ngFor.ngForTrackBy = (index: number, _item: T) => index;
  }
}
