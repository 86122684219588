import { Injectable } from '@angular/core';

import { LogLevel, LoggerService, LogType } from './logger.service';

@Injectable()
export class ConsoleLogService extends LoggerService {
  public info(message: string, ...vargs: any[]): void {
    if (this.hasLevel(LogLevel.Info)) {
      this.log(LogType.Info, message, ...vargs);
    }
  }

  public warn(message: string, ...vargs: any[]): void {
    if (this.hasLevel(LogLevel.Warn)) {
      this.log(LogType.Warn, message, ...vargs);
    }
  }

  public debug(message: string, ...vargs: any[]): void {
    if (this.hasLevel(LogLevel.Debug)) {
      this.log(LogType.Debug, message, ...vargs);
    }
  }

  public error(message: string, ...vargs: any[]): void {
    if (this.hasLevel(LogLevel.Error)) {
      this.log(LogType.Error, message, ...vargs);
    }
  }

  private log(type: LogType, message: string, ...vargs: any[]) {
    if (!(type in console)) {
      return;
    }

    if (Array.isArray(vargs) && vargs.length > 0) {
      // eslint-disable-next-line no-console
      console[type](message, ...vargs);
    } else {
      // eslint-disable-next-line no-console
      console[type](message);
    }
  }
}

export const consoleLoggerProviders = [
  {
    provide: LoggerService,
    useClass: ConsoleLogService,
  },
];
