import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ArchObjAutocompleteViewModel,
  LocationClient,
  ResultWrapperOfArchObjAutocompleteViewModel,
} from '@api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-form-autocomplete-type-pasm',
  templateUrl: './pasm-formly-autocomplete-type.component.html',
  styleUrls: ['./pasm-formly-autocomplete-type.component.style.scss'],
})
export class PasmFormlyAutocompleteTypeComponent extends FieldType implements OnInit, OnDestroy {
  public currentSearchCtrl = new FormControl();

  public currentSearchCtrlWE = new FormControl();

  public searchBuildings = false;

  public search = '';

  public isVisibleInputWaiting = false;

  public isVisibleInputWaitingWE = false;

  public searchList: ArchObjAutocompleteViewModel[] = [];

  public searchListWE: ArchObjAutocompleteViewModel[] = [];

  public selectedResult: ArchObjAutocompleteViewModel;

  public selectedResultWE: ArchObjAutocompleteViewModel;

  public field: FormlyFieldConfig;

  public selectedText = '';

  public selectedTextWE = '';

  private subCurrentSearchCtrl: Subscription;

  private subCurrentSearchCtrlWE: Subscription;

  constructor(
    public translateService: TranslateService,
    private toastr: ToastrService,
    private locationClient: LocationClient
  ) {
    super();
    this.addSearchWE();
    this.addSearch();
  }

  ngOnInit(): void {
    this.searchBuildings = this.field.templateOptions?.searchBuildings ?? false;
  }

  ngOnDestroy(): void {
    this.subCurrentSearchCtrl.unsubscribe();
    this.subCurrentSearchCtrlWE.unsubscribe();
  }

  public selectSearchResult(option: any) {
    this.selectedResult = option;
    if (this.selectedResult?.archObjId) {
      this.value = this.selectedResult;
    }
    this.currentSearchCtrl.setValue(this.selectedText);
  }

  public selectSearchResultWE(option: any) {
    this.selectedResult = option;
    if (this.selectedResult?.archObjId) {
      this.value = this.selectedResult;
    }
    this.currentSearchCtrlWE.setValue(this.selectedTextWE);
  }

  public changeSearch(event: any) {
    this.selectedResult = event.option.value;
    if (this.selectedResult?.archObjId) {
      this.value = this.selectedResult;
    }
    this.currentSearchCtrl.setValue(this.selectedText);
  }

  public changeSearchWE(event: any) {
    this.selectedResultWE = event.option.value;
    if (this.selectedResultWE?.archObjId) {
      this.value = this.selectedResultWE;
    }
    this.currentSearchCtrlWE.setValue(this.selectedTextWE);
  }

  public deleteSelected() {
    this.value = undefined;
    this.currentSearchCtrl.setValue('');
    this.selectedResult = {};
  }

  public deleteSelectedWE() {
    this.value = undefined;
    this.currentSearchCtrlWE.setValue('');
    this.selectedResultWE = {};
  }

  private addSearch() {
    this.subCurrentSearchCtrl = this.currentSearchCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.searchList = [];
        }),
        filter(search => search.length > 2 && this.value !== search),
        switchMap((value: string) => {
          this.toastr.clear();
          this.isVisibleInputWaiting = true;
          this.selectedText = value;
          return this.locationClient.searchArchobjForAutocomplete(
            value,
            this.searchBuildings,
            false
          );
        })
      )
      .subscribe(
        (data: ResultWrapperOfArchObjAutocompleteViewModel) => {
          this.searchList = [];
          data.entites?.forEach(item => {
            this.searchList.push(item);
          });
          if (this.searchList.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedResult = this.searchList[0];
            this.value = this.selectedResult;
          }
          if (this.searchList.length === 0) {
            this.toastr.info(
              this.translateService.instant('grid.no_results'),
              this.translateService.instant('alert.info'),
              {
                disableTimeOut: true,
              }
            );
          }
          this.isVisibleInputWaiting = false;
        },
        (_error: any) => {
          this.toastr.error(
            this.translateService.instant('crud.details.error.failed_to_load_msg'),
            this.translateService.instant('alert.error'),
            {
              disableTimeOut: true,
            }
          );
          this.isVisibleInputWaiting = false;
          this.subCurrentSearchCtrl.unsubscribe();
          this.addSearch();
        }
      );
  }

  private addSearchWE() {
    this.subCurrentSearchCtrlWE = this.currentSearchCtrlWE.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.searchList = [];
        }),
        filter(search => search.length > 2 && this.value !== search && +this.value > -1),
        switchMap((value: string) => {
          this.toastr.clear();
          this.isVisibleInputWaitingWE = true;
          this.selectedTextWE = value;
          return this.locationClient.searchArchobjForAutocomplete(
            value,
            this.searchBuildings,
            true
          );
        })
      )
      .subscribe(
        (data: ResultWrapperOfArchObjAutocompleteViewModel) => {
          this.searchListWE = [];
          data.entites?.forEach(item => {
            this.searchListWE.push(item);
          });
          if (this.searchListWE.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedResultWE = this.searchListWE[0];
            this.value = this.selectedResult;
          }
          if (this.searchListWE.length === 0) {
            this.toastr.info(
              this.translateService.instant('grid.no_results'),
              this.translateService.instant('alert.info'),
              {
                disableTimeOut: true,
              }
            );
          }
          this.isVisibleInputWaitingWE = false;
        },
        (_error: any) => {
          this.toastr.error(
            this.translateService.instant('crud.details.error.failed_to_load_msg'),
            this.translateService.instant('alert.error'),
            {
              disableTimeOut: true,
            }
          );
          this.isVisibleInputWaitingWE = false;
          this.subCurrentSearchCtrlWE.unsubscribe();
          this.addSearchWE();
        }
      );
  }
}
