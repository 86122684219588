import { Component, Injector } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AddIncidentDTO, IncidentClient, IncidentType } from '@api';
import { FileService } from '@shared';
import { ToastrService } from 'ngx-toastr';
import { searchLocation } from '@strategies/telekom/ticket-anonym/ticket-create/field-configs/ticket-create-search-location.field-configs';
import { LocationFormlyFieldService } from '@routes/location/location-formly-field.service';
import { ActivatedRoute } from '@angular/router';
import { ContactSearchFormlyFieldService } from './contact-search-formly-field.service';
import { TicketCreateBaseComponent } from '../../../_base/ticket-anonym/ticket-create/ticket-create.base';
import {
  fileAttachments,
  targetLocation,
  auxAddress,
  concerningContact,
  requestingContact,
  extraConfirm,
  incidentInformation,
} from './field-configs';
import { editor } from './field-configs/ticket-create-editor.field-config';
import { emailcc } from './field-configs/ticket-create-email-cc.field-config';
import { searchContact } from './field-configs/ticket-create-search-contact.field-config';
import { FormModes, TicketCreateQueryParams } from './types';

@Component({
  selector: 'app-ticket-create-telekom',
  templateUrl: '../../../_base/ticket-anonym/ticket-create/ticket-create.base.html',
  styleUrls: ['../../../_base/ticket-anonym/ticket-create/ticket-create.base.scss'],
})
export class TicketCreateTelekomComponent extends TicketCreateBaseComponent {
  public title = '';

  public description = '';

  public formFields: Array<FormlyFieldConfig> = [];

  public isHelpdesk = false;

  constructor(
    injector: Injector,
    private incidentClient: IncidentClient,
    private fileService: FileService,
    public toastr: ToastrService,
    public locationFormlyFieldService: LocationFormlyFieldService,
    public contactSearchFormlyFieldService: ContactSearchFormlyFieldService,
    private route: ActivatedRoute
  ) {
    super(injector);

    let weNo: string | undefined;
    let contactSearchString: string | undefined;
    let locationSearchString: string | undefined;
    let mode: FormModes;
    this.route.queryParams.subscribe((params: Partial<TicketCreateQueryParams>) => {
      weNo = params.archObjId;
      mode = params.mode || 'default';

      if (mode === 'helpdesk') {
        this.isHelpdesk = true;
        this.title = this.translateService.instant('ticket.create.helpDeskTitle');
        this.description = this.translateService.instant('ticket.create.p__info_helpDeskCreate');
        this.showTicketCreateData = true;
        this.showExtraConfirmFormFields = false;

        this.formFields = [
          ...incidentInformation(this.translateService, mode),
          ...fileAttachments(this.translateService),
          ...searchLocation(
            this.translateService,
            this.locationFormlyFieldService,
            this.onSelectLocation,
            weNo
          ),
          ...searchContact(
            this.translateService,
            this.contactSearchFormlyFieldService,
            this.onSelectContact.bind(this),
            contactSearchString
          ),
          ...editor(
            this.translateService,
            this.contactSearchFormlyFieldService,
            this.onSelectEditor.bind(this),
            locationSearchString
          ),
          ...emailcc(this.translateService, this.onEmailChange.bind(this)),
        ];
      } else {
        this.title = this.translateService.instant('ticket.create.contactTitle');
        this.description = this.translateService.instant('ticket.create.p__info_contactCreate');
        this.showTicketCreateData = false;
        this.showExtraConfirmFormFields = true;

        this.formFields = [
          ...incidentInformation(this.translateService, mode),
          ...fileAttachments(this.translateService),
          ...targetLocation(this.translateService),
          ...auxAddress(this.translateService),
          ...concerningContact(this.translateService),
          ...requestingContact(this.translateService),
        ];
      }
    });
  }

  extraConfirmFormFields: Array<FormlyFieldConfig> = [
    {
      key: 'subscribeNewsletter',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      templateOptions: {
        label: this.translateService.instant('ticket.create.confirm.label__newsletter_checkbox'),
        appearance: 'standard',
      },
    },
    {
      key: 'confirmContent',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      templateOptions: {
        label: this.translateService.instant('ticket.create.confirm.label__confirm_checkbox'),
        appearance: 'standard',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
    {
      key: 'acceptDsgvo',
      type: 'checkbox',
      wrappers: ['checkbox'],
      defaultValue: false,
      templateOptions: {
        label: this.translateService.instant('ticket.create.confirm.label__dsgvo'),
        appearance: 'standard',
      },
      validators: {
        validation: ['requiredTrue'],
      },
    },
  ];

  private onSelectLocation(location: any) {
    console.log(location);
  }

  private onSelectContact(contact: any) {
    this.form.get('requestingContact.firstname')?.setValue(contact.firstName);
    this.form.get('requestingContact.lastname')?.setValue(contact.lastName);
    this.form.get('requestingContact.phonenumber')?.setValue(contact.phone);
    this.form.get('requestingContact.email')?.setValue(contact.email);
    this.form.get('requestingContact.confirmEmail')?.setValue(contact.email);
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  private onSelectEditor(editor: any) {
    this.form.get('editor')?.setValue(editor.email);
  }

  public onEmailChange(emails: string) {
    this.form.get('ccemail')?.setValue(emails);
  }

  protected canSubmit(): boolean {
    const MAX_FILE_SIZE = 20;
    if (this.form.value.attachments.length === 0) {
      return true;
    }
    const [isValid, message, title] = this.fileService.validateTotalFileSize(
      this.form.value.attachments,
      (accumulator, current) => accumulator + current.attachment.size,
      MAX_FILE_SIZE
    );
    if (!isValid) {
      this.toastr.error(message, title);
    }
    return isValid;
  }

  protected prepareSubmit = (data: any) => {
    const d = data;

    if (this.isHelpdesk) {
      d.incidentType = IncidentType.Kundenprozesse;
      d.incidentClass = 'Anfrage';
    } else if (d.incidentClass === 'Fläche extern vermieten') {
      d.incidentType = IncidentType.Mietmanagement;
    } else {
      d.incidentType = IncidentType.Eigentuemerbelange;
    }

    function setToNullIfEmpty(obj) {
      console.log(obj);
      const isNullish = Object.values(obj).every(value => !(value !== '' && value !== null));
      return isNullish ? null : obj;
    }

    const subscribeNewsletter = extraConfirm(this.translateService).find(
      f => f.key === 'subscribeNewsletter'
    )?.formControl?.value;

    if (d.concerningLocation && d.concerningLocation.powerOfDisposition === 'Eigentümer') {
      d.requestingContact = d.concerningContact;
    }

    d.requestingContact.subscribeNewsletter = subscribeNewsletter;

    d.powerOfDisposition = d.concerningLocation?.powerOfDisposition;

    const incidentDTO: AddIncidentDTO = d;

    // Run code if it's ticket creation under location
    if (data.targetLocation === undefined) {
      incidentDTO.incidentClass = '';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      incidentDTO.requestingContact!.address = {};
      incidentDTO.incidentDescription = '';
    } else {
      incidentDTO.targetLocation = setToNullIfEmpty(data.targetLocation);
    }

    return { incidentDTO };
  };

  public clientEndpoint = data => {
    return this.incidentClient.createIncident(data);
  };
}
