// https://github.com/ngx-formly/ngx-formly/issues/2558

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'select-tooltip',
  template: `
    <mat-form-field
      fxFlexFill
      [class]="formControl.errors && formControl.touched ? 'mat-form-field-invalid' : ''"
    >
      <mat-label [innerText]="to.label"></mat-label>
      <mat-select
        [formlyAttributes]="field"
        [(ngModel)]="value"
        (selectionChange)="selectionChange($event)"
      >
        <mat-option
          *ngFor="let item of to.options | formlySelectOptionsTooltip | async"
          [value]="item.value"
          [matTooltip]="item.tooltip!"
          >{{ item.label }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
})
export class FormlySelectTooltipComponent extends FieldType implements OnInit {
  public value = undefined;

  formControl: FormControl;

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((res: any) => {
      if (res === null) {
        this.value = undefined;
      }
    });
  }

  selectionChange(event) {
    this.formControl.patchValue(event.value);
  }
}
