<smart-card [backButton]="true" buttonColor="white" class="get-status-wrapper">
  <h2 [innerHtml]="'ticket.status.title' | translate"></h2>
  <p [innerHtml]="'ticket.status.p__info' | translate"></p>
  <form (ngSubmit)="submit($event)" [formGroup]="form">
    <formly-form [form]="form" [fields]="formFields" [model]="formModel"></formly-form>
    <div fxLayout="row" fxLayoutAlign="center center" class="get-status-button-container">
      <button
        type="submit"
        color="primary"
        fxFlex="66"
        mat-raised-button
      >
        <span class="customSendIconRight"></span>
        <span [innerHtml]="'form.submit' | translate"></span>&nbsp;
        <mat-icon class="materialSendIcon">send</mat-icon>
      </button>
    </div>
  </form>
</smart-card>
 
<spinner-overlay [show]="loading"></spinner-overlay>