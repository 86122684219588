  <div fxFlex="100" fxFlex.gt-sm="60"  fxFlexOffset.gt-sm="20" fxFlexFill fxLayoutAlign="center center" fxLayout="column" class="m-t-16">
  <button fxFlex="nogrow" mat-button color="primary" navigateBack>
    <mat-icon class="materialSendIcon">arrow_back</mat-icon>
    <span class="customSendIconLeft"></span>
    <span [innerHtml]="'back'|translate |uppercase"></span>
  </button>

    <mtx-alert type="danger" [elevation]="2" [dismissible]="false" class="m-t-16">
        <h3 [innerHtml]="title | translate "></h3>
      <strong [innerHtml]="message | translate"></strong>
    </mtx-alert>
  </div>
  