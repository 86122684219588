import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SettingsService } from '@core';
import { MatDatetimepickerType } from '@mat-datetimepicker/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
  selector: 'formly-field-daterange',
  templateUrl: './formly-daterange.component.html',
})
export class FormlyDateRangeComponent extends FieldType implements OnInit {
  formControl: FormControl;

  field: FormlyFieldConfig;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  templateOptions: any;

  lang: string;

  startDate: any;

  endDate: any;

  matDatepickerFilter: (any) => any;

  constructor(private dateAdapter: DateAdapter<Date>, private settingService: SettingsService) {
    super();
    this.lang = this.settingService.language.substring(0, 2);
    this.dateAdapter.setLocale(this.settingService.language.substring(0, 2));
  }

  ngOnInit() {
    this.templateOptions = this.field.templateOptions;

    if (this.templateOptions?.dateFilter) {
      this.matDatepickerFilter = this.templateOptions?.dateFilter;
    } else if (this.templateOptions?.allowedDates) {
      this.matDatepickerFilter = (d: moment.Moment) => {
        if (d) {
          const day = d.format('DD.MM.YYYY');
          return this.templateOptions?.allowedDates.includes(day);
        }
        return true;
      };
    }
    this.range.valueChanges.subscribe(res => {
      this.formControl.patchValue([res.start, res.end]);
    });
    this.form.valueChanges.subscribe((res: any) => {
      const stringKey = this.field.key?.toString() ?? 'test';
      if (res[stringKey] === 'delete') {
        this.startDate = undefined;
        this.endDate = undefined;
        this.formControl.patchValue([this.startDate, this.endDate]);
      }
    });
  }

  gettype() {
    return <MatDatetimepickerType>this.to.datetype || 'date';
  }

  emitChange(ev) {
    this.field.templateOptions?.change?.call(this.field, ev);
  }

  change(event: any) {
    console.log(event);
  }
}
