import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-HeaderItems',
  templateUrl: './HeaderItems.component.html',
  styleUrls: ['./HeaderItems.component.scss'],
})
export class HeaderItemsComponent {
  @Input()
  url: string;

  @Input()
  icon: string;

  @Input()
  translationKey: string;
}
