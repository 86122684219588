export class JsonWebToken {
  public accessToken: string;

  public role: string;

  public expires: number;

  public user: string;

  // Azure AAD details

  public name: string;

  public roles: string[];

  public uniqueName: string;

  public upn: string;

  public appid: string;

  public givenName: string;

  public familyName: string;
}
