<nav mat-tab-nav-bar class="sticky"   >
  <ng-container *ngFor="let menuItem of menus | async | filterArray: 'visibleOnHeader' : false : false">

      <!-- link -->
        <button
          *ngIf="menuItem.type === 'link'"
          mat-button
          [routerLink]="buildRoute([menuItem.route])"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
        >
          <mat-icon class="menu-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
          <span class="menu-name">{{ menuItem.name | translate }}</span>
          <span *ngIf="menuItem.label" class="badge menu-label bg-{{ menuItem.label.color }}">
            {{ menuItem.label.value }}
          </span>
          <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{ menuItem.badge.color }}">
            {{ menuItem.badge.value }}
          </span>
        </button>


      <!-- extLink -->
      <a *ngIf="menuItem.type === 'extLink'" mat-button [href]="menuItem.route">
        <mat-icon class="menu-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
        <span class="menu-name">{{ menuItem.name | translate }}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{ menuItem.label.color }}">
          {{ menuItem.label.value }}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{ menuItem.badge.color }}">
          {{ menuItem.badge.value }}
        </span>
      </a>

      <!-- extTabLink -->
      <a *ngIf="menuItem.type === 'extTabLink'" mat-button [href]="menuItem.route" target="_blank">
        <mat-icon class="menu-icon" *ngIf="menuItem.icon">{{ menuItem.icon }}</mat-icon>
        <span class="menu-name">{{ menuItem.name | translate }}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{ menuItem.label.color }}">
          {{ menuItem.label.value }}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{ menuItem.badge.color }}">
          {{ menuItem.badge.value }}
        </span>
        <mat-icon>launch</mat-icon>
      </a>

      <!-- sub drop down -->
      <button
        *ngIf="menuItem.type === 'sub'"
        mat-button
        [matMenuTriggerFor]="menulevel1"
        routerLinkActive="active"
      >
        <mat-icon class="menu-icon">{{ menuItem.icon }}</mat-icon>
        <span class="menu-name">{{ menuItem.name | translate }}</span>
        <span *ngIf="menuItem.label" class="badge menu-label bg-{{ menuItem.label.color }}">
          {{ menuItem.label.value }}
        </span>
        <span *ngIf="menuItem.badge" class="badge menu-badge bg-{{ menuItem.badge.color }}">
          {{ menuItem.badge.value }}
        </span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>

        <!-- menu level 1 -->
        <mat-menu #menulevel1="matMenu">
          <ng-container *ngFor="let childLvl1 of menuItem.children">

            <!-- link sub level 1 -->
            <button
              *ngIf="childLvl1.type === 'link'"
              mat-menu-item
              [routerLink]="buildRoute([menuItem.route, childLvl1.route])"
              routerLinkActive="active"
            >
              <mat-icon class="menu-icon">{{ childLvl1.icon }}</mat-icon>

              {{ childLvl1.name | translate }}
            </button>

            <!-- extLink sub level 1 -->
            <a *ngIf="childLvl1.type === 'extLink'" mat-menu-item [href]="childLvl1.route">
              <span class="menu-name">{{ childLvl1.name | translate }}</span>
              <mat-icon>launch</mat-icon>
            </a>

            <!-- extTabLink sub level 1 -->
            <a
              *ngIf="childLvl1.type === 'extTabLink'"
              mat-menu-item
              [href]="childLvl1.route"
              target="_blank"
            >
              <span class="menu-name">{{ childLvl1.name | translate }}</span>
              <mat-icon>launch</mat-icon>
            </a>

            <!-- drop down level 1 -->
            <button
              *ngIf="childLvl1.type === 'sub'"
              mat-menu-item
              [matMenuTriggerFor]="menulevel2"
              routerLinkActive="active"
            >
              {{ childLvl1.name | translate }}

              <!-- menu level 2 -->
              <mat-menu #menulevel2="matMenu">
                <ng-container *ngFor="let childLvl2 of childLvl1.children">

                  <!-- link level 2 -->
                  <button
                    *ngIf="childLvl2.type === 'link'"
                    mat-menu-item
                    [routerLink]="buildRoute([menuItem.route, childLvl1.route, childLvl2.route])"
                    routerLinkActive="active"
                  >
                    {{ childLvl2.name | translate }}
                  </button>

                  <!-- extLink level 2 -->
                  <a *ngIf="childLvl2.type === 'extLink'" mat-menu-item [href]="childLvl2.route">
                    <span class="menu-name">{{ childLvl2.name | translate }}</span>
                    <mat-icon>launch</mat-icon>
                  </a>

                  <!-- extTabLink level 2-->
                  <a
                    *ngIf="childLvl2.type === 'extTabLink'"
                    mat-menu-item
                    [href]="childLvl2.route"
                    target="_blank"
                  >
                    <span class="menu-name">{{ childLvl2.name | translate }}</span>
                    <mat-icon>launch</mat-icon>
                  </a>
                </ng-container>
              </mat-menu>
            </button>
          </ng-container>
        </mat-menu>
      </button>

  </ng-container>
</nav>
