export enum RoutePath {
  AUTHENTICATION = 'authentication',
  DASHBOARD = 'dashboard',
  DOCUMENTS = 'documents',
  FEEDBACK = 'feedback',
  NONE = '',
  OFFERING = 'offering',
  PROFILE = 'profile',
  LOCATION = 'location',
  PROPERTY_MANAGEMENT = 'propertymanagement',
  SELF_SERVICE = 'selfservice',
  TASKS = 'taskmodule',
  TICKET = 'ticket',
  INFO = 'info',
  ADMIN = 'admin',
  PERMISSION_REQUEST = 'permission-request',
}
