import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from '@env/environment';
import { JsonWebToken } from './json-web-token.model';
import { JwtParser } from './jwt-parser';

export enum AuthorityChoice {
  'b2c' = 1,
  'ciam' = 2,
}

@Injectable()
export class AuthenticationService {
  constructor(private oauthService: OAuthService) {
    console.log('creating AuthenticationService');
    this.ConfigureOAuth();
  }

  public authorityChoice = AuthorityChoice.ciam;

  public async ConfigureOAuth(authority?: AuthorityChoice): Promise<void> {
    if (!(environment.B2cOauthConfig && environment.CiamConfig)) {
      return;
    }

    if (!authority) {
      authority = this.authorityChoice;
    }
    let config;
    if (authority === AuthorityChoice.b2c) {
      config = this.getB2CConfig();
    }
    if (authority === AuthorityChoice.ciam) {
      config = this.getCiamConfig();
    }
    console.log('config', config);

    sessionStorage.setItem('logoutUrl', config.logoutUrl);

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.configure(config);
    this.oauthService.setStorage(sessionStorage);

    // this.oauthService.loadDiscoveryDocument(
    //   'https://websso-myiam-ite1-cp.app.t-internal.com/.well-known/openid-configuration'
    // );

    await this.LoadDiscoveryDocument();
    // await this.oauthService.loadDiscoveryDocument(
    //   'https://affinissmartus.b2clogin.com/affinissmartus.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1_signupsigninmail'
    // );
    // this.oauthService.tryLogin().then(async () => {
    //   if (this.oauthService.hasValidAccessToken()) {
    //     // Load UserProfile to get the additional claims
    //     // await this.oauthService.loadUserProfile();
    //     this.oauthService.getAccessToken();
    //     // this.router.navigateByUrl('/');
    //   } else {
    //     this.oauthService.initCodeFlow();
    //   }
    // });
    // // console.log('token @ configure time: ', this.oauthService.getAccessToken());
  }

  public async LoadDiscoveryDocument(
    authority?: AuthorityChoice
  ): Promise<OAuthSuccessEvent | void> {
    if (!authority) {
      authority = this.authorityChoice;
    }

    let ret: OAuthSuccessEvent;
    try {
      switch (authority) {
        case AuthorityChoice.b2c:
          if (!environment.B2cOauthConfig) {
            throw new Error('missing B2cOauthConfig.DiscoveryDocumentUrl in environment');
          }
          ret = await this.oauthService.loadDiscoveryDocument(
            environment.B2cOauthConfig.DiscoveryDocumentUrl
          );
          break;
        case AuthorityChoice.ciam:
          if (!environment.CiamConfig) {
            throw new Error('missing CiamConfig.DiscoveryDocumentUrl in environment');
          }
          ret = await this.oauthService.loadDiscoveryDocument(
            environment.CiamConfig.DiscoveryDocumentUrl // 'https://websso-myiam-ite1-cp.app.t-internal.com/.well-known/openid-configuration'
          );
          break;
        default:
          throw new Error('given authority is not defined.');
          break;
      }
    } catch (e: any) {
      return Promise.resolve();
    }
    return ret;
  }

  private get token(): JsonWebToken {
    const token = sessionStorage.getItem('access_token');
    const jwt = token ? this.parseToken(token) : new JsonWebToken();
    return jwt;
  }

  private set token(token: JsonWebToken) {
    sessionStorage.setItem('access_token', token.accessToken);
  }

  public setToken(token: string) {
    try {
      this.token = this.parseToken(token);
    } catch (e: any) {
      console.log(e);
    }
  }

  public refreshInterval() {
    if (this.oauthService.getAccessTokenExpiration()) {
      setTimeout(() => {
        this.oauthService.refreshToken();
      }, 10000);
    }
  }

  public getToken(): JsonWebToken {
    return this.token;
  }

  public isTokenExpired() {
    if (this.token) {
      return this.token.expires <= Date.now();
    }
    return true;
  }

  public isAuthenticated() {
    const token = sessionStorage.getItem('access_token');
    if (!this.token || this.isTokenExpired() || !token) {
      return false;
    }
    return true;
  }

  public deleteAll() {
    this.token = new JsonWebToken();
    sessionStorage.removeItem('access_token');
    const readToken = sessionStorage.getItem('access_token');
    console.log('deleted token, readtoken:', readToken);
  }

  public async doLogout() {
    this.deleteAll();
    this.oauthService.logOut(true);
    sessionStorage.removeItem('logoutUrl');
  }

  private parseToken(token: string): JsonWebToken {
    const rawToken = JwtParser.decodeToken(token);
    console.log('parsing raw token:', rawToken);

    const jwt = new JsonWebToken();
    jwt.accessToken = token;
    jwt.expires = rawToken.exp * 1000;
    jwt.role = rawToken.Role;
    jwt.name = rawToken.name;

    jwt.givenName = rawToken.given_name;
    jwt.familyName = rawToken.family_name;

    return jwt;
  }

  private getCiamConfig(): AuthConfig {
    if (!environment.CiamConfig) {
      throw new Error('missing CIAM config in environment');
    }
    const config = new AuthConfig();

    config.loginUrl = environment.CiamConfig.LoginUrl;
    // 'https://websso-myiam-ite1-cp.app.t-internal.com/mga/sps/oauth/oauth20/authorize';
    config.clientId = environment.CiamConfig.ClientId; // 'TzXNSX4nSHYERQTgv2Vi-cptest';
    config.issuer = environment.CiamConfig.Issuer; // 'https://websso-myiam-ite1-cp.app.t-internal.com';
    config.tokenEndpoint = environment.CiamConfig.TokenEndpoint; // 'https://websso-myiam-ite1-cp.app.t-internal.com/mga/sps/oauth/oauth20/token';
    config.redirectUri = `${window.location.origin}/authentication/callback`;
    config.logoutUrl = window.location.origin;
    config.disableAtHashCheck = true;
    config.responseType = 'code';
    config.scope = environment.CiamConfig.Scope; // 'openid profile offline_access';
    config.oidc = true;
    config.strictDiscoveryDocumentValidation = false;

    return config;
  }

  private getB2CConfig(): AuthConfig {
    if (!environment.B2cOauthConfig) {
      throw new Error('missing B2C config in environment');
    }
    const config = new AuthConfig();

    config.loginUrl = environment.B2cOauthConfig.LoginUrl;
    config.clientId = environment.B2cOauthConfig.ClientId;
    config.issuer = environment.B2cOauthConfig.Issuer;
    config.tokenEndpoint = environment.B2cOauthConfig.TokenEndpoint;
    config.redirectUri = `${window.location.origin}/authentication/callback`;
    config.logoutUrl = window.location.origin;
    config.disableAtHashCheck = true;
    config.responseType = 'code';
    config.scope = environment.B2cOauthConfig.Scope;
    config.oidc = true;
    config.strictDiscoveryDocumentValidation = false;
    config.requireHttps = false;

    config.showDebugInformation = true;

    return config;
  }
}
