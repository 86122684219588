<smart-card [offset]="2" [backButton]="true" style="overflow: hidden">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
      <h3 [innerText]="config.title | translate"></h3>
      <div fxFlex="auto" [style.padding-top.px]="8">
        <app-table-filter
          fxFlexAlign="end"
          *ngIf="viewOptionsFields"
          [filterFields]="viewOptionsFields"
          [triggerViaChange]="true"
          [filterVisible]="true"
          (filterEvent)="updateViewOptions($event)"
        >
        </app-table-filter>
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="end center"
        fxLayoutAlign.lt-sm="begin begin"
        fxLayoutGap="10px"
      >
        <button
          *ngIf="filterFields"
          fxFlexOrder="0"
          fxFlexOrder.lt-sm="5"
          mat-button
          color="primary"
          (click)="toggleFilter()"
        >
          <mat-icon>filter_alt</mat-icon><span [innerText]="'crud.filter' | translate"></span>
        </button>
        <button mat-raised-button color="primary" (click)="refresh()">
          <mat-icon>sync</mat-icon><span [innerText]="'crud.refresh' | translate"></span>
        </button>
        <button
          *ngIf="clickAdd && config.addButton.label"
          [disabled]="config.addButton.disabled"
          mat-raised-button
          color="accent"
          (click)="clickAddWrapper()"
        >
          <mat-icon>add</mat-icon><span [innerText]="config.addButton.label | translate"></span>
        </button>
      </div>
    </div>

    <app-table-filter
      *ngIf="filterFields"
      [filterFields]="filterFields"
      [filterVisible]="config.grid.filter.filterVisible"
      (filterEvent)="updateFilter($event)"
      (searchEvent)="refreshFromFilter()"
      (resetFilterEvent)="resetFilter()"
    >
    </app-table-filter>
    <ng-container>
      <mtx-grid
        [columns]="columns"
        [data]="data.items"
        [length]="data.totalCount"
        [pageOnFront]="false"
        [sortOnFront]="config.grid.filter.sortOnFront"
        [loading]="isLoading"
        [rowStriped]="true"
        [noResultText]="'grid.no_results' | translate"
        (rowClick)="clickRowWrapper($event)"
        [rowHover]="false"
        [cellSelectable]="false"
        [pageSizeOptions]="[5, 10, 25, 50, 100]"
        (sortChange)="sortChange($event)"
        (page)="getNextPage($event)"
        [pageSize]="config.grid.paging.pageSize"
        [pageIndex]="config.grid.paging.pageIndex"
        [showPaginator]="config.grid.paging.showPaginator"
        [columnMenuButtonText]="'grid.columns_shown' | translate"
        [columnMenuHeaderText]="'grid.columnMenuHeaderText' | translate"
        [columnHideable]="true"
        [columnHideableChecked]="'show'"
        [columnMovable]="true"
        [showColumnMenuFooter]="true"
        [columnMenuFooterTemplate]="menuFooter"
        [showToolbar]="true"
        [showColumnMenuHeader]="true"
        [toolbarTemplate]="toolbarTpl"
        [showSidebar]="true"
        [columnResizable]="true"
        [expandable]="fieldsInExpansionTemplate.length > 0"
        (expansionChange)="getTasks($event)"
        [expansionTemplate]="expansionTemplate"
        [noResultText]="'grid.no_results' | translate"
      >
      </mtx-grid>
    </ng-container>
  </div>
</smart-card>
<ng-template #menuFooter> </ng-template>

<ng-template #toolbarTpl> </ng-template>

<ng-template #expansionTemplate let-row>
  <div>
    <div *ngFor="let task of row.fmTask">
      <!------Tickets mit aktiven Aufgaben-------------(click)="clickExpandedRow(task)"------------------------------------------------>
      <div
        *ngIf="this.form.value.ticketTaskSelection === 'TicketsWithActiveTasks'"
        class="expansion-item mat-cell"
        style="display: flex"
      >
        <div
          *ngIf="task.isActive && task.status !== 'Abgeschlossen' && task.status !== 'Storniert'"
        >
          <ng-container>
            <p
              *ngIf="!hasReadAllRole() && task.organisationId !== user.organisationId; else first"
              style="color: gray; font-style: italic"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-container>
          <ng-template #first>
            <p
              *ngIf="
                task.status === 'Abgeschlossen';
                else second || task.status === 'Storniert; else second'
              "
              style="color: gray"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-template>
          <ng-template #second>
            <p
              *ngIf="task.status !== 'Abgeschlossen' && task.status !== 'Storniert'"
              style="color: black"
              (click)="clickExpandedRow(task)"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-template>
        </div>
      </div>
      <!------Alle Tickets------------------------------------------------------------->
      <div
        *ngIf="this.form.value.ticketTaskSelection === 'All'"
        class="expansion-item mat-cell"
        style="display: flex"
      >
          <ng-container>
            <p
              *ngIf="!hasReadAllRole() && task.organisationId !== user.organisationId; else first"
              style="color: gray; font-style: italic"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-container>
          <ng-template #first>
            <p
              *ngIf="
                task.status === 'Abgeschlossen' ||
                  (task.status === 'Storniert' && task.organisationId === user.organisationId);
                else second
              "
              style="color: black; font-style: italic"
              (click)="clickExpandedRow(task)"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-template>
          <ng-template #second>
            <p *ngIf="task.status === 'Abgeschlossen'; else third" style="color: gray">
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-template>
          <ng-template #third>
            <p
              *ngIf="task.status !== 'Abgeschlossen'"
              style="color: black"
              (click)="clickExpandedRow(task)"
            >
              {{ task.subject }} ({{ task.status }})
            </p>
          </ng-template>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <!-- {{ row | json }} -->
</ng-template>
