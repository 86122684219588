/* eslint-disable no-case-declarations */
import { Component, ComponentFactoryResolver } from '@angular/core';
import { Mandant, MandantConfigService } from '@strategies/mandant-config.service';
import { SpinnerOverlayTelekomComponent } from '@strategies/telekom/_components/spinner-overlay';
import { BaseDynamicContainerComponent } from '@strategies/_containers/base-dynamic.container';
import { SpinnerOverlayDefaultComponent } from '@strategies/_default/spinner-overlay';

@Component({
  selector: 'spinner-overlay',
  template: '<ng-template #dynamic></ng-template>',
  styleUrls: [],
})
export class SpinnerOverlayContainerComponent extends BaseDynamicContainerComponent {
  constructor(
    public mandantConfigService: MandantConfigService,
    public cfr: ComponentFactoryResolver
  ) {
    super(mandantConfigService, cfr);
  }

  protected override getComponent() {
    switch (this.mandantConfigService.mandant.name) {
      case Mandant.telekom:
        const componentFactoryV1 = this.cfr.resolveComponentFactory(SpinnerOverlayTelekomComponent);
        return this.viewContainerRef.createComponent(componentFactoryV1);
      default:
        const factory = this.cfr.resolveComponentFactory(SpinnerOverlayDefaultComponent);
        return this.viewContainerRef.createComponent(factory);
    }
  }
}
