<mat-form-field
  fxFlexFill
  [class]="formControl.errors && formControl.touched ? 'mat-form-field-invalid' : ''"
>
  <mat-label [innerText]="to.label"></mat-label>
  <input
    id="ctrlSingleSearch"
    matInput
    type="text"
    [formlyAttributes]="field"
    [formControl]="currentSearchCtrl"
    [matAutocomplete]="search"
  />

  <mat-autocomplete
    (optionSelected)="changeSearch($event)"
    #search="matAutocomplete"
  >
    <mat-option *ngIf="searchList.length > 0" disabled="true">
      <div class="row">
        <div *ngFor="let definition of columnDefinitions" [class]="definition.classes">
          {{ translateService.instant(definition.labelTranslationKey) }}
        </div>
      </div>
    </mat-option>
    <mat-option
      (click)="selectValue(option)"
      *ngFor="let option of searchList"
      [value]="option"
    >
      <div class="row">
        <div *ngFor="let definition of columnDefinitions" [class]="definition.classes">
          {{ getOptionLabelFormatted(option, definition) }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
  <button mat-button matSuffix *ngIf="isVisibleInputWaiting" mat-icon-button>
    <mat-spinner [diameter]="20"></mat-spinner>
  </button>
</mat-form-field>
<div class="preview">

  <div class="row" *ngFor="let definition of columnDefinitions">
    <div class="col-3">{{ this.translateService.instant(definition.labelTranslationKey) }}</div>
    <div class="col-7">{{getOptionLabelFormatted(selectedResult, definition) }}</div>
  </div>

  <div class="delete" *ngIf="selectedResult" (click)="deleteSelected()">
    <mat-icon>delete</mat-icon>
  </div>
</div>
