import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  isDevMode,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '@shared/components/dialog';
import { PlaceholderDirective } from '@shared/directives';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IDetailFields } from './detail-fields/models/detail-fields.model';
import { IDetailTemplate } from './detail-fields/models/detail-template.model';
import { IDetailsConfig } from './models/details.base.model';

@Component({
  selector: 'app-details-base',
  templateUrl: './details.base.html',
  styleUrls: ['./details.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsBaseComponent implements OnInit, AfterViewInit {
  config: IDetailsConfig = <IDetailsConfig>{
    title: '',
    showBackButton: true,
    showFileUpload: true,
  };

  getItem: () => Observable<any>;

  transformData?: (any: any) => any;

  detailFields: Array<IDetailFields> | undefined;

  @ViewChild(PlaceholderDirective) templateHost: PlaceholderDirective;

  data: any;

  isLoading: boolean;

  isDevMode: boolean;

  dynamicComponent?: any;

  processEvent?: (data: any, event: any) => any;

  // If this component is called from the creation page, the entry may not yet exist.
  // In this case, the page should be refreshed automatically after a few seconds.
  waiting = false;

  private dialog: MatDialog;

  private toastr: ToastrService;

  private cd: ChangeDetectorRef;

  private componentFactoryResolver: ComponentFactoryResolver;

  protected router: Router;

  protected route: ActivatedRoute;

  protected translateService: TranslateService;

  constructor(@Inject(Injector) injector: Injector) {
    this.dialog = injector.get(MatDialog);
    this.toastr = injector.get(ToastrService);
    this.cd = injector.get(ChangeDetectorRef);
    this.componentFactoryResolver = injector.get(ComponentFactoryResolver);

    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.translateService = injector.get(TranslateService);

    this.isDevMode = isDevMode();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(p => {
      this.waiting = p.w || false;
    });
  }

  ngAfterViewInit(): void {
    this.loadItem();
  }

  protected configure(config: IDetailsConfig) {
    return { ...this.config, ...config };
  }

  protected translate(key: string) {
    return this.translateService.instant(`${this.config.translatePath}.${key}`);
  }

  private showErrorToast() {
    this.toastr.error(
      this.translateService.instant('crud.details.error.failed_to_load_msg'),
      this.translateService.instant('crud.details.error.failed_to_load_title'),
      { timeOut: 0 }
    );
  }

  private loadItem() {
    this.isLoading = true;
    this.cd.detectChanges();

    this.getItem()
      .pipe(take(1))
      .subscribe(
        data => {
          this.isLoading = false;

          if (!data) {
            this.showErrorToast();
            this.cd.detectChanges();
            return;
          }

          const fmDocumentField = this.detailFields?.find(
            d => d.key != null && d.key === 'fmDocument'
          );
          if (fmDocumentField) {
            fmDocumentField.fileMetadata = fmDocumentField.fileMetadata ?? [];

            if (data.incidentId) {
              fmDocumentField.fileMetadata.push({
                attributeName: 'incidentId',
                attributeValue: data.incidentId || 'error no incidentId',
              });
            }

            if (data.taskId) {
              fmDocumentField.fileMetadata.push({
                attributeName: 'taskId',
                attributeValue: data.taskId || 'error no taskId',
              });

              fmDocumentField.fileMetadata.push({
                attributeName: 'defaultDocumentTypeId',
                attributeValue: data.configurationTask?.defaultDocumentTypeId,
              });
            }
          }

          if (this.transformData) {
            this.data = this.transformData(data);
          } else {
            this.data = data;
          }
          this.showTemplate();
          this.cd.detectChanges();
        },
        error => {
          this.isLoading = false;
          if (this.waiting) {
            this.retryLogic();
          } else {
            this.showErrorToast();
            this.cd.detectChanges();
            console.log(error);
          }
        }
      );
  }

  private retryLogic() {
    const waitDialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: {
        type: 'okDismissButton',
        title: this.translateService.instant('crud.reload.title'),
        message: this.translateService.instant('crud.reload.message'),
        okButtonText: this.translateService.instant('crud.reload.btnReload'),
        dismissButtonText: this.translateService.instant('crud.reload.btnReturn'),
      },
    });

    waitDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          setTimeout(() => {
            this.loadItem();
            waitDialogRef.close(true);
          }, 5000);
        } else {
          this.router.navigate(['../../'], { relativeTo: this.route });
        }
      });
  }

  showTemplate() {
    if (this.dynamicComponent) {
      const templateFactory =
        this.componentFactoryResolver.resolveComponentFactory<IDetailTemplate>(
          this.dynamicComponent
        );
      const hostViewContainerRef = this.templateHost.viewContainerRef;
      const templateRef = hostViewContainerRef.createComponent(templateFactory);
      templateRef.instance.data = this.data;
      if (templateRef.instance.outputEvent) {
        templateRef.instance.outputEvent.subscribe(event => {
          if (this.processEvent !== undefined) {
            this.data = this.processEvent(this.data, event);
          }
        });
      }
    }
  }
}
