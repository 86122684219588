import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { StartupService } from '@core/bootstrap/startup.service';
import {FeatureToggleService} from "@shared/services/feature-toggle.service";

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  constructor(
    private router: Router,
    private startupService: StartupService,
    private msalAuthService: MsalService,
    private featureToggleService: FeatureToggleService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isAuthorized = true;

    const userRoles = this.msalAuthService.instance.getActiveAccount()?.idTokenClaims?.roles;

    // TODO: Remove this workaround when the roles are set correctly in the token
    if (this.featureToggleService.isEnabled('use-location-module')) {
      userRoles?.push('skyblue');
    }

    Object.keys(this.startupService.menuJson).forEach((moduleName: string) => {
      if (state.url.startsWith(`/${moduleName}`)) {
        this.startupService.menuJson[moduleName].forEach((entry: any) => {
          if (state.url === `/${entry.route}` && entry.roles?.length) {
            isAuthorized = false;
            for (const roleName of entry.roles) {
              isAuthorized = !!userRoles?.includes(roleName);
              if (isAuthorized) {
                break;
              }
            }
          }
        });
      }
    });

    if (!isAuthorized) {
      this.router.navigate(['']);
    }

    return isAuthorized;
  }
}
