import { Component, Input, OnInit } from '@angular/core';
import { DynamicThemeService } from '@core/bootstrap/dynamic-theme.service';

@Component({
  selector: 'app-background-image',
  template: '',
  styleUrls: [],
})
export class HeaderBannerComponent implements OnInit {
  @Input() public imageUrl: any = './assets/images/polygon.svg';

  @Input() public applyColoringToBg = true;

  public backgroundColor: string;

  constructor(dynamicTheme: DynamicThemeService) {
    dynamicTheme.backgroundUrl$.subscribe(bgUrl => {
      this.imageUrl = bgUrl;
      this.setBackground();
    });
    dynamicTheme.backgroundColor$.subscribe(color => {
      this.backgroundColor = color;
      this.setBackground();
    });
  }

  ngOnInit() {
    this.setBackground();
  }

  setBackground() {
    let backgroundImageStyle = `url(${this.imageUrl})`;
    if (this.applyColoringToBg) {
      backgroundImageStyle = `linear-gradient(${this.backgroundColor},${this.backgroundColor}), url(${this.imageUrl}),  linear-gradient(transparent,white)`;
    }
    const theStyleString = `
        background-size: cover !important;
        background-image: ${backgroundImageStyle};
        background-blend-mode: color, screen;
        background-attachment: fixed;
        background-position: center top;
        `;

    document.body.setAttribute('style', theStyleString);
    document.querySelector('mat-sidenav-content')?.setAttribute('style', theStyleString);
  }
}
