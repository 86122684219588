import { Component } from '@angular/core';
import { MandantConfigService } from '@strategies/mandant-config.service';

@Component({
  selector: 'app-layout-container-colored-backgroundimage',
  templateUrl: '../../_base/layout/layout-container.component.html',
})
export class LayoutContainerColoredBackgroundimageComponent {
  public imageUrl: any = this.mandantService.mandant.backgroundUrl;

  public applyColoringToBg = true;

  constructor(private mandantService: MandantConfigService) {}
}
