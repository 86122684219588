import { Component, ChangeDetectionStrategy, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmploymentClient } from '@api';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export interface DialogData {
  currValue: string;
}
@Component({
  selector: 'app-search-help-dialog',
  templateUrl: './search-help-dialog.component.html',
  styleUrls: ['./search-help-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchHelpDialogComponent implements OnInit {
  myControl = new FormControl('');

  options: string[] = [];

  selectedOptions: string[] = [];

  filteredOptions: Observable<string[]>;

  resultString: string;

  currentValue: string;

  emailExists: boolean;
re
  emailInOptions: boolean;

  constructor(
    public dialogRef: MatDialogRef<SearchHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private employmentClient: EmploymentClient,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.emailExists = false;
    this.emailInOptions = false;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      debounceTime(1000),
      startWith(''),
      map(value => this.filter(value || ''))
    );
    this.currentValue = this.data.currValue || '';
    this.options.sort();
  }

  addOption() {
    if (this.myControl.value !== '' && this.emailAlreadyExists(this.myControl.value)) {
      this.emailExists = true;
      return;
    }

    const index = this.options.findIndex(x => x === this.myControl.value);
    if (index !== -1) {
      this.options.splice(index, 1);
    }

    if (this.myControl.value.length > 0) {
      this.selectedOptions.push(this.myControl.value);
    }

    this.myControl.reset();
  }

  saveSelectedOptions(selectedOptions: string[]) {
    if (
      this.currentValue.substring(this.currentValue.length - 1, this.currentValue.length) === ';'
    ) {
      this.resultString = this.currentValue ? `${this.currentValue}` : '';
    } else {
      this.resultString = this.currentValue ? `${this.currentValue};` : '';
    }

    this.resultString += selectedOptions.join(';');
    this.dialogRef.close(this.resultString);
  }

  deleteSelectedOption(option: string) {
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
      this.options = [...this.options, option];
      this.options.sort();
    }
    this.myControl.setValue(this.myControl.value);
  }

  isEmailInOptions() {
    this.emailInOptions = false;
    if (this.options.includes(this.myControl.value)) {
      this.emailInOptions = true;
    }
  }

  closeDialog() {
    this.dialogRef.close(this.currentValue);
  }

  private emailAlreadyExists(chosenEmail: string): Boolean {
    return this.currentValue.includes(chosenEmail);
  }

  private filter(value: string): string[] {
    let employees: string[] = [];

    if(value.length < 3) return employees;

    const filterValue = value.toLowerCase();
    
    this.employmentClient.searchEmployee(filterValue).subscribe(
      data => { 
        var result = data as any;
        result.entites.forEach(element => {
          employees.push(element.email);
          this.options.push(element.email);
          this.changeDetector.detectChanges();
        });
      },
    );

    return employees;
  }
}
