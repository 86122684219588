import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormModes } from '../types';

export function incidentInformation(
  translateService: TranslateService,
  mode: FormModes
): Array<FormlyFieldConfig> {
  if (mode === 'helpdesk') {
    return [
      {
        fieldGroup: [
          {
            type: 'input',
            key: 'incidentTitle',
            templateOptions: {
              label: translateService.instant('ticket.create.label__short_title'),
              required: true,
              visibleInConfirm: true,
              maxLength: 200,
            },
          },
        ],
      },
      {
        wrappers: ['form-field', 'count-chars'],
        key: 'incidentDescription',
        type: 'textarea',
        templateOptions: {
          label: translateService.instant('ticket.create.label__short_description'),
          visibleInConfirm: true,
          autosize: true,
          autosizeMinRows: 4,
          maxLength: 2000,
        },
      },
    ];
  }

  return [
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'incidentTitle',
          templateOptions: {
            label: translateService.instant('ticket.create.label__title'),
            required: true,
            visibleInConfirm: true,
            maxLength: 200,
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          type: 'select-tooltip',
          key: 'incidentClass',
          templateOptions: {
            visibleInConfirm: true,
            label: translateService.instant('ticket.create.label__incidentcategory'),
            required: true,
            options: [
              {
                value: 'Baulast',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.constructionLoad.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.constructionLoad.tooltip'
                ),
              },
              {
                value: 'Dienstbarkeit',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.easement.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.easement.tooltip'
                ),
              },
              {
                value: 'Kaufanfrage',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.purchaseEnquiry.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.purchaseEnquiry.tooltip'
                ),
              },
              {
                value: 'Löschungsbewilligung',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.deletionPermit.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.deletionPermit.tooltip'
                ),
              },
              {
                value: 'Öffentlich rechtliches Verfahren',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.publicLawProcedure.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.publicLawProcedure.tooltip'
                ),
              },
              {
                value: 'Fläche extern vermieten',
                label: translateService.instant(
                  'ticket.create.incidentClass.options.rentSpaceExternally.label'
                ),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.rentSpaceExternally.tooltip'
                ),
              },
              {
                value: 'Sonstiges',
                label: translateService.instant('ticket.create.incidentClass.options.other.label'),
                tooltip: translateService.instant(
                  'ticket.create.incidentClass.options.other.tooltip'
                ),
              },
            ],
          },
        },
      ],
    },
    {
      hideExpression: 'model.incidentClass!=="Sonstiges"',
      fieldGroup: [
        {
          type: 'input',
          key: 'incidentClassMiscText',
          templateOptions: {
            label: translateService.instant('ticket.create.label__incidentclassOther'),
            required: true,
            visibleInConfirm: true,
            maxLength: 200,
          },
          expressionProperties: {
            'templateOptions.required': 'model.incidentClass==="Sonstiges"',
          },
        },
      ],
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'incidentDescription',
      type: 'textarea',
      templateOptions: {
        label: translateService.instant('ticket.create.label__description'),
        visibleInConfirm: true,
        required: true,
        autosize: true,
        autosizeMinRows: 4,
        maxLength: 2000,
      },
    },
    {
      type: 'chapter',
      templateOptions: {
        chapterText: translateService.instant('ticket.create.p__info_contactInformation'),
        visibleInConfirm: false,
      },
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'contactInformation',
      type: 'textarea',
      templateOptions: {
        label: translateService.instant('ticket.create.label__contactInformation'),
        autosize: true,
        autosizeMinRows: 3,
        maxLength: 2000,
      },
    },
  ];
}
