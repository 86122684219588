export const federalStatesDe = [
  {
    value: 'Baden-Württemberg',
    name_de: 'Baden-Württemberg',
    name_en: 'Baden-Wuerttemberg',
  },
  {
    value: 'Bayern',
    name_de: 'Bayern',
    name_en: 'Bavaria',
  },
  {
    value: 'Berlin',
    name_de: 'Berlin',
    name_en: 'Berlin',
  },
  {
    value: 'Brandenburg',
    name_de: 'Brandenburg',
    name_en: 'Brandenburg',
  },
  {
    value: 'Bremen',
    name_de: 'Bremen',
    name_en: 'Bremen',
  },
  {
    value: 'Hamburg',
    name_de: 'Hamburg',
    name_en: 'Hamburg',
  },
  {
    value: 'Hessen',
    name_de: 'Hessen',
    name_en: 'Hesse',
  },
  {
    value: 'Mecklenburg-Vorpommern',
    name_de: 'Mecklenburg-Vorpommern',
    name_en: 'Mecklenburg-Western Pomerania',
  },
  {
    value: 'Niedersachsen',
    name_de: 'Niedersachsen',
    name_en: 'Lower Saxony',
  },
  {
    value: 'Nordrhein-Westfalen',
    name_de: 'Nordrhein-Westfalen',
    name_en: 'North Rhine-Westphalia',
  },
  {
    value: 'Rheinland-Pfalz',
    name_de: 'Rheinland-Pfalz',
    name_en: 'Rhineland-Palatinate',
  },
  {
    value: 'Saarland',
    name_de: 'Saarland',
    name_en: 'Saarland',
  },
  {
    value: 'Sachsen-Anhalt',
    name_de: 'Sachsen-Anhalt',
    name_en: 'Saxony-Anhalt',
  },
  {
    value: 'Sachsen',
    name_de: 'Sachsen',
    name_en: 'Saxony',
  },
  {
    value: 'Schleswig-Holstein',
    name_de: 'Schleswig-Holstein',
    name_en: 'Schleswig-Holstein',
  },
  {
    value: 'Thüringen',
    name_de: 'Thüringen',
    name_en: 'Thuringia',
  },
];
