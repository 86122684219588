// panel-wrapper.component.ts
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `<div style="margin: 15px 0">
    <ng-container #fieldComponent></ng-container>
    <mat-error *ngIf="formControl.errors && (formControl.touched || formControl.dirty)">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </div> `,
})
export class FormlyCheckboxWrapperComponent extends FieldWrapper {}
