<h2 [innerText]="dialogData.title"></h2>
<mat-dialog-content [innerHtml]="dialogData.message"> </mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-button color="warn" (click)="onNoClick()">
    <span [innerText]="dialogData.rejectButtonLabel"></span>
  </button>
  <button mat-raised-button color="primary" (click)="onYesClick()">
    <span [innerText]="dialogData.okButtonLabel"></span>
  </button>
</mat-dialog-actions>
