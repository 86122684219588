import { Component } from "@angular/core";
import { ViewBaseComponent } from "@strategies/_base/_view/view.base";
import { ViewIncidentFieldsTelekomComponent } from "./view-incident-fields.telekom.component";

@Component({
  selector: 'app-task-view-telekom',
  templateUrl: '../../../_base/_view/view.base.html',
  styleUrls: ['../../../_base/_view/view.base.scss'],
})
export class TaskViewTelekomComponent extends ViewBaseComponent {
  dynamicComponent = ViewIncidentFieldsTelekomComponent;  
}
