// https://stackblitz.com/edit/angular-mfsxfi?file=src%2Fapp%2Faddons.wrapper.ts

import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';
@Component({
  selector: 'formly-wrapper-addons',
  template: `
    <ng-container *ngIf="to.readOnly">
      <div class="read-only-field" *ngIf="tmpl === ''" fxLayout="row" fxLayout.lt-md="column">
        <div class="label" fxFlex="170px" fxFlex.lt-md="">{{ to.label }}:</div>
        <div fxFlex fxLayoutAlign="start end" [innerHtml]="value || '--'"></div>
      </div>
      <div class="read-only-field" *ngIf="tmpl !== ''" [innerHtml]="tmpl"></div>
    </ng-container>
    <div [style.display]="to.readOnly ? 'none' : ''">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styles: [
    `
      .read-only-field {
        padding: 1em;
        padding-right: 2em;
        display: flex;
      }
      .read-only-field > * {
        word-break: break-word;
      }
      .read-only-field > .label {
        font-weight: bold;
        margin-right: 10px;
      }
    `,
  ],
})
export class FormlyReadOnlyWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent!: ViewContainerRef;

  field: FormlyFieldConfig;

  tmpl = '';

  private valueFormatter: (value: any) => any;

  constructor(private santitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if (this.to.valueFormatter) {
      this.valueFormatter = this.to.valueFormatter;
    }
    if (this.to.readOnly && this.to.readOnlyTemplate && this.to.readOnlyTemplate !== '') {
      this.tmpl = this.to.readOnlyTemplate(this.field);
    }
  }

  get value(): string {
    return this.valueFormatter
      ? this.santitizer.bypassSecurityTrustHtml(this.valueFormatter(this.formControl.value))
      : this.formControl.value;
  }
}
