<smart-card [backButton]="true" *ngIf="!isLoading">
  <ng-container *ngIf="data">
    <h1 [innerText]="title" class="status-headline"></h1>
    <app-detail-fields-base
      [detailFields]="detailFields"
      [data]="data"
    ></app-detail-fields-base>
  </ng-container>
  <mtx-alert type="danger" [elevation]="2" [dismissible]="false" class="m-t-16" *ngIf="notFound">
    <h3 [innerHtml]="'ticket.details.error.ticketNotFound.title' | translate"></h3>
    <strong [innerHtml]="'ticket.details.error.ticketNotFound.message' | translate"></strong>
  </mtx-alert>
</smart-card>

<spinner-overlay [show]="isLoading"></spinner-overlay>
