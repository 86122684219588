import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-flex',
  template: `
    <style>
      :host ::ng-deep .mat-form-field-infix {
        width: auto;
      }
    </style>
    <div
      class="content"
      fxFlexFill
      [fxLayout]="to.fxLayout || 'column'"
      fxLayout.gt-xs="row"
      fxLayoutGap="0"
      fxLayoutGap.gt-xs="15px"
    >
      <formly-field
        *ngFor="let f of field.fieldGroup"
        [field]="f"
        [fxFlex]="f.templateOptions?.fxFlex || '100'"
      ></formly-field>
    </div>
  `,
})
export class FlexLayoutType extends FieldType {}
