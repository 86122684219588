<div class="footer-container">
  <div
    fxLayout="column"
    fxLayoutGap="32px"

    fxFlex.md="90"
    fxFlexOffset.md="5"

    fxFlex.lt-md="90"
    fxFlexOffset.lt-md="5"

    fxFlexOffset.gt-md="20"
    fxFlex.gt-md="60"

  >
    <div fxLayout="row" fxLayout.lt-md="column" class="debug" fxLayoutGap="64px">
      <div fxLayout="column" class="debug" fxFlex>
        <h2>Information</h2>
        <ng-container *ngFor="let item of information">
          <a [href]="item.link" target="_blank"><p [innerText]="item.label"></p></a>
        </ng-container>
      </div>
      <div fxLayout="column" class="debug" fxFlex>
        <h2 [innerText]="'footer.contact' | translate"></h2>
        <div class="contact-group">
          <p><strong [innerText]="'footer.renting' | translate"></strong></p>
          <p>T +49 30 491022-232</p>
          <p>vermietung@hib-brst.de</p>
        </div>
        <div class="contact-group">
          <p><strong>Service</strong></p>
          <p>T +49 30 491022-252</p>
          <p>wedding@hib-brst.de</p>
          <p>T +49 30 491022-292</p>
          <p>lichtenberg@hib-brst.de</p>
        </div>
      </div>
      <div fxLayout="column" class="debug" fxFlex fxLayoutGap="10px">
        <ng-container *ngFor="let item of ctaButtons">
          <a [href]="item.link" target="_blank">
            <button
              fxLayout="row"
              fxFlex
              mat-raised-button
              [style]="'background:' + item.color"
              class="ctaButton"
              fxFlexAlign="center"
              [title]="item.label"
            >
              <span class="customSendIconRight"></span
              ><span [innerText]="item.label"></span>
            </button>
          </a>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxFlex class="debug" fxLayoutGap="16px">
      <ng-container *ngFor="let item of impressum">
        <a [href]="item.link" target="_blank"><p [innerText]="item.label"></p></a>
      </ng-container>
    </div>
  </div>
</div>
