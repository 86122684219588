import {FormlyFieldConfig} from '@ngx-formly/core';
import {RegexpStreetname, RegexpZipCodeDE} from '@core';
import {TranslateService} from '@ngx-translate/core';
import {federalList} from './ticket-created-shared.field-configs';

export function targetLocation(translateService: TranslateService): Array<FormlyFieldConfig> {
  return [
    {
      wrappers: ['card'],
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.headline__objectAdress'),
            chapterText: translateService.instant('ticket.create.p__info_objectadress'),
            visibleInConfirm: true,
          },
        },
        {
          key: 'targetLocation',
          fieldGroup: [
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'street',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__street'),
                    visibleInConfirm: true,
                    maxLength: 250,
                    minLength: 3,
                    pattern: RegexpStreetname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.street.pattern'),
                      minlength: (err, _field) =>
                        translateService.instant('formErrors.street.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.required': (_model, formstate, _field) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
                {
                  type: 'input',
                  key: 'zipcode',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__zipcode'),
                    required: true,
                    visibleInConfirm: true,
                    maxLength: 5,
                    minLength: 5,
                    pattern: RegexpZipCodeDE,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.zipcode.pattern'),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.required': (_model, formstate, _field) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'city',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__city'),
                    required: true,
                    visibleInConfirm: true,
                    minLength: 3,
                    maxLength: 80,
                    pattern: RegexpStreetname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.city.pattern'),
                      minlength: (err, _field) =>
                        translateService.instant('formErrors.city.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.required': (_model, formstate, _field) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
                {
                  type: 'select',
                  key: 'state',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__state'),
                    required: true,
                    visibleInConfirm: true,
                    options: federalList(translateService.currentLang),
                  },
                  expressionProperties: {
                    'templateOptions.required': (_model, formstate, _field) =>
                      !formstate.mainModel.auxAddress,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
