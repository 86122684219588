<div class="row">
  <div class="col-4">
    <mat-form-field
      fxFlexFill
      [class]="formControl.errors && formControl.touched ? 'mat-form-field-invalid' : ''"
    >
      <mat-label innerText="{{ 'ticket.create.label__search_we' | translate }}"></mat-label>
      <input
        id="ctrlSingleSearchWE"
        matInput
        type="text"
        [formlyAttributes]="field"
        [formControl]="currentSearchCtrlWE"
        [matAutocomplete]="searchWE"
      />
      <button mat-button matSuffix *ngIf="isVisibleInputWaitingWE" mat-icon-button>
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
    </mat-form-field>
  </div>
  <div class="col-8">
    <mat-form-field
      fxFlexFill
      [class]="formControl.errors && formControl.touched ? 'mat-form-field-invalid' : ''"
    >
      <mat-label innerText="{{ 'ticket.create.label__search_other' | translate }}"></mat-label>
      <input
        id="ctrlSingleSearch"
        matInput
        type="text"
        [formlyAttributes]="field"
        [formControl]="currentSearchCtrl"
        [matAutocomplete]="search"
      />
      <button mat-button matSuffix *ngIf="isVisibleInputWaiting" mat-icon-button>
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
    </mat-form-field>
  </div>
</div>
<div class="row w-100">
  <div class="col-12">
    <mat-autocomplete panelWidth="800px" (optionSelected)="changeSearch($event)" #search="matAutocomplete">
      <mat-option *ngIf="searchList.length > 0" disabled="true">
        <div class="row w-100">
          <div class="col-2">
            {{ translateService.instant('ticket.autocomplete.VPSZ') }}
          </div>
          <div class="col-2">
            {{ translateService.instant('ticket.autocomplete.WE-No') }}
          </div>
          <div class="col-2" *ngIf="searchBuildings">
            {{ this.translateService.instant('ticket.autocomplete.WE-Additional-No') }}
          </div>
          <div class="col-3">
            {{ this.translateService.instant('ticket.autocomplete.city') }}
          </div>
          <div class="col-3">
            {{ this.translateService.instant('ticket.autocomplete.street') }}
          </div>
        </div>
      </mat-option>
      <mat-option
        (click)="selectSearchResult(option)"
        *ngFor="let option of searchList"
        [value]="option"
      >
        <div class="row w-100">
          <div class="col-2">
            {{ option?.vpsz ?? '' }}
          </div>
          <div class="col-2">
            {{ option?.siteName ?? '' }}
          </div>
          <div class="col-2" *ngIf="searchBuildings">
            {{ option?.siteAdditionalNumber ?? '' }}
          </div>
          <div class="col-3 text-overflow">
            {{ option?.address?.city ?? '' }}
          </div>
          <div class="col-3 text-overflow">
            {{
            option?.address?.streetName ??
            '' + (option?.address?.houseNumber ? ' ' + option?.address?.houseNumber : '')
            }}
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
<div class="row w-100">
  <div class="col-12">
    <mat-autocomplete panelWidth="800px" (optionSelected)="changeSearchWE($event)" #searchWE="matAutocomplete">
      <mat-option *ngIf="searchListWE.length > 0" disabled="true">
        <div class="row w-100">
          <div class="col-2">
            {{ translateService.instant('ticket.autocomplete.VPSZ') }}
          </div>
          <div class="col-2">
            {{ translateService.instant('ticket.autocomplete.WE-No') }}
          </div>
          <div class="col-2" *ngIf="searchBuildings">
            {{ this.translateService.instant('ticket.autocomplete.WE-Additional-No') }}
          </div>
          <div class="col-3">
            {{ this.translateService.instant('ticket.autocomplete.city') }}
          </div>
          <div class="col-3">
            {{ this.translateService.instant('ticket.autocomplete.street') }}
          </div>
        </div>
      </mat-option>
      <mat-option
        (click)="selectSearchResultWE(option)"
        *ngFor="let option of searchListWE"
        [value]="option"
      >
        <div class="row w-100">
          <div class="col-2">
            {{ option?.vpsz ?? '' }}
          </div>
          <div class="col-2">
            {{ option?.siteName ?? '' }}
          </div>
          <div class="col-2" *ngIf="searchBuildings">
            {{ option?.siteAdditionalNumber ?? '' }}
          </div>
          <div class="col-3 text-overflow">
            {{ option?.address?.city ?? '' }}
          </div>
          <div class="col-3 text-overflow">
            {{
            option?.address?.streetName ??
            '' + (option?.address?.houseNumber ? ' ' + option?.address?.houseNumber : '')
            }}
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>


<div class="preview">
  <div class="row">
    <div class="col-3">{{ 'ticket.autocomplete.WE-No' | translate }}</div>
    <div class="col-7">{{ selectedResult?.siteName ?? '' }}</div>
  </div>
  <div class="row">
    <div class="col-3" *ngIf="searchBuildings">
      {{ 'ticket.autocomplete.WE-Additional-No' | translate }}
    </div>
    <div class="col-7">{{ selectedResult?.siteAdditionalNumber ?? '' }}</div>
  </div>
  <div class="row">
    <div class="col-3">{{ 'ticket.autocomplete.VPSZ' | translate }}</div>
    <div class="col-7">{{ selectedResult?.vpsz ?? '' }}</div>
  </div>
  <div class="row">
    <div class="col-3">{{ 'ticket.autocomplete.zip' | translate }}</div>
    <div class="col-7">{{ selectedResult?.address?.postalCode ?? '' }}</div>
  </div>
  <div class="row">
    <div class="col-3">{{ 'ticket.autocomplete.city' | translate }}</div>
    <div class="col-7">{{ selectedResult?.address?.city ?? '' }}</div>
  </div>
  <div class="row">
    <div class="col-3">{{ 'ticket.autocomplete.street' | translate }}</div>
    <div class="col-7">
      <span *ngIf="selectedResult?.address">
        {{
        selectedResult.address?.streetName ?? '' + ' ' + selectedResult.address?.houseNumber ?? ''
        }}
      </span>
    </div>
    <div class="col-2 delete" *ngIf="selectedResult" (click)="deleteSelected()">
      <mat-icon [matTooltip]="'ticket.autocomplete.delete' | translate">delete</mat-icon>
    </div>
  </div>
</div>
