import { Component } from '@angular/core';
import { RegexpEmail } from '@core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TicketGetStatusBaseComponent } from '../../_base/ticket-anonym/ticket-get-status/ticket-get-status.base';

@Component({
  selector: 'app-ticket-get-status-default',
  templateUrl: '../../_base/ticket-anonym/ticket-get-status/ticket-get-status.base.html',
  styleUrls: [],
})
export class TicketGetStatusDefaultComponent extends TicketGetStatusBaseComponent {
  formFields: Array<FormlyFieldConfig> = [
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'id',
          templateOptions: {
            label: this.translateService.instant('ticket.status.label__incident_guid'),
            required: true,
            attributes: {
              style: 'font-family: monospace; font-size:1.5em',
            },
          },
        },
      ],
    },
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'email',
          templateOptions: {
            label: this.translateService.instant('ticket.status.label__email'),
            required: true,
            pattern: RegexpEmail,
          },
          validation: {
            messages: {
              pattern: (_err, _field: FormlyFieldConfig) =>
                this.translateService.instant('formErrors.email.pattern'),
            },
          },
        },
      ],
    },
  ];
}
