<mat-toolbar
  class="matero-toolbar"
  fxFlex="100"
  fxLayout="row"
  fxLayoutAlign="space-between"
  fxFlexFill
>
  <app-branding
    class="branding"
    fxFlex="25"
    fxFlex.xs="100"
    fxLayoutAlign="start center"
  ></app-branding>

  <ng-container *ngIf="{length: (menuItems$ | async).length} as menuItems">
    <!-- big top nav bar -->
    <app-topmenu *ngIf="showMenuItems" fxFlex="50" fxFlex.xs="100" fxHide.lt-lg [fxHide]="menuItems.length >= menuLengthMax"
                 fxLayoutAlign="center center"></app-topmenu>

    <div fxFlex="25" fxHide.lt-lg fxLayoutAlign="center center" [fxHide]="!showUserMenuItems || menuItems.length >= menuLengthMax">
      <user-actions></user-actions>
    </div>

    <!-- small sidenav toggle -->
    <div fxFlex fxFlex.xs="100" fxLayoutAlign="center center" [fxHide.gt-md]="menuItems.length < menuLengthMax"></div>
    <button *ngIf="helpService.currentHelpId" 
      mat-button (click)="openHelp()"><mat-icon>help</mat-icon>
    </button>
    
    <button *ngIf="showToggle" [fxHide.gt-md]="menuItems.length < menuLengthMax"
            mat-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  </ng-container>
</mat-toolbar>
