import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterArray' })
export class FilterArrayPipe implements PipeTransform {
  transform(value: any[], filterProperty: string, filterValue: any, equal = true): any[] {
    return equal
      ? value.filter(x => x[filterProperty] === filterValue)
      : value.filter(x => x[filterProperty] !== filterValue);
  }
}
