// panel-wrapper.component.ts
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldWrapper } from '@ngx-formly/core';
import { CheckboxDialogComponent } from '../checkbox-dialog';

@Component({
  selector: 'formly-checkbox-dialog-wrapper-component',
  template: `
    <div style="margin: 15px 0" (click)="openDialog()">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class FormlyCheckboxDialogWrapperComponent extends FieldWrapper {
  constructor(public dialog: MatDialog) {
    super();
  }

  openDialog() {
    const checkboxDialogRef = this.dialog.open(CheckboxDialogComponent, {
      width: '90%',
      maxHeight: '100vh',
      data: {
        title: this.to.checkboxDialogTitle,
        message: this.to.checkboxDialogMessage,
        okButtonLabel: this.to.checkboxDialogOkButtonLabel,
        rejectButtonLabel: this.to.checkboxDialogRejectButtonLabel,
      },
    });
    checkboxDialogRef.afterClosed().subscribe(result => {
      const val = result === undefined ? null : result;
      this.formControl.patchValue(val);
    });
  }
}
