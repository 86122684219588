import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { SettingsService } from '@core';
import { MatDatetimepickerType } from '@mat-datetimepicker/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-field-datetimepicker',
  template: `<mat-form-field appearance="outline" fxFlex="100">
    <mat-label [innerHtml]="field.templateOptions?.label"></mat-label>
    <mat-datetimepicker-toggle [for]="dateTimePicker" matSuffix></mat-datetimepicker-toggle>

    <mat-datetimepicker
      #dateTimePicker
      [type]="gettype()"
      [openOnFocus]="true"
      [startView]="field.templateOptions?.startView"
      [startAt]="field.templateOptions?.startAt"
      [lang]="lang"
    >
    </mat-datetimepicker>
    <mat-error *ngIf="formControl.errors?.matDatepickerMin">
      <span [innerText]="field.templateOptions?.errorMessageMin | translate"></span>
    </mat-error>
    <mat-error *ngIf="formControl.errors?.matDatepickerMax">
      <span [innerText]="field.templateOptions?.errorMessageMax | translate"></span>
    </mat-error>
    <mat-error *ngIf="formControl.errors?.matDatepickerParse">
      <span [innerText]="'formErrors.datepicker.matDatepickerParse' | translate"></span>
    </mat-error>
    <mat-error>
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
    <input
      [title]="to.label"
      matInput
      [matDatetimepicker]="dateTimePicker"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [matDatepickerFilter]="datepickerFilter"
      [min]="field.templateOptions?.minDate"
      [max]="field.templateOptions?.maxDate"
      (ngModelChange)="emitChange($event)"
      (click)="dateTimePicker.open()"
      [required]="this.templateOptions?.required === true"
      readonly
  /></mat-form-field>`,
})
export class FormlyDateTimePickerComponent extends FieldType implements OnInit {
  formControl: FormControl;

  field: FormlyFieldConfig;

  templateOptions: any;

  lang: string;

  datepickerFilter: (any) => any;

  constructor(private dateAdapter: DateAdapter<Date>, private settingService: SettingsService) {
    super();
    this.lang = this.settingService.language.substring(0, 2);
    this.dateAdapter.setLocale(this.settingService.language.substring(0, 2));
  }

  ngOnInit() {
    this.templateOptions = this.field.templateOptions;

    if (this.templateOptions?.dateFilter) {
      this.datepickerFilter = this.templateOptions?.dateFilter;
    } else if (this.templateOptions?.allowedDates) {
      this.datepickerFilter = (d: moment.Moment) => {
        if (d) {
          const day = d.format('DD.MM.YYYY');
          return this.templateOptions?.allowedDates.includes(day);
        }
        return true;
      };
    }
  }

  gettype() {
    return <MatDatetimepickerType>this.to.datetype || 'datetime';
  }

  emitChange(ev) {
    this.field.templateOptions?.change?.call(this.field, ev);
  }
}
