import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetIncidentDTO, IncidentClient } from '@api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateDataService } from '@routes/offering/shared/service/translate-data.service';
import { IbpdiHelperService } from '@shared/services/ibpdi-helper.service';
import { IDetailFields } from '@strategies/_base/_details/detail-fields/models/detail-fields.model';
import { Subscription } from 'rxjs';
import { DocumentVisibility } from '@shared/types/document.types';

@Component({
  selector: 'app-ticket-status-base',
  templateUrl: './ticket-status-item.base.html',
  styleUrls: [],
})
export class TicketStatusItemBaseComponent {
  public data: any;

  public title?: string;

  public detailFields: IDetailFields[];

  public isLoading = false;

  public notFound = false;

  private queryParamsSubscription: Subscription;

  private getTicketStatusSubscription: Subscription;

  prepareOutput?: (data) => any;

  constructor(
    private route: ActivatedRoute,
    private incidentClient: IncidentClient,
    protected ibpdiHelperService: IbpdiHelperService,
    protected translateService: TranslateService,
    protected translateDataService: TranslateDataService
  ) {
    this.getTicketData();
  }

  getTicketData() {
    this.isLoading = true;

    this.queryParamsSubscription = this.route.queryParams.subscribe(async queryParams => {
      const { email } = queryParams;
      const ticketNumber = this.route.snapshot.paramMap.get('id');
      if (ticketNumber) {
        const getIncidentDto: GetIncidentDTO = {
          ticketNumber,
          primaryContactEmail: email,
        };

        this.getTicketStatusSubscription = this.incidentClient
          .getIncidentWithEmail(getIncidentDto)
          .subscribe(
            data => {
              this.data = data;
              if (this.prepareOutput) {
                this.data = this.prepareOutput(data);
              }

              const fmDocumentField = this.detailFields.find(
                d => d.key != null && d.key === 'fmDocument'
              );
              if (fmDocumentField) {
                fmDocumentField.fileMetadata = [
                  {
                    attributeName: 'incidentId',
                    attributeValue: data.incidentId || 'error no incidentId',
                  },
                  {
                    attributeName: 'visibilityId',
                    attributeValue: DocumentVisibility.Public,
                  },
                  {
                    attributeName: 'ticketEmail',
                    attributeValue:
                      getIncidentDto.primaryContactEmail || 'error no primaryContactEmail',
                  },
                  {
                    attributeName: 'ticketNumber',
                    attributeValue: getIncidentDto.ticketNumber || 'error no ticketNumber',
                  },
                ];
              }

              this.isLoading = false;
              this.notFound = !this.data;
            },
            _error => {
              this.notFound = true;
              this.isLoading = false;
            }
          );
      } else {
        this.notFound = true;
        this.isLoading = false;
      }
    });
  }

  ngOnDestory() {
    this.queryParamsSubscription.unsubscribe();
    this.getTicketStatusSubscription.unsubscribe();
  }
}
