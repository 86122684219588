// source https://stackblitz.com/edit/ang-mat-tree-select?file=src%2Fapp%2Fselect-optgroup-example.html,src%2Fapp%2Fselect-optgroup-example.ts

import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: string;
}

interface OptionTreeNode {
  name: string;
  value?: string;
  children?: OptionTreeNode[];
  id: string;
}

@Component({
  selector: 'tree-select-input',
  template: `
    <mat-form-field fxFlex>
      <mat-label [innerText]="to.label" *ngIf="!error"></mat-label>
      <mat-label *ngIf="error">error</mat-label>
      <mat-spinner
        [diameter]="24"
        *ngIf="isLoading"
        style="position: absolute; left: 50%"
      ></mat-spinner>
      <mat-select
        [disabled]="isLoading || error"
        #myselect
        (selectionChange)="onSelectionChange($event)"
        [formlyAttributes]="field"
        [multiple]="false"
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [value]="selected"
      >
        <mat-form-field appearance="outline" style="margin:10px; display:block" id="selectSearch">
          <mat-label>Filter</mat-label>
          <input
            #searchInput
            placeholder="Search"
            (keydown)="$event.stopPropagation()"
            (keyup)="filter(originalData, searchInput.value)"
            matInput
          />
          <button
            *ngIf="searchInput.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchInput.value = ''; filter(originalData, '')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-option [value]="null">-- None --</mat-option>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node
            (click)="onTreeNodeSelect($event)"
            *matTreeNodeDef="let node"
            matTreeNodePadding
          >
            <mat-option [value]="node">{{ node.name }}</mat-option>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <mat-option [value]="node">{{ node.name }}</mat-option>
          </mat-tree-node>
        </mat-tree>
      </mat-select>
    </mat-form-field>
    <br />
  `,
  styles: [
    `
      #selectSearch > ::ng-deep.mat-form-field-wrapper {
        padding-bottom: 0px !important;
      }
    `,
  ],
})
export class FormlyTreeSelectComponent extends FieldType implements OnInit {
  @ViewChild('myselect') selectPanel!: MatSelect;

  originalData: any;

  isLoading = false;

  selected: any;

  error: any;

  private transformer = (node: OptionTreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      id: node.id,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  onSelectionChange(ev: any) {
    this.formControl.patchValue(ev.value);
    this.selected = ev.value;
  }

  ngOnInit() {
    this.isLoading = true;
    (this.to.options as Observable<any>).subscribe(
      opts => {
        console.log(opts);
        this.dataSource.data = opts;
        this.originalData = opts;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.error = error;
        console.log(error);
      }
    );
  }

  onSelectionChanged($event: any) {
    console.log($event);
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onTreeNodeSelect(event: any): void {
    this.selectPanel._handleKeydown(event);
  }

  resetSelection() {
    console.log('reset');
  }

  filter(array: any, searchInput: string) {
    const getNodes = (result: any, object: any) => {
      if (object.name.toLowerCase().includes(searchInput)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };
    this.dataSource.data = array.reduce(getNodes, []);
  }
}
