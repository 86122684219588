import { FormlyFieldConfig } from '@ngx-formly/core';
import { RegexpPlotnumber, RegexpStreetname, RegexpZipCodeDE } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { federalList } from './ticket-created-shared.field-configs';

export function auxAddress(translateService: TranslateService): Array<FormlyFieldConfig> {
  return [
    {
      key: 'auxAddress',
      type: 'checkbox',
      defaultValue: false,
      templateOptions: {
        label: translateService.instant('ticket.create.checkbox__auxAddress'),
        appearance: 'standard',
      },
    },
    {
      wrappers: ['card'],
      hideExpression: 'model.auxAddress===false',
      fieldGroup: [
        {
          type: 'chapter',
          templateOptions: {
            chapterTitle: translateService.instant('ticket.create.headline__auxAddress'),
            chapterText: translateService.instant('ticket.create.p__info_auxAddress'),
          },
          expressionProperties: {
            'templateOptions.visibleInConfirm': 'model.auxAddress',
          },
        },
        {
          key: 'concerningLocation',
          fieldGroup: [
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'street',
                  templateOptions: {
                    required: true,
                    label: translateService.instant('ticket.create.label__street_aux'),
                    maxLength: 250,
                    minLength: 3,
                    pattern: RegexpStreetname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.street.pattern'),
                      minlength: (err, _field) =>
                        translateService.instant('formErrors.street.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.visibleInConfirm': 'true',
                  },
                },
                {
                  type: 'input',
                  key: 'zipcode',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__zipcode_aux'),
                    required: true,
                    maxLength: 5,
                    minLength: 5,
                    pattern: RegexpZipCodeDE,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.zipcode.pattern'),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'city',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__city_aux'),
                    required: true,
                    minLength: 3,
                    maxLength: 80,
                    pattern: RegexpStreetname,
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.city.pattern'),
                      minlength: (err, _field) =>
                        translateService.instant('formErrors.city.minLength', {
                          length: err.requiredLength,
                        }),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.visibleInConfirm': 'true',
                  },
                },
                {
                  type: 'select',
                  key: 'state',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__state_aux'),
                    required: true,
                    options: federalList(translateService.currentLang),
                  },
                  expressionProperties: {
                    'templateOptions.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'flex-layout',
              templateOptions: {
                fxLayout: 'row',
              },
              fieldGroup: [
                {
                  type: 'input',
                  key: 'plotNumber',
                  templateOptions: {
                    maxLength: 300,
                    pattern: RegexpPlotnumber,
                    label: translateService.instant('ticket.create.label__plotNumber_aux'),
                  },
                  validation: {
                    messages: {
                      pattern: translateService.instant('formErrors.plotnumber.pattern'),
                    },
                  },
                  expressionProperties: {
                    'templateOptions.visibleInConfirm': 'true',
                  },
                },
              ],
            },
            {
              type: 'select',
              key: 'powerOfDisposition',
              hideExpression: 'model.auxAddress === false',
              templateOptions: {
                label: translateService.instant('ticket.create.label__powerOfDisposition'),
                visibleInConfirm: true,
                required: true,
                options: [
                  { label: 'Eigentümer', value: 'Eigentümer' },
                  { label: 'Verfügungsberechtigter', value: 'Verfügungsberechtigter' },
                  { label: 'Andere', value: 'Andere' },
                ],
              },
            },
          ],
        },
      ],
    },
  ];
}
