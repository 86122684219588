import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { ErrorHandlerService, LoggerService } from '@core';

// NOTE: Here you can handle every Error (Client / Server) that may raise
// You could inject a Dialog or Toast Service to show custom error to the user
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandlerService {
  constructor(private zone: NgZone, private loggerService: LoggerService) {}

  public handleClientError(error: Error): void {
    this.loggerService.error('Client error:', error);
    this.runInAngularZone(() => {
      // NOTE: Show Error Dialog
    });
  }

  public handleServerError(error: HttpErrorResponse): void {
    this.loggerService.error('Server error:', error);
    this.runInAngularZone(() => {
      // NOTE: Show Error Dialog
    });
  }

  private runInAngularZone(callbackFn: () => void) {
    this.zone.run(callbackFn);
  }
}

export const globalErrorHandlerProviders = [
  {
    provide: ErrorHandlerService,
    useClass: GlobalErrorHandlerService,
  },
];
