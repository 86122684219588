import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CheckboxDialogData {
  title: string;
  message: string;
  okButtonLabel: string;
  rejectButtonLabel: string;
}

@Component({
  selector: 'app-checkbox-dialog',
  templateUrl: './checkbox-dialog.component.html',
  styleUrls: ['./checkbox-dialog.component.scss'],
})
export class CheckboxDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CheckboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: CheckboxDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onDismissClick(): void {
    this.dialogRef.close();
  }
}
