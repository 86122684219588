import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AccordionDirective } from './sidemenu/accordion.directive';
import { AccordionAnchorDirective } from './sidemenu/accordionanchor.directive';
import { AccordionItemDirective } from './sidemenu/accordionItem.directive';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { HeaderComponent } from './header/header.component';
import { BrandingComponent } from './header/widgets/branding.component';
import { HeaderItemsComponent } from './header/widgets/edbHeaderItems/HeaderItems.component';
import { FooterComponent } from './footer/footer.component';
import { FooterDefaultComponent } from './footer/variations/footer-default/footer-default.component';
import { FooterBrstComponent } from './footer/variations/footer-brst/footer-brst.component';
import { FooterMolewoComponent } from './footer/variations/footer-molewo/footer-molewo.component';

@NgModule({
  declarations: [
    AppLayoutComponent,
    SidebarComponent,
    SidemenuComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    TopmenuComponent,
    FooterComponent,
    HeaderComponent,
    BrandingComponent,
    HeaderItemsComponent,
    FooterDefaultComponent,
    FooterBrstComponent,
    FooterMolewoComponent,
  ],
  imports: [SharedModule],
})
export class ThemeModule {}
