import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '@env/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNIN_ONLY_ROLES',
    editProfile: 'B2C_1_PasswordReset',
  },
  authorities: {
    signUpSignIn: {
      authority: environment.AadSignupSigninUrl,
    },
    editProfile: {
      authority: environment.AadEditprofileUrl,
    },
  },
  authorityDomain: environment.AadAuthorityDomain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.AadClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: `${window.location.origin}/authentication/callback`,
    postLogoutRedirectUri: `${window.location.origin}/`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (_logLevel, _message, _containsPii) => {
        // console.log(_logLevel, _message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  getIncident: {
    endpoint: '/api/Incident/GetIncidents',
    scopes: [environment.AadScope],
  },
  getIncidentsAuthenticated: {
    endpoint: '/api/Incident/GetIncidentsAuthenticated',
    scopes: [environment.AadScope],
  },
  getRequestLocations: {
    endpoint: '/api/RequestLocation/GetRequestLocations',
    scopes: [environment.AadScope],
  },
  getRentalContractForUserIncident: {
    endpoint: '/api/User/GetRentalContractForUserIncident',
    scopes: [environment.AadScope],
  },
  getIncidentClasses: {
    endpoint: '/api/IncidentClass/GetIncidentClasses',
    scopes: [environment.AadScope],
  },
  getIncidentAuthenticated: {
    endpoint: '/api/Incident/GetIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  getIncidentByTicketIdAuthenticated: {
    endpoint: '/api/Incident/GetIncidentByTicketIdAuthenticated',
    scopes: [environment.AadScope],
  },
  getWorkorders: {
    endpoint: '/api/Workorder/GetWorkorders',
    scopes: [environment.AadScope],
  },
  getWorkorder: {
    endpoint: '/api/Workorder/GetWorkorder',
    scopes: [environment.AadScope],
  },
  getQuotes: {
    endpoint: '/api/Quote/GetQuotes',
    scopes: [environment.AadScope],
  },
  getQuote: {
    endpoint: '/api/Quote/GetQuote',
    scopes: [environment.AadScope],
  },
  changeQuoteState: {
    endpoint: '/api/Quote/ChangeQuoteState',
    scopes: [environment.AadScope],
  },
  getLocationFilterOptionSet: {
    endpoint: '/api/OptionSet/GetLocationFilter',
    scopes: [environment.AadScope],
  },
  getOptionSetByString: {
    endpoint: '/api/OptionSet/GetOptionSetByString',
    scopes: [environment.AadScope],
  },
  searchArchobjForAutocomplete: {
    endpoint: '/api/Location/SearchArchobjForAutocomplete',
    scopes: [environment.AadScope],
  },
  searchArchobjForAutocompleteInexact: {
    endpoint: '/api/Location/SearchArchobjForAutoCompleteInexact',
    scopes: [environment.AadScope],
  },
  searchEmployee: {
    endpoint: '/api/Employment/SearchEmployee',
    scopes: [environment.AadScope],
  },
  postUpdateLocationInformation: {
    endpoint: '/api/Location/PostUpdateLocationInformation',
    scopes: [environment.AadScope],
  },
  postUpdateLocationHints: {
    endpoint: '/api/Location/PostUpdateLocationHints',
    scopes: [environment.AadScope],
  },
  getArchobjById: {
    endpoint: '/api/Location/GetArchobjById',
    scopes: [environment.AadScope],
  },
  getArchobjOfCurrentUser: {
    endpoint: '/api/Location/GetArchobjOfCurrentUser',
    scopes: [environment.AadScope],
  },
  getHolidayList: {
    endpoint: '/api/OptionSet/GetHolidayList',
    scopes: [environment.AadScope],
  },
  createIncidentAuthenticated: {
    endpoint: '/api/Incident/CreateIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  createPasmIncidentAuthenticated: {
    endpoint: '/api/Incident/CreatePasmIncidentAuthenticated',
    scopes: [environment.AadScope],
  },
  getTasks: {
    endpoint: '/api/Task/GetTasks',
    scopes: [environment.AadScope],
  },
  getTask: {
    endpoint: '/api/Task/GetTask',
    scopes: [environment.AadScope],
  },
  updateTask: {
    endpoint: '/api/Task/UpdateTask',
    scopes: [environment.AadScope],
  },
  createExportExcelFromTask: {
    endpoint: 'api/Task/CreateExportExcelFromTask',
    scopes: [environment.AadScope],
  },
  getUserProfile: {
    endpoint: '/api/User/GetUserProfile',
    scopes: [environment.AadScope],
  },
  updateUserProfile: {
    endpoint: '/api/User/UpdateUserProfile',
    scopes: [environment.AadScope],
  },
  getRentalContractForUser: {
    endpoint: '/api/User/GetRentalContractForUser',
    scopes: [environment.AadScope],
  },
  readUser: {
    endpoint: '/api/User/ReadUser',
    scopes: [environment.AadScope],
  },
  updateUser: {
    endpoint: '/api/User/UpdateUser',
    scopes: [environment.AadScope],
  },
  deleteUser: {
    endpoint: '/api/User/DeleteUser',
    scopes: [environment.AadScope],
  },
  readUsers: {
    endpoint: '/api/User/ReadUsers',
    scopes: [environment.AadScope],
  },
  readUserGroups: {
    endpoint: '/api/User/ReadUserGroups',
    scopes: [environment.AadScope],
  },
  readGroups: {
    endpoint: '/api/User/ReadGroups',
    scopes: [environment.AadScope],
  },
  addUserToGroups: {
    endpoint: '/api/User/AddUserToGroups',
    scopes: [environment.AadScope],
  },
  removeUserFromGroups: {
    endpoint: '/api/User/RemoveUserFromGroups',
    scopes: [environment.AadScope],
  },
  requestRentalcontractTermination: {
    endpoint: '/api/Incident/RequestRentalcontractTermination',
    scopes: [environment.AadScope],
  },
  getCustomizableTextList: {
    endpoint: '/api/CustomizableText/GetCustomizableTextList',
    scopes: [environment.AadScope],
  },
  getCustomizableText: {
    endpoint: '/api/CustomizableText/GetCustomizableText',
    scopes: [environment.AadScope],
  },
  updateCustomizableText: {
    endpoint: '/api/CustomizableText/UpdateCustomizableText',
    scopes: [environment.AadScope],
  },
  requestRoles: {
    endpoint: '/api/User/RequestRoles',
    scopes: [environment.AadScope],
  },
  archiveFileToImageMaster: {
    endpoint: '/api/Storage/ArchiveFileToImageMaster',
    scopes: [environment.AadScope],
  },
  archiveFilesToImageMaster: {
    endpoint: '/api/Storage/ArchiveFilesToImageMaster',
    scopes: [environment.AadScope],
  },
  deleteDocumentMetadata: {
    endpoint: '/api/Storage/DeleteDocumentMetadata',
    scopes: [environment.AadScope],
  },
  getDocumentMetadata: {
    endpoint: '/api/Storage/GetDocumentMetadata',
    scopes: [environment.AadScope],
  },
  getDocumentTypes: {
    endpoint: '/api/Storage/GetDocumentTypes',
    scopes: [environment.AadScope],
  },
  uploadFiles: {
    endpoint: '/api/Storage/UploadFiles',
    scopes: [environment.AadScope],
  },
  getIncidentDocuments: {
    endpoint: '/api/File/GetIncidentDocuments',
    scopes: [environment.AadScope],
  },
  getTaskDocuments: {
    endpoint: '/api/File/GetTaskDocuments',
    scopes: [environment.AadScope],
  },
  getContactDocuments: {
    endpoint: '/api/File/GetContactDocuments',
    scopes: [environment.AadScope],
  },
  getRentalContractDocuments: {
    endpoint: '/api/File/GetRentalContractDocuments',
    scopes: [environment.AadScope],
  },
  downloadFile: {
    endpoint: '/api/File/DownloadFile',
    scopes: [environment.AadScope],
  },
  downloadZip: {
    endpoint: '/api/File/DownloadZip',
    scopes: [environment.AadScope],
  },
  getDocuments: {
    endpoint: '/api/File/GetDocuments',
    scopes: [environment.AadScope],
  },
  getIbpdiDocuments: {
    endpoint: '/api/File/GetIbpdiDocuments',
    scopes: [environment.AadScope],
  },
  getSearchedDocuments: {
    endpoint: '/api/DocumentSearch/GetSearchedDocuments',
    scopes: [environment.AadScope],
  },
  setFileVisibility: {
    endpoint: '/api/File/SetFileVisibility',
    scopes: [environment.AadScope],
  },
};

export const loginRequest = {
  scopes: [],
};

export function GetProtectedResourcesMap(): Map<string, string[]> {
  const map: Map<string, string[]> = new Map([]);
  Object.keys(protectedResources).forEach(element => {
    const ressource: { endpoint: string; scopes: string[] } = protectedResources[element];
    map.set(ressource.endpoint, ressource.scopes);
  });
  return map;
}
