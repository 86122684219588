import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@core/module-import-guard';
import { environment } from '@env/environment';
import {
  API_BASE_URL,
  FileClient,
  StorageClient,
  IncidentClient,
  OfferingClient,
  OptionSetClient,
  LocationClient,
  EmploymentClient,
  QuoteClient,
  TaskClient,
  UserClient,
  WorkorderClient,
  CustomizableTextClient,
  IncidentClassClient,
  RequestLocationClient,
  DocumentSearchClient,
} from './smart-portal.api';

const PROVIDERS = [
  { provide: API_BASE_URL, useFactory: () => environment.baseUrl },
  OfferingClient,
  IncidentClient,
  FileClient,
  UserClient,
  TaskClient,
  OptionSetClient,
  LocationClient,
  EmploymentClient,
  QuoteClient,
  WorkorderClient,
  CustomizableTextClient,
  StorageClient,
  IncidentClassClient,
  RequestLocationClient,
  DocumentSearchClient,
];

@NgModule({
  declarations: [],
  imports: [],
  providers: [...PROVIDERS],
})
export class SmartPortalApiModule {
  constructor(@Optional() @SkipSelf() parentModule: SmartPortalApiModule) {
    throwIfAlreadyLoaded(parentModule, 'SmartPortalApiModule');
  }
}
