import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlSegment } from '@angular/router';
import { StartupService } from '@core/bootstrap/startup.service';

@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanLoad {
  constructor(private startupService: StartupService, private router: Router) {}

  canLoad(route: UrlSegment): boolean {
    if (!Object.keys(this.startupService.menuJson).includes(route.path)) {
      this.router.navigate(['']);
    }
    return true;
  }
}
