import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core';
import { HelpService } from '@shared';
import { MandantConfigService } from '@strategies';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  /*
   * Controls the visibility of the hamburger menu button to collapse / expand the side menu.
   * */
  @Input() showToggle = true;

  /*
   * Controls the visibility of the menu items
   * */
  @Input() showMenuItems = true;

  /*
   * Controls the visibility of the menu items for the user, e.g. language switch, login, profile
   * */
  @Input() showUserMenuItems = true;

  /*
   * Controls the visibility of the company logo / title
   * */
  @Input() showBranding = false;

  @Output() toggleSidenav = new EventEmitter<void>();

  @Output() toggleSidenavNotice = new EventEmitter<void>();

  menuLengthMax = this.mandantConfigService.mandant.topMenuLengthMax ?? 4;

  menuItems$ = this.menuService.getFilteredMenu$();

  constructor(
    public helpService: HelpService,
    private menuService: MenuService,
    private mandantConfigService: MandantConfigService
  ) {}

  public openHelp() {
    console.log('Help');
    this.helpService.show();
  }
}
