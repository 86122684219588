import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { FormlyPreviewAutocompleteColumnDefinition } from '@shared/components/formly-preview-autocomplete-type/formly-preview-autocomplete-type.types';

@Component({
  selector: 'app-form-preview-autocomplete-type',
  templateUrl: './formly-preview-autocomplete-type.component.html',
  styleUrls: ['./formly-preview-autocomplete-type.component.style.scss'],
})
export class FormlyPreviewAutocompleteTypeComponent
  extends FieldType
  implements OnInit, AfterViewInit, OnDestroy
{
  public currentSearchCtrl = new FormControl();

  public translatePath = '';

  public search = '';

  public isVisibleInputWaiting = false;

  public searchList: any[] = [];

  public selectedResult: any;

  public field: FormlyFieldConfig;

  public selectedText = '';
  public initialSearch: boolean = true;

  private subCurrentSearchCtrl: Subscription;
  public columnDefinitions: FormlyPreviewAutocompleteColumnDefinition[];

  constructor(public translateService: TranslateService, private cd: ChangeDetectorRef) {
    super();
    this.addSearch();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subCurrentSearchCtrl.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.translatePath = this.field.templateOptions?.translatePath ?? '';
    this.columnDefinitions = this.field.templateOptions?.columnDefinitions ?? [];

    if (this.field.templateOptions?.defaultValue?.id) {
      this.selectValue(this.field.templateOptions?.defaultValue);
      this.currentSearchCtrl.setValue(this.field.templateOptions?.defaultValue.id);
    }
    this.cd.detectChanges();
  }

  public selectValue(option: any) {
    this.selectedResult = option;
    if (this.selectedResult?.id) {
      this.value = this.selectedResult;
    }
    this.currentSearchCtrl.setValue(this.selectedText);
    return this.field?.templateOptions?.onSelect(this.selectedResult);
  }

  public changeSearch(event: any) {
    this.selectedResult = event.option.value;
    if (this.selectedResult?.id) {
      this.value = this.selectedResult;
    }
    this.currentSearchCtrl.setValue(this.selectedText);
  }

  public deleteSelected() {
    this.value = undefined;
    this.currentSearchCtrl.setValue('');
    this.selectedResult = {};
  }

  public getOptionLabelFormatted(option: any, column: FormlyPreviewAutocompleteColumnDefinition) {
    let label: any = option?.[column.fieldName];
    if (label) {
      return label;
    }
    label = column.formatter ? column.formatter(option) : '';
    return label;
  }

  private addSearch() {
    this.subCurrentSearchCtrl = this.currentSearchCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.searchList = [];
        }),
        filter(search => search.length > 2 && this.value !== search),
        switchMap((value: string) => {
          this.isVisibleInputWaiting = true;
          this.selectedText = value;
          const promise = this.field?.templateOptions?.onSearch(value, this.initialSearch);
          this.initialSearch = false;
          return promise;
        })
      )
      .subscribe(
        (data: any) => {
          data.entites?.forEach(item => {
            this.searchList.push(item);
          });
          if (this.searchList.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedResult = this.searchList[0];
            this.value = this.selectedResult;
          }
          if (this.searchList.length === 0) {
            this.field?.templateOptions?.onSuccess('grid.no_results');
            this.field?.templateOptions?.onSuccess('alert.info');
          }
          this.isVisibleInputWaiting = false;
        },
        (_error: any) => {
          this.field?.templateOptions?.onError('crud.details.error.failed_to_load_msg');
          this.field?.templateOptions?.onError('alert.error');
          this.isVisibleInputWaiting = false;
          this.subCurrentSearchCtrl.unsubscribe();
          this.addSearch();
        }
      );
  }
}
