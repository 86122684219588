import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent {
  public get visible$(): Observable<boolean> {
    return this.breadcrumbService.visible$;
  }

  constructor(private breadcrumbService: BreadcrumbService) {}
}
