<div fxFlex="100" fxLayout="column" class="page-wrapper" *ngIf="(isLandingModule | async) !== true">
  <app-header [showMenuItems]="!showPermanentSideMenu"
              [showUserMenuItems]="!isTabletScreen"
              (toggleSidenav)="sidenav.toggle()"
              class="sticky">
  </app-header>

  <mat-drawer-container *ngIf="showPermanentSideMenu && !isTabletScreen" fxFlexFill>
    <mat-drawer mode="side" [opened]="!isTabletScreen" fxLayout="column">
      <app-sidebar>
      </app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>

  <mat-sidenav-container *ngIf="!showPermanentSideMenu || isTabletScreen" fxFlexFill>
    <mat-sidenav fxLayout="column" #sidenav [position]="mobileSideMenuPosition">
      <app-sidebar>
        <user-actions></user-actions>
      </app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill class="matero-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-footer class="footer"></app-footer>
</div>
<div style="width: 100%" *ngIf="(isLandingModule | async) === true">
  <router-outlet></router-outlet>
</div>
