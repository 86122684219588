import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-container-colored-polygon',
  templateUrl: '../../_base/layout/layout-container.component.html',
})
export class LayoutContainerColoredPolygonComponent {
  public imageUrl: any = './assets/images/polygon.svg';

  public applyColoringToBg = true;
}
