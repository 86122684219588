import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-field-dragdrop',
  template: `
    <div class="drag-drop-area" [class.dragging]="isDragging">
      <input type="file" #fileInput multiple (change)="onFilesAdded()" hidden />
      <h3>{{ translateService.instant('crud.fileUpload.dragAndDrop.title') }}</h3>
      <h3>{{ translateService.instant('crud.fileUpload.dragAndDrop.titleOr') }}</h3>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="browseButton"
        (click)="fileInput.click()"
      >
        {{ translateService.instant('crud.fileUpload.dragAndDrop.browse') }}
      </button>
    </div>
    <div>
      <h3 *ngIf="formControl.value && formControl.value.length > 0">
        <mat-icon>attach_file</mat-icon>
        {{ translateService.instant('crud.fileUpload.dragAndDrop.uploadedFiles') }}
      </h3>
      <ul>
        <li *ngFor="let file of formControl.value; index as i" class="filename-label">
          {{ file?.name }} ({{ (file?.size / 1e6).toFixed(2) }} MB)
          <button mat-icon-button color="warn" (click)="removeFile(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </li>
      </ul>
    </div>

    <p fxFlex class="mat-error" *ngIf="formatError">
      {{ 'formErrors.file.format' | translate: { accept: to.accept } }}
    </p>
    <p fxFlex class="mat-error" *ngIf="sizeError">
      {{ 'formErrors.file.size' | translate: { maxSize: to.maxFileSizeInMB } }}
    </p>
    <p fxFlex class="mat-error" *ngIf="formControl.errors && formControl.touched">
      {{ 'formErrors.file.select' | translate }}
    </p>
    <p fxFlex class="mat-error" *ngIf="fileNameLengthError">
      {{ 'fileUpload.error.message__filename_length_too_large' | translate }}
    </p>
    <p fxFlex class="mat-error" *ngIf="fileSizeTooSmallError">
      {{ 'fileUpload.error.message__file_size_too_small' | translate }}
    </p>
  `,
  styles: [
    `
      .drag-drop-area {
        width: 100%;
        height: 200px;
        border: 2px dashed #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
      }

      .browseButton {
        vertical-align: middle;
      }

      .filename-label {
        word-break: break-all;
        padding-left: 10px;
        /* margin-top: 26px; */
      }
      .error {
        color: red;
      }

      .drag-drop-area.dragging {
        border-color: #3f51b5;
      }
      ::ng-deep .drag-and-drop-form-field {
        vertical-align: middle;
        margin-left: 0.5em;
        margin-top: 6px;
      }
      ::ng-deep .drag-and-drop-form-field .mat-form-field-flex > .mat-form-field-infix {
        padding: 1em 0 !important;
      }
      ::ng-deep .drag-and-drop-form-field input {
        opacity: 1 !important;
      }
    `,
  ],
})
export class FormlyFieldDragDropComponent extends FieldType implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  formControl: FormControl;

  field: FormlyFieldConfig;

  selectedFile: File;

  isDragging = false;

  formatError = false;

  sizeError = false;

  fileNameLengthError = false;

  fileSizeTooSmallError = false;

  constructor(public translateService: TranslateService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.formatError = false;
    this.sizeError = false;
    this.fileNameLengthError = false;
    this.fileSizeTooSmallError = false;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    this.preventAndStopEventPropagation(event);
    this.isDragging = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.preventAndStopEventPropagation(event);
    this.isDragging = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.preventAndStopEventPropagation(event);
    this.isDragging = false;
    const { files } = event.dataTransfer;
    this.appendFiles(files);
  }

  preventAndStopEventPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onFilesAdded() {
    const { files } = this.fileInput.nativeElement;
    this.appendFiles(files);
  }

  appendFiles(files: FileList) {
    const fileArray = Array.from(files);
    fileArray.forEach(file => {
      this.formatError = false;
      this.sizeError = false;
      this.fileNameLengthError = false;
      this.fileSizeTooSmallError = false;

      const selectedFileExtensionArray = file.name.split('.');
      const selectedFileExtensionArrayLength = selectedFileExtensionArray.length;
      if (
        this.field.templateOptions?.accept &&
        !this.field.templateOptions?.accept
          ?.toLowerCase()
          .includes(selectedFileExtensionArray[selectedFileExtensionArrayLength - 1].toLowerCase())
      ) {
        this.formatError = true;
        return;
      }
      if (
        this.field.templateOptions?.maxFileSizeInMB &&
        file.size > this.field.templateOptions?.maxFileSizeInMB * 1e6
      ) {
        this.sizeError = true;
        return;
      }
      if (file.name.length > 150) {
        this.fileNameLengthError = true;
        return;
      }

      if (file.size <= 0) {
        this.fileSizeTooSmallError = true;
        return;
      }

      const existingFiles = this.formControl.value || [];
      this.formControl.setValue([...existingFiles, file]);
      this.cdr.detectChanges();
      this.form.setErrors(null);
    });
  }

  removeFile(index: number) {
    const files = this.formControl.value || [];
    files.splice(index, 1);
    this.formControl.setValue([...files]);
  }
}
