import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticket-status-base',
  templateUrl: './ticket-get-status.base.html',
  styleUrls: [],
})
export class TicketGetStatusBaseComponent implements OnDestroy {
  loading = false;

  form = new FormGroup({});

  formModel: any = {};

  formFields: Array<FormlyFieldConfig>;

  private routeSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected translateService: TranslateService
  ) {
    this.routeSub = this.route.queryParams.subscribe(params => {
      setTimeout(() => {
        this.form.patchValue({
          id: params.id,
          email: params.email,
        });
      }, 1);
    });
  }

  submit(_event) {
    if (this.form.valid) {
      this.loading = true;
      const { id } = this.form.value;
      this.router.navigate([`${id}`], {
        relativeTo: this.route,
        queryParams: { email: this.form.value.email },
      });
    }
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }
}
