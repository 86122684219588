<h1>{{ 'captcha.title' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ 'captcha.description' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="space-between center" >
    <img [src]="captchaImage" height="100px" width="200px" />
    <button mat-button color="accent" (click)="loadNewCaptcha()">
      <mat-icon>refresh</mat-icon>
      <span>{{ 'captcha.reload' | translate }}</span>
    </button>
  </div>
</div>
<form [formGroup]="form">
  <formly-form
    [form]="form"
    [fields]="formFields"
    [model]="formModel"
    [options]="options"
  ></formly-form>
</form>
<div mat-dialog-actions align="center">
  <button mat-raised-button color="warn" (click)="onDismiss()">
    <span>{{ 'captcha.abort' | translate }}</span>
  </button>
  <button mat-raised-button color="primary" (click)="onOk()">
    <span>{{ 'captcha.ok' | translate }}</span>
  </button>
</div>
