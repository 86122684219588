import { LOCATION_INITIALIZED } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlobStorageService } from './blob-storage.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateLangService {
  private static readonly DEFAULT_LANGUAGE = navigator.language === 'de-DE' ? 'de-DE' : 'en-US';

  private static readonly BASE_TRANSLATION_PATH = 'assets/i18n/base-';

  constructor(
    private injector: Injector,
    private translate: TranslateService,
    private settingService: SettingsService,
    private blobStorageService: BlobStorageService
  ) {}

  public async load(): Promise<void> {
    try {
      await this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      const storedSettings = this.settingService.getOptions();
      const defaultLang = storedSettings.language || TranslateLangService.DEFAULT_LANGUAGE;
      this.translate.setDefaultLang(defaultLang);
      await this.translate.use(defaultLang).toPromise();
    } catch (error) {
      throw new Error('Failed to initialize language.');
    }
  }

  public async loadTranslation(path: string, lang = 'de-DE') {
    await this.loadTranslationFromUrl(TranslateLangService.BASE_TRANSLATION_PATH, lang);
    await this.loadTranslationFromUrl(path, lang);
    this.translate.use(lang).toPromise();
  }

  private async loadTranslationFromUrl(path, lang) {
    try {
      const translation = await this.blobStorageService.getJsonFromUrl(
        `${path}${lang}.json?t=${new Date().getTime()}`
      );
      this.translate.setTranslation(lang, translation, true);
    } catch (error) {
      console.log(`Fail to load translate file ${path}`);
    }
  }
}
