import { FeatureNames, FeatureToggle } from '@shared/features/feature-toggle';

export class UseLocationModuleFeature extends FeatureToggle<any> {
  public override name: FeatureNames = 'use-location-module';

  public override isEnabled: () => boolean = () => {
    const claims = this.getClaims();
    if (!claims?.email) {
      return false;
    }
    // TODO: Remove this workaround when the roles are set correctly in the token
    const email = claims?.email as string;
    const whitelistedUsers = ['s.vukovic@gmx.net', 'k.berneburg@nemo-dev.de', 'test@nemo-dev.de'];
    return whitelistedUsers.includes(email);
  };
}
