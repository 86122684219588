import { Component, OnInit } from '@angular/core';
import { MandantConfigService } from '@strategies/mandant-config.service';

@Component({
  selector: 'app-footer-default',
  templateUrl: './footer-default.component.html',
  styleUrls: ['./footer-default.component.scss'],
})
export class FooterDefaultComponent implements OnInit {
  impressumUrl: string;

  dataPrivacyUrl: string;

  hideFooter: boolean | undefined = false;

  constructor(private mandantConfigService: MandantConfigService) {
    if (mandantConfigService.mandant.hideFooter === true) {
      this.hideFooter = true;
    }
  }

  ngOnInit() {
    this.mandantConfigService.mandantConfig.subscribe(conf => {
      if (conf === null) return;
      this.impressumUrl = conf.impressumUrl;
      this.dataPrivacyUrl = conf.dataPrivacyUrl;
    });
  }
}
