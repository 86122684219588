<!--
<mat-form-field appearance="outline" fxFlex="100">
  <mat-label [innerHtml]="field.templateOptions?.label"></mat-label>
  <mat-datetimepicker-toggle [for]="thePicker" matSuffix></mat-datetimepicker-toggle>

  <mat-date-range-picker #thePicker>
    [type]="gettype()"
    [openOnFocus]="true"
    [startView]="field.templateOptions?.startView"
    [startAt]="field.templateOptions?.startAt"
    [lang]="lang"
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <mat-error *ngIf="formControl.errors?.matDatepickerMin">
    <span [innerText]="field.templateOptions?.errorMessageMin | translate"></span>
  </mat-error>
  <mat-error *ngIf="formControl.errors?.matDatepickerMax">
    <span [innerText]="field.templateOptions?.errorMessageMax | translate"></span>
  </mat-error>
  <mat-error *ngIf="formControl.errors?.matDatepickerParse">
    <span [innerText]="'formErrors.datepicker.matDatepickerParse' | translate"></span>
  </mat-error>
  <mat-error>
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
  <input
    [title]="to.label"
    matInput
    [matDatetimepicker]="thePicker"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [matDatepickerFilter]="matDatepickerFilter"
    [min]="field.templateOptions?.minDate"
    [max]="field.templateOptions?.maxDate"
    (ngModelChange)="emitChange($event)"
    (click)="thePicker.open()"
    [required]="this.templateOptions?.required === true"
    readonly
/></mat-form-field>-->
<mat-form-field appearance="outline" fxFlex="100">
    <mat-label [innerHtml]="field.templateOptions?.label"></mat-label>
    <mat-datetimepicker-toggle [lang]="lang" [for]="picker" matSuffix></mat-datetimepicker-toggle>
    <mat-date-range-input
      [lang]="lang"
      [rangePicker]="picker"
      [formGroup]="range"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="this.templateOptions?.required === true"
    >
      <input
        matInput
        readonly
        matStartDate
        [(ngModel)]="startDate"
        formControlName="start"
        [placeholder]="'shared.from' | translate">
      <input
        matInput
        readonly
        matEndDate
        [(ngModel)]="endDate"
        formControlName="end"
        [placeholder]="'shared.to' | translate">
    </mat-date-range-input>
    <mat-date-range-picker

      #picker>
    </mat-date-range-picker>
</mat-form-field>
