import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldBaseService } from '@strategies/_base/_form/services/formly-field.base';
import { Observable } from 'rxjs';
import { LocationClient } from '@api';
import { ToastrService } from 'ngx-toastr';
import { FormlyPreviewAutocompleteTemplateOptions } from '@shared/components/formly-preview-autocomplete-type/formly-preview-autocomplete-type.types';

@Injectable({
  providedIn: 'root',
})
export class LocationFormlyFieldService extends FormlyFieldBaseService {
  translatePath = 'location.autocomplete';

  constructor(
    protected translateService: TranslateService,
    private locationClient: LocationClient,
    private toastr: ToastrService
  ) {
    super(translateService);
  }

  autocompleteSearch(
    searchBuildings: boolean,
    defaultValue: any,
    onSelect: (id: string) => void
  ): FormlyFieldConfig[] {
    const translatePath = 'location.autocomplete';

    const columnDefinitions = [
      { fieldName: 'vpsz', labelTranslationKey: translatePath + '.VPSZ', classes: 'col-2' },
      { fieldName: 'siteName', labelTranslationKey: translatePath + '.WE-No', classes: 'col-2' },
      {
        fieldName: 'city',
        labelTranslationKey: translatePath + '.city',
        classes: 'col-3 text-overflow',
        formatter: x => x?.address?.city ?? '',
      },
      {
        fieldName: 'streetName',
        labelTranslationKey: translatePath + '.street',
        classes: 'col-3 text-overflow',
        formatter: x =>
          x?.address?.streetName ??
          '' + (x?.address?.houseNumber ? ' ' + x?.address?.houseNumber : ''),
      },
    ];

    if (searchBuildings) {
      columnDefinitions.push({
        fieldName: 'siteAdditionalNumber',
        labelTranslationKey: translatePath + '.WE-Additional-No',
        classes: 'col-2',
      });
    }
    const templateOptions: FormlyPreviewAutocompleteTemplateOptions = {
      required: true,
      visibleInConfirm: true,
      translatePath,
      columnDefinitions,
      defaultValue: defaultValue
        ? {
            ...defaultValue,
            id: defaultValue.archObjId,
          }
        : null,
      onSearch: (searchText: string): Observable<any> => {
        return this.locationClient.searchArchobjForAutoCompleteInexact(searchText);
      },

      onSelect: (searchResult: any): void => {
        onSelect(searchResult.archObjId);
      },
      onError: (errorMessage: string): void => {
        this.toastr.error(this.translateService.instant(errorMessage));
      },
      onSuccess: (message: string): void => {
        this.toastr.info(this.translateService.instant(message));
      },
      label: this.translateService.instant('location.autocomplete.label__placeholder'),
    };

    return [
      {
        type: 'preview-autocomplete',
        key: 'searchResult',
        templateOptions: { ...templateOptions },
      },
    ];
  }
}
