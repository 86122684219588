import { Component, ViewEncapsulation } from '@angular/core';
import { ErrorCodeComponent } from './error-code.component';

@Component({
  selector: 'error-code-404',
  templateUrl: './error-code.component.html',
  styleUrls: ['./error-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorCode404Component extends ErrorCodeComponent {
  /**
   * This class exists to not have to pass params to the base ErrorCodeComponent from route.
   */
  constructor() {
    super();
    this.code = '404';
    this.title = 'The item you are looking for does not exist.';
    this.message = 'Did you enter the correct value? 👀';
  }
}
