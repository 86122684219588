// eslint-disable-next-line max-classes-per-file
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Constructor } from '@core';

export const rxSubscriptionContainerMixin = <T extends Constructor>(baseClass: T = class {} as T) =>
  class extends baseClass implements OnDestroy {
    private subscriptions$: Subscription[] = [];

    public pushSubscription(subscription: Subscription) {
      if (!Array.isArray(this.subscriptions$)) {
        this.subscriptions$ = [];
      }

      this.subscriptions$.push(subscription);
    }

    public popSubscription(subscription: Subscription) {
      if (!Array.isArray(this.subscriptions$) || this.subscriptions$.length === 0) {
        return;
      }

      const idx = this.subscriptions$.indexOf(subscription);
      if (idx !== -1) {
        this.subscriptions$.splice(idx, 1);
      }
    }

    public clearSubscriptions() {
      if (Array.isArray(this.subscriptions$)) {
        this.subscriptions$.forEach(s => s.unsubscribe());
        this.subscriptions$ = [];
      }
    }

    public ngOnDestroy(): void {
      this.clearSubscriptions();
    }
  };
