import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialExtensionsModule } from '@ng-matero/extensions';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ToastrModule } from 'ngx-toastr';

import { IvyCarouselModule } from 'angular-responsive-carousel';

import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';

import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';

import { LayoutContainerColoredPolygonComponent } from '@strategies/_default/layout/layout-container-colored-polygon.component';
import { LayoutContainerColoredBackgroundimageComponent } from '@strategies/_default/layout/layout-container-colored-backgroundimage.component';
import { LayoutContainerBackgroundimageComponent } from '@strategies/_default/layout/layout-container-backgroundimage.component';
import { UpdateBaseComponent } from '@strategies/_base/_update/update.base';
import { ViewBaseComponent } from '@strategies/_base/_view/view.base';
import { TicketGetStatusDefaultComponent } from '@strategies/_default';
import {
  TicketCreateTelekomComponent,
  TicketStatusItemTelekomComponent,
} from '@strategies/telekom';
import { DashboardComponent } from '@strategies/_default/dashboard';
import { SpinnerOverlayContainerComponent } from '@strategies/_containers/spinner-overlay.container';
import { SpinnerOverlayTelekomComponent } from '@strategies/telekom/_components/spinner-overlay';
import { SpinnerOverlayDefaultComponent } from '@strategies/_default/spinner-overlay';
import { UserActionsContainerComponent } from '@strategies/_containers/user-actions.container';
import { UserActionsDefaultComponent } from '@strategies/_default/user-actions';
import { UserActionsTelekomComponent } from '@strategies/telekom/_components/user-actions';
import { TranslateComponent } from '@theme/header/widgets/translate.component';
import { UserComponent } from '@theme/header/widgets/user/user.component';
import { FeedbackComponent } from '@theme/header/widgets/feedback.component';
import { LocationNewsComponent } from '@routes/location/my-location/location-news/location-news.component';
import { TaskViewTelekomComponent } from '@strategies/telekom/task/task-view/task-view-telekom.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FeatureToggleService } from '@shared/services/feature-toggle.service';
import { environment } from '@env/environment';
import { AzureMapsModule } from 'ng-azure-maps';
import { LocationMapComponent } from '@shared/components/location-map/location-map.component';
import { DetailFileUploadContainerComponent } from '@strategies/_containers/detail-file-upload.container';
import { DetailFileUploadDefaultComponent } from '@strategies/_default/_details/detail-file-upload-service/detail-file-upload.component';
import { DetailFileUploadLegacyComponent } from '@strategies/_default/_details/detail-file-upload-legacy/detail-file-upload.component';
import { DetailFileListBufferedComponent } from '@strategies/_base/_details/detail-file-list-buffered/detail-file-list-buffered.component';
import { ProspectiveTenantsTelekomComponent } from '@strategies/telekom/task';
import { ExcelExportTelekomComponent } from '@strategies/telekom/_components/excel-export';
import { MaterialModule } from '../material.module';

import {
  DisableControlDirective,
  MatRadioGroupRequiredDirective,
  NavigateBackDirective,
  NgForTrackByIndexDirective,
  NgrxMatSelectViewAdapterDirective,
  PlaceholderDirective,
} from './directives';
import {
  FormlySelectOptionsTooltipPipe,
  ToObservablePipe,
  TruncatePipe,
  HtmlPipe,
  WithLoadingPipe,
  FilterArrayPipe,
} from './pipes';

import {
  AddressFinderComponent,
  BreadcrumbComponent,
  BreadcrumbService,
  DialogComponent,
  ElementNotFoundComponent,
  ErrorCode401Component,
  ErrorCode404Component,
  ErrorCode503Component,
  ErrorCodeComponent,
  FileValueAccessorDirective,
  FlexLayoutType,
  FormlyAutocompleteTypeComponent,
  FormlyPreviewAutocompleteTypeComponent,
  FormlyCardWrapperComponent,
  FormlyChapterComponent,
  FormlyCheckboxDialogWrapperComponent,
  FormlyCheckboxWrapperComponent,
  FormlyCountCharsWrapperComponent,
  FormlyDatePickerComponent,
  FormlyDateRangeComponent,
  FormlyFieldFileComponent,
  FormlyLoadingSuffixWrapperComponent,
  FormlyReadOnlyWrapperComponent,
  FormlyRecaptchaComponent,
  FormlySelectTooltipComponent,
  FormlyTooltipWrapperComponent,
  FormlyTreeSelectComponent,
  HeaderBannerComponent,
  PageHeaderComponent,
  RepeatTypeComponent,
  SmartCardComponent,
  HelpDialogComponent,
  FormlyButtonComponent,
  SearchHelpDialogComponent,
  FormlyInputSearchComponent,
  TelekomEmailTemplateComponent,
  FormlyFieldDragDropComponent,
} from './components';

import { PasmFormlyAutocompleteTypeComponent } from '../strategies/pasm/ticket/pasm-formly-autocomplete-type.component';
import { CheckboxDialogComponent } from './components/checkbox-dialog/checkbox-dialog.component';
import { FileService } from './services';
import { FormBaseComponent } from '../strategies/_base/_form/form.base';
import { FormConfirmComponent } from '../strategies/_base/_form/form-confirm/form-confirm.component';
import { TableBaseComponent } from '../strategies/_base/_table/table.base';
import { TableFilterComponent } from '../strategies/_base/_table/table-filter/table-filter.component';
import { DetailsBaseComponent } from '../strategies/_base/_details/details.base';
import { DetailFieldsBaseComponent } from '../strategies/_base/_details/detail-fields/detail-fields.base';
import { DetailFileListComponent } from '../strategies/_base/_details/detail-file-list/detail-file-list.component';
import { TicketCreateBaseComponent } from '../strategies/_base/ticket-anonym/ticket-create/ticket-create.base';
import { TicketGetStatusBaseComponent } from '../strategies/_base/ticket-anonym/ticket-get-status/ticket-get-status.base';
import { TicketStatusItemBaseComponent } from '../strategies/_base/ticket-anonym/ticket-status-item/ticket-status-item.base';
import { LocationNotificationComponent } from './components/location-notification/location-notification.component';
import { FormlyDateTimePickerComponent } from './components/custom-formly-components/formly-datetimepicker.component';
import { CaptchaComponent } from './components/captcha';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};
const MODULES = [
  MaterialModule,
  MaterialExtensionsModule,
  FlexLayoutModule,
  NgProgressModule,
  NgProgressRouterModule,
  NgProgressHttpModule,
  NgSelectModule,
  ToastrModule,
  TranslateModule,
  PpBreadcrumbsModule,
  IvyCarouselModule,
  FormlyMaterialModule,
  FormlyMatDatepickerModule,
  RecaptchaModule,
  AngularEditorModule,
];
const COMPONENTS = [
  BreadcrumbComponent,
  PageHeaderComponent,
  HeaderBannerComponent,
  ErrorCodeComponent,
  ErrorCode401Component,
  ErrorCode404Component,
  ErrorCode503Component,
  ElementNotFoundComponent,
  DialogComponent,
  CaptchaComponent,
  DashboardComponent,
  FlexLayoutType,
  FormlyFieldFileComponent,
  FormlyButtonComponent,
  FormlyChapterComponent,
  FormlyRecaptchaComponent,
  FormlyDatePickerComponent,
  FormlyDateTimePickerComponent,
  FormlyDateRangeComponent,
  FormlyFieldDragDropComponent,
  FormlyCheckboxWrapperComponent,
  FormlyCheckboxDialogWrapperComponent,
  FormlyCardWrapperComponent,
  FormlyLoadingSuffixWrapperComponent,
  FormlyPreviewAutocompleteTypeComponent,
  PasmFormlyAutocompleteTypeComponent,
  FormlyAutocompleteTypeComponent,
  FormlyCountCharsWrapperComponent,
  FormlyTooltipWrapperComponent,
  FormlyReadOnlyWrapperComponent,
  FormlySelectTooltipComponent,
  RepeatTypeComponent,
  SmartCardComponent,
  AddressFinderComponent,
  CheckboxDialogComponent,
  FormlyTreeSelectComponent,
  FormlyAutocompleteTypeComponent,
  FormBaseComponent,
  FormBaseComponent,
  FormConfirmComponent,
  UpdateBaseComponent,
  ViewBaseComponent,
  TableBaseComponent,
  TableFilterComponent,
  DetailsBaseComponent,
  DetailFieldsBaseComponent,
  DetailFileListComponent,
  DetailFileListBufferedComponent,
  TicketCreateBaseComponent,
  TicketGetStatusBaseComponent,
  TicketGetStatusDefaultComponent,
  TicketStatusItemBaseComponent,
  TicketCreateTelekomComponent,
  TicketStatusItemTelekomComponent,
  LayoutContainerBackgroundimageComponent,
  LayoutContainerColoredBackgroundimageComponent,
  LayoutContainerColoredPolygonComponent,
  TranslateComponent,
  UserComponent,
  FeedbackComponent,
  SpinnerOverlayContainerComponent,
  SpinnerOverlayDefaultComponent,
  SpinnerOverlayTelekomComponent,
  UserActionsContainerComponent,
  UserActionsDefaultComponent,
  UserActionsTelekomComponent,
  HelpDialogComponent,
  DetailFileUploadContainerComponent,
  DetailFileUploadLegacyComponent,
  DetailFileUploadDefaultComponent,
  LocationMapComponent,
  SearchHelpDialogComponent,
  FormlyInputSearchComponent,
  LocationNotificationComponent,
  TelekomEmailTemplateComponent,
  TaskViewTelekomComponent,
  ExcelExportTelekomComponent,
  LocationNewsComponent,
  ProspectiveTenantsTelekomComponent,
];

const COMPONENTS_DYNAMIC = [];
const DIRECTIVES = [
  DisableControlDirective,
  NgrxMatSelectViewAdapterDirective,
  MatRadioGroupRequiredDirective,
  NgForTrackByIndexDirective,
  NavigateBackDirective,
  FileValueAccessorDirective,
  PlaceholderDirective,
];
const PIPES = [
  ToObservablePipe,
  WithLoadingPipe,
  FormlySelectOptionsTooltipPipe,
  TruncatePipe,
  HtmlPipe,
  FilterArrayPipe,
];

const PROVIDERS = [
  BreadcrumbService,
  FeatureToggleService,
  FileService,
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: appearance,
  },
];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...MODULES,
    FormlySelectModule,
    FormlyModule.forChild({
      types: [
        { name: 'flex-layout', component: FlexLayoutType },
        { name: 'file', component: FormlyFieldFileComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'chapter', component: FormlyChapterComponent },
        { name: 'recaptcha', component: FormlyRecaptchaComponent },
        { name: 'matdatetimepicker', component: FormlyDatePickerComponent },
        { name: 'datetimepicker', component: FormlyDateTimePickerComponent },
        { name: 'dragdrop', component: FormlyFieldDragDropComponent },
        { name: 'preview-autocomplete', component: FormlyPreviewAutocompleteTypeComponent },
        { name: 'pasm-autocomplete', component: PasmFormlyAutocompleteTypeComponent },
        { name: 'matdaterange', component: FormlyDateRangeComponent },
        { name: 'autocomplete', component: FormlyAutocompleteTypeComponent },
        { name: 'select-tooltip', component: FormlySelectTooltipComponent },
        { name: 'tree-select', component: FormlyTreeSelectComponent },
        { name: 'button', component: FormlyButtonComponent },
        { name: 'input-search', component: FormlyInputSearchComponent },
      ],
      wrappers: [
        { name: 'checkbox', component: FormlyCheckboxWrapperComponent },
        { name: 'checkbox-dialog', component: FormlyCheckboxDialogWrapperComponent },
        { name: 'card', component: FormlyCardWrapperComponent },
        { name: 'loading-suffix', component: FormlyLoadingSuffixWrapperComponent },
        { name: 'count-chars', component: FormlyCountCharsWrapperComponent },
        { name: 'tooltip', component: FormlyTooltipWrapperComponent },
        { name: 'readOnly', component: FormlyReadOnlyWrapperComponent },
      ],
      validators: [{ name: 'requiredTrue', validation: Validators.requiredTrue }],
    }),
    AzureMapsModule.forChild({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      authOptions: environment.authOptions!,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FormlyModule,
    ...MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS],
    };
  }
}
