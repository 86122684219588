import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicThemeService } from '@core/bootstrap/dynamic-theme.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { MandantConfigService } from '@strategies';
@Component({
  selector: 'app-branding',
  template: `
    <a class="matero-branding" (click)="navigateToDashboardPage()">
      <img
        *ngIf="brandingLogo"
        [src]="brandingLogo"
        class="matero-branding-logo-expanded"
        alt="logo"
      />
      <span class="matero-branding-name accent-color" [innerText]="brandingTitle">...</span>
    </a>
  `,
})
export class BrandingComponent {
  public brandingLogo: string;

  private logoSub$ = this.dynamicThemeService.brandingLogo$;

  private faviconSub$ = this.dynamicThemeService.brandingFavicon$;

  private brandingTitle$ = this.dynamicThemeService.brandingTitle$;

  public brandingTitle = '';

  // private favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor(
    private router: Router,
    private dynamicThemeService: DynamicThemeService,
    private sanitizer: DomSanitizer,
    private mandant: MandantConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.brandingLogo = '';
    this.logoSub$.subscribe(dataString => {
      const breakSanatizer2 = this.sanitizer.bypassSecurityTrustResourceUrl(dataString) as string;
      this.brandingLogo = breakSanatizer2;
    });

    this.faviconSub$.subscribe(dataString => {
      const dataUrl = this.sanitizer.bypassSecurityTrustHtml(dataString) as string;
      const breakSanatizer = JSON.parse(JSON.stringify(dataUrl))
        .changingThisBreaksApplicationSecurity;
      this.document.getElementById('appIcon')?.setAttribute('href', breakSanatizer);
    });

    this.brandingTitle$.subscribe(brandingTitle => {
      this.brandingTitle = brandingTitle;
    });
  }

  navigateToBasePage() {
    this.router.navigateByUrl(`/${this.router.url.split('/')[1]}`);
  }

  navigateToDashboardPage() {
    window.location.href = this.mandant.mandant.defaultRoute;
  }

  ngOnDestory() {
    this.logoSub$.unsubscribe();
  }
}
