import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IDialogData {
  type: 'noButtons' | 'okButton' | 'okDismissButton';
  title?: string;
  message?: string;
  okButtonText?: string;
  dismissButtonText?: string;
  confirmColor?: string;
  cancelColor?: string;
}

export interface IDialogFeedback {
  error: {
    title: string;
    message: string;
  };
  success: {
    title: string;
    message: string;
  };
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    if (!data.confirmColor) {
      this.data.confirmColor = 'primary';
    }
    if (!data.cancelColor) {
      this.data.cancelColor = 'warn';
    }
  }

  public onOk() {
    this.dialogRef.close(true);
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }
}
