<div
  fxFlex="100"
  [fxFlex.gt-sm]="100 - 2 * offset"
  [fxFlexOffset.gt-sm]="offset"
  fxFlexFill
  fxLayoutAlign="start start"
  fxLayout="column"
  fxLayoutGap="16px"
  [style.margin.px]="margin"
  class="ticket-status"
>
  <div fxFlex="nogrow">
    <button
      *ngIf="backButton"
      mat-raised-button
      color="primary"
      navigateBack
      [style.margin-bottom.px]="10"
    >
      <mat-icon class="materialSendIcon">arrow_back</mat-icon>
      <span class="customSendIconLeft"></span>
      <span [innerHtml]="'back' | translate | uppercase"></span>
    </button>
    <ng-content select="[navigation]"></ng-content>
  </div>

  <div fxFlexFill fxLayoutAlign="center center">
    <mat-card fxFlex="100" class="smart-card-component" [style.padding.px]="padding">
      <ng-content></ng-content>
    </mat-card>
  </div>
</div>
