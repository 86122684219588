import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  Inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegexpMultipleEmail } from '@core/utility/regex';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '@shared/services';
import { ToastrService } from 'ngx-toastr';

export interface Email {
  from: string;
  to: string;
  subject: string;
  body: string;
}

@Component({
  selector: 'app-location-notification',
  templateUrl: './location-notification.component.html',
  styleUrls: ['./location-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationNotificationComponent {
  @Input() navigation = true;

  @Output() emailContent = new EventEmitter<string>();

  public formFields: Array<FormlyFieldConfig> = [];

  public filledForm: FormlyFieldConfig[] | null;

  public formFieldsAttachment: Array<FormlyFieldConfig> = [];

  form = new FormGroup({});

  formModel: any = {};

  private el: ElementRef;

  public selectedEmployees = 'x';

  public deskSharingUser = 'y';

  public maxFileSizeInMB = 10;

  public htmlContent;

  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '25rem',
    placeholder: this.translateService.instant('location.myLocation.enter_text'),
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [[]],
  };

  constructor(
    private translateService: TranslateService,
    private fileService: FileService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<LocationNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { e_mail: string; we_no: string; street: string }
  ) {
    this.formFields = [
      {
        wrappers: ['card'],
        fieldGroup: [
          {
            type: 'chapter',
            templateOptions: {
              chapterTitle: this.translateService.instant(
                'location.myLocation.location_notification.email_employees'
              ),
            },
          },
          {
            key: 'location',
            type: 'textarea',
            defaultValue: data.we_no,
            templateOptions: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__location'
              ),
              readonly: true,
            },
          },
          {
            key: 'recipient',
            type: 'textarea',
            defaultValue: this.translateService.instant(
              'location.myLocation.location_notification.email_to',
              {
                selectedEmployees: this.selectedEmployees,
                deskSharingUser: this.deskSharingUser,
              }
            ),
            templateOptions: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__email_to'
              ),
              rows: 2,
              readonly: true,
            },
          },
          {
            key: 'hint',
            type: 'textarea',
            defaultValue: this.translateService.instant(
              'location.myLocation.location_notification.email_hint'
            ),
            templateOptions: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__hint'
              ),
              rows: 2,
              readonly: true,
            },
          },
          {
            key: 'additionalRecipients',
            type: 'input',
            defaultValue: data.e_mail,
            templateOptions: {
              label: this.translateService.instant(
                'location.myLocation.location_notification.label__additional_recipients'
              ),
              placeholder: this.translateService.instant(
                'location.myLocation.location_notification.email'
              ),
              pattern: RegexpMultipleEmail,
              description: this.translateService.instant(
                'location.myLocation.location_notification.email_recipient_description'
              ),
            },
            validation: {
              messages: {
                pattern: (_err, _field: FormlyFieldConfig) =>
                  translateService
                    .instant('formErrors.email.pattern')
                    .concat(
                      ' ',
                      translateService.instant(
                        'location.myLocation.location_notification.email_recipient_description'
                      )
                    ),
              },
            },
          },
          {
            key: 'subject',
            type: 'input',
            templateOptions: {
              label: `${this.translateService.instant(
                'location.myLocation.location_notification.label__subject'
              )}:`,
              required: true,
              pattern: /^(?!\s*$).+/,
            },
            validation: {
              messages: {
                required: this.translateService.instant('formErrors.required', {
                  label: this.translateService.instant(
                    'location.myLocation.location_notification.label__subject'
                  ),
                }),
                pattern: this.translateService.instant('formErrors.noSpaces', {
                  label: this.translateService.instant(
                    'location.myLocation.location_notification.label__subject'
                  ),
                }),
              },
            },
          },
        ],
      },
      {
        wrappers: ['card'],
        fieldGroup: [
          {
            type: 'chapter',
            templateOptions: {
              chapterTitle: this.translateService.instant('Anhänge'),
              chapterText: translateService.instant(
                this.translateService.instant(
                  'location.myLocation.location_notification.file_attachments',
                  {
                    maxMB: this.maxFileSizeInMB,
                  }
                )
              ),
            },
          },
          {
            key: 'attachments',
            type: 'repeat',
            templateOptions: {
              maxItems: 5,
              addText: translateService.instant('ticket.create.button__attach_new_file'),
            },

            fieldArray: {
              type: 'flex-layout',
              fieldGroup: [
                {
                  type: 'file',
                  key: 'attachment',
                  templateOptions: {
                    label: translateService.instant('ticket.create.label__attachment'),
                    visibleInConfirm: true,
                    accept: '.jpg, jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf',
                    maxFileSizeInMB: this.maxFileSizeInMB,
                  },
                },
              ],
            },
          },
        ],
      },
    ];
  }

  public onBlur(content: any) {
    this.emailContent.emit(content);
  }

  public onEmailChange() {
    const emailToLowercase = this.form.get('additionalRecipients')?.value.toLowerCase();

    this.form.get('additionalRecipients')?.setValue(emailToLowercase);
  }

  public canSubmit(): boolean {
    const maxMB = 10;
    if (this.form.value.attachments.length === 0) {
      return true;
    }
    const [isValid, message, title] = this.fileService.validateTotalFileSize(
      this.form.value.attachments,
      (accumulator, current) => accumulator + current.attachment.size,
      maxMB
    );
    if (!isValid) {
      this.toastr.error(message, title);
    }
    return isValid;
  }

  validateForm() {
    this.onEmailChange();
    this.canSubmit();
    if (this.form.valid && this.canSubmit()) {
      this.showFormSummary();
    } else {
      this.scrollToFirstInvalidControl(true);
    }
  }

  private async scrollToFirstInvalidControl(repeatScroll: boolean) {
    this.form.markAllAsTouched();
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-error',
      '.mat-input-element.ng-invalid, .mat-select.ng-invalid, mat-checkbox.ng-invalid, mat-form-field.ng-invalid'
    );

    try {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
      firstInvalidControl.focus();
    } finally {
      if (repeatScroll) {
        setTimeout(() => {
          this.scrollToFirstInvalidControl(false);
        }, 10);
      }
    }
  }

  async showFormSummary() {
    this.filledForm = this.formFields;
  }

  public toLowercaseParser() {
    return this.formModel.additionalRecipients
      ? this.formModel.additionalRecipients.toLowerCase()
      : this.formModel.additionalRecipients;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
