import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export function incidentInformationSkyBlue(
  translateService: TranslateService
): Array<FormlyFieldConfig> {
  return [
    {
      fieldGroup: [
        {
          type: 'input',
          key: 'incidentTitle',
          templateOptions: {
            label: translateService.instant('ticket.create.label__short_title'),
            required: true,
            visibleInConfirm: true,
            maxLength: 200,
          },
        },
      ],
    },
    {
      wrappers: ['form-field', 'count-chars'],
      key: 'incidentDescription',
      type: 'textarea',
      templateOptions: {
        label: translateService.instant('ticket.create.label__short_description'),
        visibleInConfirm: true,
        autosize: true,
        autosizeMinRows: 4,
        maxLength: 2000,
      },
    },
  ];
}
