import { defer, Observable } from 'rxjs';

export const prepare = <T>(
  callback: () => void
): ((source: Observable<T>) => Observable<T>) => {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source;
    });
};
