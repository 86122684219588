import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  private httpOptions: any = {
    observe: 'response',
    responseType: 'application/json',
    headers: new HttpHeaders({
      Accept: 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public getJsonFromUrl(url: string): any {
    return new Promise(async (resolve, reject) => {
      try {
        const menuJson: any = await this.http.get(url, this.httpOptions).toPromise();
        resolve(JSON.parse(menuJson.body));
      } catch (error) {
        reject(error);
      }
    });
  }
}
