<input type="file" multiple (change)="onFileSelected($event)" #fileUpload hidden />
<div flexLayout="row" fxLayoutGap="1em" [style.margin-bottom.em]="1" fxLayoutAlign="start start">
  <div fxFlex.lt-sm="100" fxLayoutAlign="start start">
    <button
      mat-raised-button
      color="primary"
      (click)="fileUpload.click()"
      [disabled]="busy"
      [disabled]="readonly"
      type="button"
      fxFlex="auto 0"
      [matTooltip]="tooltip"
    >
      <mat-icon>attach_file</mat-icon><span [innerText]="'crud.file.attach' | translate"></span>
    </button>
  </div>
  <div fxFlex.lt-sm="100" fxLayout="column">
    <button
      type="button"
      mat-raised-button
      fxFlex="100"
      color="accent"
      (click)="uploadFiles()"
      [disabled]="busy || typedFiles.value.length <= 0"
    >
      <mat-icon>upload</mat-icon> <span [innerText]="'crud.file.upload' | translate"></span>
    </button>
    <mat-error *ngIf="!form.valid && form.touched">
      <span [innerHtml]="'crud.fileUpload.error.missingRequiredFields' | translate"></span>
    </mat-error>
  </div>
</div>
<div fxFlexFill fxLayout="column" fxLayoutGap="16px" [formGroup]="form">
  <ng-container formArrayName="typedFiles">
    <ng-container *ngFor="let typedFileForm; as: FormGroup; of: typedFiles.controls; index as i">
      <mat-card [formGroup]="castToFormGroup(typedFileForm)" class="mat-card-attach">
        <div [fxFlex]="documentTypes ? '40' : '80'" [style.padding-top.em]="1" class="break-all">
          {{ typedFileForm.value.file.name }}
        </div>
        <mat-form-field fxFlex="40" *ngIf="documentTypes">
          <mat-label>{{ 'crud.fileUpload.documentType' | translate }}</mat-label>
          <mat-select ngDefaultControl formControlName="documentTypeId" [required]="typeIsRequired">
            <mat-option
              *ngFor="let docType of documentTypes"
              [value]="docType.value"
              [title]="docType.label"
            >
              {{ docType.label }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="typeIsRequired">
            <span [innerHtml]="'crud.fileUpload.error.required' | translate"></span>
          </mat-error>
        </mat-form-field>
        <div fxFlex="20" [style.padding-top.em]="0.5">
          <button mat-button color="warn" (click)="detachFile(i)" [disabled]="busy">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>
</div>

<spinner-overlay [show]="busy"></spinner-overlay>
