import { Component } from '@angular/core';
import { IDetailFields } from '@strategies/_base/_details/detail-fields/models/detail-fields.model';
import { TicketStatusItemBaseComponent } from '../../../_base/ticket-anonym/ticket-status-item/ticket-status-item.base';

@Component({
  selector: 'app-ticket-status-item-telekom',
  templateUrl: '../../../_base/ticket-anonym/ticket-status-item/ticket-status-item.base.html',
  styleUrls: [],
})
export class TicketStatusItemTelekomComponent extends TicketStatusItemBaseComponent {
  title = 'Ticketstatus';

  detailFields: Array<IDetailFields> = [
    {
      type: 'field',
      key: 'name',
      label: 'Betreff',
      fxFlex: 100,
      break: false,
    },
    {
      type: 'field',
      key: 'incidentClass.incidentClass',
      label: 'Kategorie',
      fxFlex: 100,
      break: false,
    },
    {
      type: 'textarea',
      key: 'description',
      label: 'Meldung',
      fxFlex: 100,
      break: false,
    },
    {
      type: 'field',
      key: 'statusReasonNavigation',
      label: 'Status',
      fxFlex: 100,
      break: false,
      transformValueFn: (field: any) => {
        return this.ibpdiHelperService.getTranslatedEnumLable(field);
      },
    },
    {
      type: 'field',
      key: 'ticketNumber',
      label: 'Ticketnummer',
      css: 'font-family: monospace; font-size:15px',
      fxFlex: 100,
      break: false,
    },
    {
      type: 'files',
      key: 'fmDocument',
      label: 'Anhänge',
      fxFlex: 100,
      break: false,
      fileMetadata: [
        {
          attributeName: 'incidentId',
          attributeValue: 'missing incidentId',
        },
      ],
    },
  ];
}
