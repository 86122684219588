<div fxLayoutAlign="center center" id="footer" *ngIf="!hideFooter">
  <a
    *ngIf="impressumUrl"
    [href]="impressumUrl"
    [innerText]="'footer.imprint' | translate"
    target="_blank"
  ></a>
  <a *ngIf="dataPrivacyUrl && impressumUrl">•</a>
  <a
    *ngIf="dataPrivacyUrl"
    [href]="dataPrivacyUrl"
    [innerText]="'footer.dataPrivacy' | translate"
    target="_blank"
  ></a>
</div>
