import { Component, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'loading-suffix',
  template: `
    <ng-template #matSuffix>
      <mat-spinner *ngIf="to.loading" class="spinner" diameter="20"></mat-spinner>
    </ng-template>
    <ng-container #fieldComponent></ng-container>
  `,
})
export class FormlyLoadingSuffixWrapperComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;

  @ViewChild('matSuffix') matSuffix: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.matSuffix) {
      setTimeout(() => {
        this.to.suffix = this.matSuffix;
      });
    }
  }
}
