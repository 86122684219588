<smart-card class="smart-card" style="margin: 0 auto; display: flex; align-items: center">
  <h1 [innerText]="title | translate"></h1>
  <p [innerText]="description | translate"></p>

  <div fxLayout="row wrap" class="container matero-row">
    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="grid">
      <ng-container *ngFor="let item of items | async">
        <div fxFlex="33" fxFlex.xs="100" fxFlex.md="50" *ngIf="item.visibleOnDashboard !== false">
          <a [routerLink]="'../' + item.route" [queryParams]="item.queryParams">
            <mat-card class="mat-elevation-z5 dashboard-card" style="border-radius: 4px !important">
              <h1 [style.color]="'var(--theme-primary-700)'">
                <mat-icon>{{ item.icon }}</mat-icon>
              </h1>
              <h3 [style.color]="'var(--theme-accent-700)'">{{ item.name | translate }}</h3>
            </mat-card>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</smart-card>

<smart-card *ngIf="mandant === 'brst'" class="smart-card" style="margin: 0 auto; display: flex">
  <div>
    <mat-card class="mat-elevation-z5" style="border-radius: 4px !important">
      <mat-card-title>
        <p>
          <span class="green-text">{{ 'selfservice.emergency.header' | translate }}</span>
        </p>
      </mat-card-title>
      <div class="flex-card" fxLayout="row" fxLayout.lt-sm="column">
        <mat-card-content fxFlex="60" fxFlex.lt-sm="100" fxFlex.md="50" fxFlex.xs="100">
          <p>
            <span class="purple-text">{{ 'selfservice.emergency.subheader' | translate }}</span>
          </p>
          <p>
            <span class="purple-text">{{ 'selfservice.emergency.info' | translate }}</span>
          </p>
          <p>
            <span class="purple-text">{{ 'selfservice.emergency.tel' | translate }}</span>
          </p>
          <p>
            <b
              ><span class="purple-text">{{ 'selfservice.examples.header' | translate }}</span></b
            >
          </p>
          <ul>
            <li class="purple-text" *ngFor="let item of 'selfservice.examples.list' | translate">
              {{ item }}
            </li>
          </ul>
          <p>
            <b
              ><span class="purple-text">{{
                'selfservice.notEmergency.header' | translate
              }}</span></b
            >
          </p>
          <ul>
            <li
              class="purple-text"
              *ngFor="let item of 'selfservice.notEmergency.list' | translate"
            >
              {{ item }}
            </li>
          </ul>
          <p>
            <b
              ><span class="purple-text">{{
                'selfservice.notEmergency.warning' | translate
              }}</span></b
            >
          </p>
        </mat-card-content>

        <mat-card-content
          fxFlex="40"
          fxFlex.sm="50"
          fxFlex.md="50"
          fxFlex.xs="100"
          fxLayoutAlign="end center"
          fxLayoutAlign.lt-sm="center center"
          fxLayoutAlign.lt-md="end center"
        >
          <img
            src="assets/brst/images/brst_emer_numbers.png"
            alt="Notfallnummern/Emergency numbers"
          />
        </mat-card-content>
      </div>
    </mat-card></div
></smart-card>
