import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
@Component({
  selector: 'formly-field-file',
  template: `
    <div fxLayout="row" fxFlexAlign="center center">
      <div fxFlex fxLayoutAlign="end start" fxFlex="nogrow">
        <button
          type="button"
          (click)="fileInput.click()"
          color="accent"
          fxFlexAlign="center center"
          mat-raised-button
        >
          <mat-icon>attach_file</mat-icon>
        </button>
      </div>

      <p fxFlex class="filename-label">
        {{ fileName || 'ticket.create.label__select_file' | translate }}
      </p>
      <p fxFlex class="mat-error" *ngIf="formatError">
        {{ 'formErrors.file.format' | translate: { accept: to.accept } }}
      </p>
      <p fxFlex class="mat-error" *ngIf="sizeError">
        {{ 'formErrors.file.size' | translate: { maxSize: to.maxFileSizeInMB } }}
      </p>
      <p fxFlex class="mat-error" *ngIf="formControl.errors && formControl.touched">
        {{ 'formErrors.file.select' | translate }}
      </p>
      <p fxFlex class="mat-error" *ngIf="fileNameLengthError">
        {{ 'fileUpload.error.message__filename_length_too_large' | translate }}
      </p>
      <p fxFlex class="mat-error" *ngIf="fileSizeTooSmallError">
        {{ 'fileUpload.error.message__file_size_too_small' | translate }}
      </p>

      <input
        #fileInput
        type="file"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [accept]="to.accept"
        hidden
        required
        (change)="onFileSelected($event)"
      />
    </div>
  `,
  styles: [
    `
      .filename-label {
        word-break: break-all;
        padding-left: 10px;
        /* margin-top: 26px; */
      }
      .error {
        color: red;
      }
    `,
  ],
})
export class FormlyFieldFileComponent extends FieldType implements OnInit {
  formControl: FormControl;

  field: FormlyFieldConfig;

  selectedFile: File;

  fileName: string;

  formatError = false;

  sizeError = false;

  fileNameLengthError = false;

  fileSizeTooSmallError = false;

  ngOnInit() {
    this.formatError = false;
    this.sizeError = false;
    this.fileName = '';
    this.fileNameLengthError = false;
    this.fileSizeTooSmallError = false;
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0] as File;
    this.sizeError = false;
    this.formatError = false;
    this.fileSizeTooSmallError = false;
    this.fileName = '';

    const selectedFileExtensionArray = this.selectedFile.name.split('.');
    const selectedFileExtensionArrayLength = selectedFileExtensionArray.length;
    if (
      this.field.templateOptions?.accept &&
      !this.field.templateOptions?.accept
        ?.toLowerCase()
        .includes(selectedFileExtensionArray[selectedFileExtensionArrayLength - 1].toLowerCase())
    ) {
      this.formatError = true;
      return;
    }
    if (
      this.field.templateOptions?.maxFileSizeInMB &&
      this.selectedFile.size > this.field.templateOptions?.maxFileSizeInMB * 1e6
    ) {
      this.sizeError = true;
      return;
    }
    if (this.selectedFile.name.length > 150) {
      this.fileNameLengthError = true;
      return;
    }

    if (this.selectedFile.size <= 0) {
      this.fileSizeTooSmallError = true;
      return;
    }

    this.fileName = `${this.selectedFile.name} (${(this.selectedFile.size / 1e6).toFixed(2)} MB)`;
    this.formControl.patchValue(this.selectedFile);
  }
}
