import { MsalService } from '@azure/msal-angular';
import { TokenClaims } from '@azure/msal-common/dist/account/TokenClaims';

export type FeatureNames = 'use-location-module';

type Claims = TokenClaims & {
  [key: string]: string | number | string[] | object | undefined | unknown;
};

export abstract class FeatureToggle<TInput> {
  public abstract name: FeatureNames;
  public isEnabled: () => boolean;
  public isEnabledWithParams: (input: TInput) => boolean;
  public childFeatures: FeatureToggle<any>[] = [];

  public constructor(public msalService: MsalService) {}

  protected get roles(): string[] {
    return this.getClaims().roles ?? [];
  }

  protected getClaims(): Claims {
    const account = this.msalService.instance?.getActiveAccount();
    return account?.idTokenClaims!;
  }
}
